import FilterForm from "../../../component/FilterForm/FilterForm";
import React, {useState} from "react";
import TextField from "@mui/material/TextField";
import Select from "react-select";
import {genderList, quantityList} from "./lists";
import SelectInput from "../../../component/SelectInput";
import {ApiUrl} from "../../../auth/authMethods";
import {InputLabelProps} from "../../../utils/InputLabelProps";


export default function FilterAnimal({filter, setFilter, setLoadData, setPage}) {

    const [lgShow, setLgShow] = useState(false);
    const [pedidoId, setPedidoId] = useState('');
    const [nomeAnimal, setNomeAnimal] = useState('');
    const [generoAnimal, setGeneroAnimal] = useState('')
    const [quantidade, setQuantidade] = useState('')

    const [pedidoList, setPedidoList] = useState('')


    const handlesSubmit = (e) => {

        e.preventDefault();

        setFilter({
            ...(nomeAnimal && {nomeAnimal}),
            ...(generoAnimal && {generoAnimal}),
            ...(quantidade && {quantidade}),
            ...(pedidoId && {pedidoId}),
        });

        setLgShow(false);
        setLoadData(true);
        setPage(0);
    }

    const clearFilter = () => {
        setNomeAnimal('')
        setGeneroAnimal('')
        setPedidoId('')
        setFilter({});

        setLgShow(false);
        setLoadData(true);
        setPage(0);
    }

    return <>
        <FilterForm
            handleSubmit={handlesSubmit} clearFilter={clearFilter}
            lgShow={lgShow} setLgShow={setLgShow}
            showSecondaryButton={Object.keys(filter).length !== 0}
        >

            <SelectInput
                url={`${ApiUrl}/pedidos/list?size=200000000`}
                placeholder={"Pedido"}
                list={pedidoList}
                set={setPedidoList}
                setObj={setPedidoId}
                defaultValue={pedidoId}
                returnList={() => {
                    let returnList = []
                    pedidoList.forEach((item) => {
                        returnList.push({value: item.id, label: `Lote ${item.numero} - ${item.evento.nomeEvento}`})
                    })
                    return returnList
                }}
                isClearable
                fullWidth
            />
            <TextField
                label="Nome do Animal"
                className='mt-2'
                inputProps={{
                    maxLength: 40
                }}
                InputLabelProps={InputLabelProps}
                errortext="Campo obrigatório!"
                value={nomeAnimal}
                onChange={(e) => setNomeAnimal(e.target.value)}
                fullWidth
                autoFocus
            />
            <Select
                className="basic-single"
                classNamePrefix="select"
                id="form_select"
                required={false}
                defaultValue={genderList.filter(item => item.value === generoAnimal)}
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                isSearchable={true}
                options={genderList}
                onChange={(e) => setGeneroAnimal(e?.value)}
                placeholder={'Gênero'}
            />
            <Select
                className="basic-single"
                classNamePrefix="select"
                id="form_select"
                required={false}
                defaultValue={quantityList.filter(item => item.value === quantidade)}
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                isSearchable={true}
                options={quantityList}
                onChange={(e) => setQuantidade(e?.value)}
                placeholder={'Quantidade'}
            />
        </FilterForm>
    </>
}