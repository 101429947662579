import React, {useEffect, useState} from "react";
import {Box, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import {numberWithPoints} from "../../../../../utils/numberWithPoints";
import {normalizeTimestemp} from "../../../../../utils/normalizeTimestemp";


export default function PreviewData({data}){
    const [pares, setPares] = useState()
    const [impares, setImpares] = useState()


    useEffect(() => {
        if (!!data){
            const parcelasPares = []
            const parcelasImpares = []

            if (!!data.entrada.valor) {
                parcelasImpares.push({
                    numero: "Entrada",
                    valor: data.entrada.valor,
                    comprador: 'PAGA NO EVENTO',
                    vencimento: data.entrada.data
                })
            }

            data.parcelas.filter(parc => parc.numero % 2 === 0)
                .forEach(parc => parcelasPares.push(parc))
            setPares(parcelasPares)

            data.parcelas.filter(parc => parc.numero % 2 !== 0)
                .forEach(parc => parcelasImpares.push(parc))
            setImpares(parcelasImpares)

        }
    }, [data])



    return <>

        <Box sx={{ bgcolor: 'black', color: 'white', textAlign: 'center', mt: 3, mb: 1}}>
            <Typography variant="h6" gutterBottom>
                Forma de Pagamento
            </Typography>
        </Box>
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <Table size="small" aria-label="table with impares background">
                    <TableHead>
                        <TableRow>
                            <TableCell>Parcela</TableCell>
                            <TableCell>Valor</TableCell>
                            <TableCell>Vencimento</TableCell>
                            <TableCell>Comprador</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {impares?.map((parcela, idx) => (
                            <TableRow key={idx} className={idx % 2 === 0 ? 'odd-row' : 'even-row'}>
                                <TableCell>{parcela.numero}</TableCell>
                                <TableCell>{numberWithPoints(parcela.valor)}</TableCell>
                                <TableCell>{normalizeTimestemp(parcela.vencimento)}</TableCell>
                                <TableCell>{parcela.comprador}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Grid>

            <Grid item xs={6}>
                <Table size="small" aria-label="table with pares background">
                    <TableHead>
                        <TableRow>
                            <TableCell>Parcela</TableCell>
                            <TableCell>Valor</TableCell>
                            <TableCell>Vencimento</TableCell>
                            <TableCell>Comprador</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {pares?.map((parcela, idx) => (
                            <TableRow key={idx} className={idx % 2 === 0 ? 'odd-row' : 'even-row'}>
                                <TableCell>{parcela.numero}</TableCell>
                                <TableCell>{numberWithPoints(parcela.valor)}</TableCell>
                                <TableCell>{normalizeTimestemp(parcela.vencimento)}</TableCell>
                                <TableCell>{parcela.comprador}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    </>
}