import "./FilterForm.css"
import React from "react";
import TuneIcon from '@mui/icons-material/Tune';
import Popup from "../Popup/Popup";
import {Button, Form} from "react-bootstrap";
import SearchIcon from '@mui/icons-material/Search';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';

const FilterForm = ({lgShow, setLgShow, handleSubmit, clearFilter, children, showSecondaryButton}) => {

    const button = <>
        Filtros <TuneIcon fontSize="medium"/>
    </>


    const title = <>
        Filtros
    </>

    const iconSpan = <>
        <TuneIcon fontSize="large"/>
        <h3>Escolha quais campos filtrar</h3>
    </>

    const secondaryButton = <Button className='btn-secondary'
                                    onClick={() => {
                                        clearFilter()
                                    }}
    >
        Limpar Filtros <CleaningServicesIcon fontSize="medium"/>
    </Button>

    return (
        <Popup
            buttonText={button}
            title={title}
            iconSpan={iconSpan}
            lgShow={lgShow} setLgShow={setLgShow}
            buttonVariant={"text"} buttonClass='btn btn-primary btn-show-filter'
            additionalComponent={showSecondaryButton? secondaryButton : null}
        >
            <Form onSubmit={handleSubmit} className="form-inputs form-filter">
                {children}
                <Button type="submit">
                    Filtrar  <SearchIcon fontSize="medium"/>
                </Button>
                <Button type="reset" onClick={clearFilter} className="btn btn-secondary">
                    Limpar  <CleaningServicesIcon fontSize="medium"/>
                </Button>
            </Form>
        </Popup>
    )
}
export default FilterForm