import {useState} from "react";
import WriteOffList from "./Components/WriteOffList";
import PaginationControl from "../../component/Pagination/PaginationControl";
import ContentContainer from "../../component/ContentContainer";
import {ApiUrl} from "../../auth/authMethods";

export default function ManualWriteOff() {

    const baseUrl = `${ApiUrl}/parcelas`
    const [loadData, setLoadData] = useState(true)

    const [size, setSize] = useState(10)
    const [page, setPage] = useState(0)
    const [sort, setSort] = useState("")

    const [totalPages, setTotalPages] = useState(0)
    const [totalElements, setTotalElements] = useState(0)

    return <ContentContainer width='xl'>
        <h1>Lista de Baixas Manuais </h1>
        <WriteOffList
            baseUrl={baseUrl + '/list'}
            loadData={loadData}
            setLoadData={setLoadData}
            page={page}
            setPage={setPage}
            size={size}
            setSize={setSize}
            totalPages={totalPages}
            setTotalPages={setTotalPages}
            totalElements={totalElements}
            setTotalElements={setTotalElements}
            sort={sort} setSort={setSort}
        />
        <PaginationControl
            totalElements={totalElements}
            page={page}
            setPage={setPage}
            size={size}
            setSize={setSize}
            setLoadData={setLoadData}
        />
    </ContentContainer>
}