import React, {useState} from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DisplayDetailsData from "../../../component/DisplayDetailsData/DisplayDetailsData";
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import {aliasRoles} from "./aliasRoles";
import Popup from "../../../component/Popup/Popup";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import BadgeIcon from '@mui/icons-material/Badge';
import RoomIcon from '@mui/icons-material/Room';
import PercentIcon from '@mui/icons-material/Percent';
import {numberWithPoints} from "../../../utils/numberWithPoints";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import displayNullableData from "../../../utils/displayNullableData";

export default function UserDetails({item}) {

    const [lgShow, setLgShow] = useState(false);

    const button = <>
        <VisibilityIcon fontSize="medium"/>
        &nbsp; Detalhes do Usuário
    </>

    const title = "Detalhes do Usuário"

    const iconSpan = <>
        <VisibilityIcon fontSize="large" id="icon-svg"/>
        {title}
    </>

    const part = item.participante

    const handleEndereco = (enderecoObj) => {
        return `${enderecoObj.logradouro}, ${enderecoObj.numero} ${enderecoObj.complemento ? '- ' + enderecoObj.complemento : ''}, ${enderecoObj.bairro}, ${enderecoObj.cidade} - ${enderecoObj.estado}, ${enderecoObj.cep}`;
    }

    return (
        <Popup
            buttonText={button}
            title={title}
            iconSpan={iconSpan}
            lgShow={lgShow} setLgShow={setLgShow}
            buttonClass='dropdown-item'
        >
            {/* Dados primários */}
            <DisplayDetailsData
                key="primaryData" // Adicione uma chave única aqui
                title='Dados Primários'
                itens={[
                    {
                        icon: <PersonIcon key="item1"/>,
                        display: `Nome do Usuário  ${item.username}`
                    },
                    {
                        icon: <EmailIcon key="item2"/>,
                        display: `Email do Usuário:  ${item.email}`
                    },
                ]}
            />
            {/* Dados Secundários */}
            <DisplayDetailsData
                key="secondData" // Adicione uma chave única aqui
                title='Dados Secundários'
                itens={[
                    {
                        icon: <PersonIcon key="item1"/>,
                        display: `Tipo de Usuário:  ${aliasRoles.find(role => role.received === item.roles[0].name).display}`
                    },
                    {
                        icon: <AutorenewIcon key="item4"/>,
                        display: `Status do Usuário:  ${item.isEnabled ? 'Ativo' : 'Inativo'}`
                    },
                    {
                        icon: <RecentActorsIcon key="item5"/>,
                        display: `Credencial do usuário:  ${item.isCredentialsNonExpired ? 'Ativa' : 'Inativa'}`
                    },
                ]}
            />
            {/* Dados de Participante */}
            {part &&
                <DisplayDetailsData
                    key="secondData" // Adicione uma chave única aqui
                    title='Dados de Participante'
                    itens={[
                        {
                            icon: <PersonIcon key="item1"/>,
                            display: `Nome:  ${part.nome}`
                        },
                        {
                            icon: <LocalPhoneIcon/>,
                            display: `Telefone:  ${displayNullableData(part?.phoneNumber)}`
                        },
                        {
                            icon: <BadgeIcon key="item7"/>,
                            display: `Documento:  ${part?.documento}`
                        },
                        {
                            icon: <MonetizationOnIcon key="item8"/>,
                            display: `Chave Pix:  ${part.dadosPix.tipoChave} - ${part.dadosPix.chavePix}`
                        },
                        {
                            icon: <RoomIcon key="item9"/>,
                            display: `Endereço:  ${handleEndereco(part.endereco)}`
                        }
                    ]}
                />
            }


            {part && item.roles[0].name === 'ROLE_PARCEIRO_COMERCIAL' &&
                <DisplayDetailsData
                    key="secondData" // Adicione uma chave única aqui
                    title='Dados de Parceiro Comercial'
                    itens={[
                        {
                            icon: <PercentIcon key="item1"/>,
                            display: `Taxa Total: ${numberWithPoints(part.parceiroComercial.taxaTotal)} %`
                        },
                        {
                            icon: <PercentIcon key="item7"/>,
                            display: `Taxa de Split (repasse para o parceiro): 
                            ${numberWithPoints(part.parceiroComercial.taxaSplit)} %`
                        },
                    ]}
                />
            }
        </Popup>
    )


}

