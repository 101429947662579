import {Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {Link} from "react-router-dom";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import React from "react";

export default function DisplayLinks({links, collapses, closeMenu}){

    const linkStyle = {
        textDecoration: 'none',
        color: 'black'
    }

    const handleClick = (ref) => {
        ref.current.click()
        closeMenu()
    }

    return <>

        {links.map(link => (
            <ListItem key={link.path} disablePadding>
                <ListItemButton onClick={() => handleClick(link.ref)}>
                    <ListItemIcon style={{marginRight: '-10%'}}>
                        {link.icon}
                    </ListItemIcon>
                    <Link to={link.path} style={linkStyle} ref={link.ref}>{link.name}</Link>
                </ListItemButton>
            </ListItem>
        ))}

        {collapses.map((collapse,index) => (
            <span key={index}>
                <ListItemButton  onClick={() => collapse.state[1](!collapse.state[0])}>
                    <ListItemIcon style={{marginRight: '-10%'}}>
                        <AdminPanelSettingsIcon/>
                    </ListItemIcon>
                    <ListItemText primary={collapse.name}/>
                    {collapse.state[0] ? <ExpandLess/> : <ExpandMore/>}
                </ListItemButton>

                <Collapse in={collapse.state[0]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {collapse.links.map(link => (
                            <ListItem key={link.path} disablePadding>
                                <ListItemButton onClick={() => handleClick(link.ref)} sx={{pl: 4}}>
                                    <ListItemIcon style={{marginRight: '-10%'}}>
                                        {link.icon}
                                    </ListItemIcon>
                                    <Link to={link.path} style={linkStyle} ref={link.ref}>{link.name}</Link>
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Collapse>
            </span>
        ))}
    </>
}