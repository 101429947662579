import TextField from "@mui/material/TextField";
import {useEffect, useRef, useState} from "react";
import {buscarCep} from "../../../../utils/buscarCep";
import applyRegex from "../../../../utils/applyRegex";

export default function InputsAddress({setEndereco, endereco}) {


    const [cep, setCep] = useState(endereco? endereco.cep : '')
    const [logradouro, setLogradouro] = useState(endereco? endereco.logradouro : '')
    const [bairro, setBairro] = useState(endereco? endereco.bairro : '')
    const [cidade, setCidade] = useState(endereco? endereco.cidade : '')
    const [estado, setEstado] = useState(endereco? endereco.estado : '')
    const [numero, setNumero] = useState(endereco? endereco.numero : '')
    const [complemento, setComplemento] = useState(endereco? endereco.complemento : '')

    const [cepInicial, setCepInicial] = useState(endereco? endereco.cep : '')

    const inputNumberRef = useRef()

    useEffect(() => {
        setEndereco({
            cep: cep.replace('-',''),
            logradouro,
            bairro,
            cidade,
            estado,
            numero,
            complemento
        })
    }, [cep, logradouro, bairro, cidade, estado, numero, complemento])

    useEffect(() => {

        if ((cep.length === 8 || cep.length === 9) && cep !== cepInicial) {

            const resp = buscarCep(cep)

            resp.then((data) => {
                if (data) {
                    if (data.logradouro)
                        setLogradouro(data.logradouro)
                    if (data.bairro)
                        setBairro(data.bairro)
                    if (data.localidade)
                        setCidade(data.localidade)
                    if (data.uf)
                        setEstado(data.uf)

                    inputNumberRef.current.focus()
                }
            })
        }

    }, [cep])

    return <>
        <TextField
            label="CEP"
            required={true}
            inputProps={{
                maxLength: 9,
                min: 1,
            }}
            type="text"
            errortext="Campo obrigatório!"
            value={cep}
            onChange={(e) => setCep(e.target.value)}
            fullWidth
        />

        <TextField
            label="Logradouro"
            required={true}
            inputProps={{
                maxLength: 80
            }}
            type="text"
            erortext="Campo obrigatório!"
            value={logradouro}
            onChange={(e) => setLogradouro(e.target.value)}
            fullWidth
        />
        <TextField
            id="input-number"
            label="Número"
            required={true}
            inputProps={{
                maxLength: 80
            }}
            type="text"
            erortext="Campo obrigatório!"
            value={numero}
            onChange={(e) => setNumero(e.target.value)}
            fullWidth
            inputRef={inputNumberRef}
        />
        <TextField
            label="Complemento"
            inputProps={{
                maxLength: 80
            }}
            type="text"
            erortext="Campo obrigatório!"
            value={complemento}
            onChange={(e) => setComplemento(e.target.value)}
            fullWidth
        />
        <TextField
            label="Bairro"
            required={true}
            inputProps={{
                maxLength: 80
            }}
            type="text"
            erortext="Campo obrigatório!"
            value={bairro}
            onChange={(e) => setBairro(e.target.value)}
            fullWidth
        />
        <TextField
            label="Cidade"
            required={true}
            inputProps={{
                maxLength: 80
            }}
            type="text"
            erortext="Campo obrigatório!"
            value={cidade}
            onChange={(e) => setCidade(e.target.value)}
            fullWidth
        />
        <TextField
            label="Estado (UF)"
            required={true}
            inputProps={{
                maxLength: 2
            }}
            type="text"
            erortext="Campo obrigatório!"
            value={estado}
            onChange={(e) => setEstado(e.target.value)}
            fullWidth
        />
    </>
}