import React, {useEffect, useState} from "react";
import axios from "axios";
import Select from "react-select";
import "./SelectInput.css"
import BasicLoader from "../BasicLoader/BasicLoader";

const SelectInput = ({
                         url, list, set, setObj,
                         defaultValue, returnList,
                         placeholder, required, getLabel,
                         getValueForList, maxMenuHeight,
                         isClearable
                     }) => {


    let listMapped = []
    const [select, setSelect] = useState(false)
    const [loading, setLoading] = useState(true)

    const [marginBottom, setMarginBottom] = useState('100%')

    useEffect(() => {
        axios.get(url)
            .then(function (response) {
                set(response.data.content)
                setLoading(false)
            }).catch(function (error) {
            console.log(error);
        })
    }, [])

    useEffect(() => {
        if (list.length > 0) {
            if (typeof returnList === 'function') {
                listMapped = returnList()
            } else
                listMapped = defaultValues()

            let item

            if (defaultValue !== undefined && defaultValue !== '') {
                item = (listMapped.find(item => item.value === defaultValue))
                setObj(item?.value)
            }
            setSelect((
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    id="form_select"
                    required={required}
                    // defaultValue={listMapped.find(item => item.value === defaultValue)}
                    defaultValue={item}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={isClearable}
                    isRtl={false}
                    isSearchable={true}
                    options={listMapped}
                    onChange={(e) => setObj(e?.value)}
                    placeholder={placeholder}
                    maxMenuHeight={maxMenuHeight}
                    onMenuOpen={() => setMarginBottom('20%')}
                    // onChange={(e) => setObj(e.target.value)}
                />
            ))
        }
    }, [list])

    const defaultValues = () => {
        let returnList = []
        list.forEach((item) => {
            // this function variable 'label' need to be passed
            // if you want to display a property different of 'name'
            // for the items of the list
            const label = getLabel ? getLabel(item) : item.name
            const value = getValueForList ? getValueForList(item) : item.id
            returnList.push({value, label})
        })
        return returnList
    }

    return (
        <>
            {!select ?
                (<>
                    <BasicLoader height={10} message={'Carregando dados...'}/>
                    <input type="text" name="block-submit" hidden required/>
                </>) :
                select
            }
        </>
    )
}

export default SelectInput