import React from 'react';
import Modal from 'react-bootstrap/Modal';

import "./LargeModel.css"

const LargeModal = ({
                        lgShow, setLgShow,
                        buttonText, buttonClass,
                        buttonId, title, iconSpan,
                        children,
                        backdrop, hideCloseButton,
                        secondaryButton, highlighted
                    }) => {

    return (
        <>

            <span onClick={() => setLgShow(true)}  style={{cursor: "pointer"}}
                  className={buttonClass ? buttonClass : 'btn btn-primary'}
                  id={buttonId}>
                {buttonText}
            </span>

            {secondaryButton}

            <Modal
                className={highlighted ? "modal-lg" : "modal-xl" }
                show={lgShow}
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                backdrop={backdrop}
                style={{ backgroundColor: highlighted ? 'rgba(0, 0, 0, 0.8)' : '' }}
            >
                <Modal.Header closeButton={!hideCloseButton}>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="row contact-info">
                        <div className="col-md-4"></div>
                        <div className="icon-span col-md-4">
                            {iconSpan}
                        </div>
                        <div className="col-md-4"></div>
                    </div>


                    {children}

                </Modal.Body>
            </Modal>
        </>
    );
}

export default LargeModal