import {useNavigate} from "react-router-dom";
import Alert from 'react-bootstrap/Alert';
import useAuthMethods from "../../auth/authMethods";
import {useState} from "react";
import LoginForm from "./Components/LoginForm";
import TokenVerificationForm from "./Components/TokenVerificationForm";
import {useUser} from "../../auth/AuthProvider";

// import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export default function Login() {

    const navigate = useNavigate()
    const {login, twoStepVerification} = useAuthMethods()
    const {setUser} = useUser();

    const [error, setError] = useState(false);
    const [loginData, setLoginData] = useState(false);
    const [isInSecondStep, setIsInSecondStep] = useState(false)
    const [isLoading, setIsLoading] = useState(false);

    const executeLogin = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        setIsLoading(true)
        login(
            data.get("login"),
            data.get("password")
        ).then((result) => {
            setIsLoading(false)
            if (result === 0) {
                setError(
                    <Alert key='danger' variant='danger'>
                        Usuário e/ou senha incorreto(s)
                    </Alert>
                )
            } else if (result === 1) {
                setLoginData(data.get("login"))
                setIsInSecondStep(true)
                setError(false)
            } else if (result === 2) {
                setError(false)
                navigate('/')
            } else if (result === 3) {
                setError(false)
                setUser({
                    login: data.get("login"),
                    firstAccess: true
                })
                navigate(`/change-password`)
            } else if (result === 4) {
                setError(false)
                setUser({
                    login: data.get("login"),
                    passwordExpired: true
                })
                navigate(`/change-password`)
            } else if (result === 6) {
                setError(
                    <Alert key='danger' variant='danger'>
                        Este usuário não acessou o sistema nos últimos 90 dias e portanto foi bloqueado.
                        Contate o suporte para regularizar!
                    </Alert>
                )
            } else if (result === 7) {
                setError(
                    <Alert key='danger' variant='danger'>
                        Este usuário esta bloqueado, contate o suporte para regularizar!
                    </Alert>
                )
            } else {
                setError(
                    <Alert key='danger' variant='danger'>
                        Ocorreu um erro em nosso serviço, tente novamente mais tarde
                    </Alert>
                )
            }
        })
    }

    const verifyTwoStepsCode = (event, token) => {
        event.preventDefault();

        twoStepVerification(
            token, loginData
        ).then((result) => {
            setIsLoading(false)
            if (result === 0) {
                setError(
                    <Alert key='danger' variant='danger'>
                        Token incorreto!
                    </Alert>
                )
            } else if (result === 1) {
                navigate('/')
            } else {
                setError(
                    <Alert key='danger' variant='danger'>
                        Ocorreu um erro em nosso serviço, tente novamente mais tarde
                    </Alert>
                )
            }
        })
    }

    return !isInSecondStep ?
        <LoginForm
            error={error} handleSubmit={executeLogin}
            loginLoading={isLoading}/> :
        <TokenVerificationForm
            error={error} handleSubmit={verifyTwoStepsCode}
            isLoading={isLoading}/>
}
