import Decimal from 'decimal.js';

export function calculateNextDate(diaDoMes, dataVencimento, periodoParcela) {
    const date = new Date(dataVencimento);
    if (periodoParcela === 'MENSAL') {
        date.setMonth(date.getMonth() + 1);
    } else if (periodoParcela === 'ANUAL') {
        date.setFullYear(date.getFullYear() + 1);
    }
    date.setDate(diaDoMes);
    return date.toISOString().split('T')[0];
}

export function generateParcela(pedido, papelLeilaoList, periodoParcela, qtdParcelas, totalValor) {
    try {
        if (!pedido || !pedido.modalParcelamento || !pedido.modalParcelamento.dataVencimento) {
            throw new Error('Dados de pedido ou modalParcelamento estão indefinidos ou incompletos:', pedido);
        }

        const totalValorPedido = new Decimal(totalValor);
        const totalParcelas = qtdParcelas;
        const compradores = papelLeilaoList.filter(papel => papel.papel === "COMPRADOR");

        if (!compradores.length) {
            throw new Error('Nenhum comprador encontrado:', papelLeilaoList);
        }

        const parcelasCompradores = [];

        compradores.forEach(comprador => {
            let countAglutinacao = 1;
            let countQtdAglutinacao = 1;
            let dataVencimentoComprador = pedido.modalParcelamento.dataVencimento;

            if (!dataVencimentoComprador) {
                throw new Error('dataVencimento está indefinido para comprador:', comprador);
            }

            const participante = comprador.participante;
            const porcentagem = new Decimal(comprador.porcentagem).dividedBy(100);
            const valorParaComprador = totalValorPedido.times(porcentagem);
            const valorParcela = valorParaComprador.dividedBy(totalParcelas).toFixed(2);

            let totalParcelasAjustado = totalParcelas;
            if (pedido.modalParcelamento.juncaoDeParcelasAglutinadas) {
                totalParcelasAjustado -= pedido.modalParcelamento.quantidadeAglutinacoes;
            }

            for (let i = 1; i <= totalParcelasAjustado; i++) {
                let valorParcelaComJuncao = new Decimal(0);
                if (countQtdAglutinacao <= pedido.modalParcelamento.quantidadeAglutinacoes) {
                    if (countAglutinacao <= pedido.modalParcelamento.aglutinacoes) {
                        if (pedido.modalParcelamento.juncaoDeParcelasAglutinadas) {
                            valorParcelaComJuncao = valorParaComprador.times(pedido.modalParcelamento.aglutinacoes).dividedBy(totalParcelas);
                            dataVencimentoComprador = calculateNextDate(pedido.modalParcelamento.diaDoMes, dataVencimentoComprador, periodoParcela);
                            countAglutinacao = 1;
                            countQtdAglutinacao++;
                        }
                    } else {
                        dataVencimentoComprador = calculateNextDate(pedido.modalParcelamento.diaDoMes, dataVencimentoComprador, periodoParcela);
                        countAglutinacao = 1;
                        countQtdAglutinacao++;
                    }
                } else {
                    dataVencimentoComprador = calculateNextDate(pedido.modalParcelamento.diaDoMes, dataVencimentoComprador, periodoParcela);
                }

                const parcela = {
                    valorOriginal: totalValorPedido.toNumber(),
                    valor: valorParcelaComJuncao.equals(0) ? parseFloat(valorParcela) : valorParcelaComJuncao.toNumber(),
                    dateCreated: new Date().toISOString(),
                    vencimento: dataVencimentoComprador,
                    status: 'CRIADA',
                    baixaManual: false,
                    numero: i,
                    statusNotificacao: 'NAO_NOTIFICAR',
                    pedido: pedido,
                    boleto: null,
                    participante: participante ? `${comprador.email} (${participante})` : `Participante não encontrado! ${comprador.email}`
                };

                parcelasCompradores.push(parcela);
                countAglutinacao++;
            }
        });

        return parcelasCompradores;
    } catch (error) {
        console.error('Ocorreu um erro ao gerar as parcelas:', error);
        return []; // Retorna um array vazio em caso de erro
    }
}

export function returnDataService(data) {
    const parcelas = generateParcela(data, data.users, data.modalParcelamento.periodoParcela, data.pedido.qtdParcelas, data.pedido.valor);
    console.log('Parcelas Geradas:', parcelas);
    return { ...data, parcelas };
}
