import React, {useState} from "react";
import {Drawer, List} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import {useUser} from "../../auth/AuthProvider";
import {Dropdown} from "react-bootstrap";
import AdminLinks from "./MenuLinks/AdminLinks";
import CommonUserLinks from "./MenuLinks/CommonUserLinks";

// icons
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';

export default function SidebarMenu({children}) {

    const {user} = useUser()
    const navigate = useNavigate()

    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };

    const { stopImpersonating } = useUser();

    return (
        <div className="">
            <nav className="navbar">
                {user?.token ?
                    <MenuIcon
                        style={{width: '6rem', cursor: 'pointer'}}
                        onClick={toggleMenu}
                    /> :
                    <HomeIcon
                        style={{width: '6rem', cursor: 'pointer'}}
                        onClick={() => navigate('/')}
                    />
                }
                {/*<img src="/logo-wb.png" alt="" style={{width: '6rem', margin: '0'}}/>*/}

                <img
                    src="/logo-wb.png" alt=""
                    style={{width: '8rem', margin: '-1% 0', padding: '0', cursor: 'pointer'}}
                    onClick={() => navigate('/')}
                />

                <div style={{ marginRight: '1%' }} className="justify-content-end">
                    {user?.token ?
                        <Dropdown>
                            <Dropdown.Toggle variant="outlined" id="dropdown-basic">
                                Olá {user?.username}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {user?.mainUser && (
                                    <>
                                        <Dropdown.Item onClick={stopImpersonating}>
                                            Parar de impersonar {user.mainUser}
                                        </Dropdown.Item>
                                        <Dropdown.Divider />
                                    </>
                                )}
                                <Dropdown.Item href="#/action-1">
                                    <Link to={'/change-password'} onClick={closeMenu} className='link-dark text-decoration-none dropdown-item'>
                                        Alterar senha
                                    </Link>
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item href="#/action-3">
                                    <Link to={'/logout'} onClick={closeMenu} className='link-dark text-decoration-none dropdown-item'>
                                        <LogoutIcon fontSize='medium' /> &nbsp; Sair
                                    </Link>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown> :
                        <Link to={'/login'} className="btn btn-dark">
                            Entrar <LoginIcon fontSize='medium' />
                        </Link>
                    }
                </div>
            </nav>

            <Drawer
                anchor="left"
                open={menuOpen}
                onClose={closeMenu}
            >
                <div className="menu-container">
                    <List>
                        <div className="text-center">
                            <img src="/logo-wb.png" alt="" style={{width: '6rem'}}/>
                        </div>
                        <hr/>

                        {user?.isAdmin ?
                            <AdminLinks closeMenu={closeMenu}/> :
                            <CommonUserLinks closeMenu={closeMenu} isGroupLeader={user?.isGroupLeader}/>
                        }

                    </List>
                </div>
            </Drawer>

            {/* Conteúdo principal */}
            <div className={`main-content ${menuOpen ? 'blurred' : ''}`}>
                {children}
            </div>
        </div>
    )
}
