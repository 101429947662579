import {useState} from "react";
import TableList from "../../../component/TableList/TableList";
import FilterPayment from "./FilterPayment";
import {statusList, typeList} from "./lists";
import {numberWithPoints} from "../../../utils/numberWithPoints";
import {normalizeTimestemp} from "../../../utils/normalizeTimestemp";
import {useUser} from "../../../auth/AuthProvider";
import PaymentsReport from "../../PaymentsToAprove/Components/PaymentsReport";
import PaymentActions from "./PaymentActions";

export default function PaymentList({
                                        baseUrl, loadData,
                                        setLoadData, size, page,
                                        setTotalPages, setTotalElements,
                                        sort, setSort, updateCode, setPage,
                                        displayReport
                                    }) {

    const {user} = useUser()

    const [data, setData] = useState([])
    const [error, setError] = useState(false)
    const [filter, setFilter] = useState({})

    const titles = user.isAdmin ? [
        'Data de Agendamento',
        'Valor',
        'Status',
        'Tipo',
        'Participante',
    ] : [
        'Data de Agendamento',
        'Valor',
        'Status',
        'Tipo',
    ]

    return (
        <>
            <FilterPayment filter={filter} setFilter={setFilter} setLoadData={setLoadData} setPage={setPage}/>
            {displayReport &&
                <PaymentsReport data={data}/>
            }
            {/*<PopupAddAnimal baseUrl={baseUrl} setLoadData={setLoadData}/>*/}
            <TableList
                titles={titles} baseUrl={baseUrl}
                page={page} sort={sort} size={size}
                loadData={loadData}
                setTotalPages={setTotalPages} setTotalElements={setTotalElements}
                setData={setData} setLoadData={setLoadData} filter={filter}
                error={error} setError={setError} isFilterByUser={!user.isAdmin}
            >
                {data.map((item) =>
                    <tr key={item.id}>
                        <td>{normalizeTimestemp(item.scheduledDate, true)}</td>
                        <td>R$ {numberWithPoints(item.valor)}</td>
                        <td>{statusList.filter(stt => stt.value === item.status)[0].label}</td>
                        <td>{typeList.filter(type => type.value === item.tipoPagamento)[0].label}</td>
                        {user.isAdmin && <>
                            <td>{item.participante.nome} - {item.participante.documento}</td>
                        </>}
                        <td><PaymentActions item={item} setLoadData={setLoadData}/></td>
                    </tr>
                )}
            </TableList>
        </>
    )
}