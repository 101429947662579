import FilterForm from "../../../component/FilterForm/FilterForm";
import React, {useState} from "react";
import TextField from "@mui/material/TextField";
import Select from "react-select";
import {statusList} from "./statusList";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {handleChangeData} from "../../../utils/handleChangeData";
import {ApiUrl} from "../../../auth/authMethods";
import SelectInput from "../../../component/SelectInput";
import {InputLabelProps} from "../../../utils/InputLabelProps";
import {handleChangeNumber} from "../../../utils/handleChangeNumber";

export default function FilterBoleto({filter, setFilter, setLoadData, setPage}) {

    const [lgShow, setLgShow] = useState(false);
    
    const [eventoId, setEventoId] = useState('');
    const [numeroLote, setNumeroLote] = useState('');
    const [parcela, setParcela] = useState('');
    const [identificador, setIdentificador] = useState('');
    const [dataVencimento, setDataVencimento] = useState('')
    const [status, setStatus] = useState('')

    const [eventoList, setEventoList] = useState([]);

    const handlesSubmit = (e) => {

        e.preventDefault();

        setFilter({
            ...(eventoId && {eventoId}),
            ...(numeroLote && {numeroLote}),
            ...(parcela && {parcela}),
            ...(identificador && {identificador}),
            ...(dataVencimento && {dataVencimento: dataVencimento + 'T00:00:00'}),
            ...(status && {status}),
        });

        setLgShow(false);
        setLoadData(true);
        setPage(0);
    }

    const clearFilter = () => {
        setEventoId('')
        setNumeroLote('')
        setParcela('')
        setIdentificador('')
        setStatus('')
        setDataVencimento('')
        setFilter({});
        setLgShow(false);
        setLoadData(true);
        setPage(0);
    }

    return <>
        <FilterForm
            handleSubmit={handlesSubmit} clearFilter={clearFilter}
            lgShow={lgShow} setLgShow={setLgShow}
            showSecondaryButton={Object.keys(filter).length !== 0}
        >
            <SelectInput
                url={ApiUrl + '/evento/list?status=ABERTO&size=20000'}
                list={eventoList}
                set={setEventoList}
                defaultValue={eventoId}
                setObj={setEventoId}
                returnList={() => {
                    let returnList = []
                    eventoList.forEach((item) => {
                        returnList.push({value: item.id, label: `${item.nomeEvento}`})
                    })
                    return returnList
                }}
                placeholder={"Evento"}
                isClearable
                fullWidth
            />
            <TextField
                label="Número do Lote"
                className='mt-2'
                inputProps={{
                    maxLength: 40
                }}
                value={numeroLote}
                onChange={(e) => setNumeroLote(e.target.value)}
                InputLabelProps={InputLabelProps}
                fullWidth
            />
            <TextField
                label="Parcela"
                type="text"
                value={parcela}
                inputProps={{
                    minLength: 0,
                    maxLength: 3,
                }}
                onChange={(e) => handleChangeNumber(setParcela, e.target.value)}
                InputLabelProps={InputLabelProps}
                fullWidth
            />
            <TextField
                label="Identificador"
                inputProps={{
                    maxLength: 40
                }}
                value={identificador}
                onChange={(e) => setIdentificador(e.target.value)}
                InputLabelProps={InputLabelProps}
                fullWidth
            />
            <DatePicker
                label="Data de Vencimento"
                sx={{
                    width: '100%',
                    zIndex: 0
                }}
                defaultValue={dataVencimento ? dayjs(dataVencimento) : null}
                onChange={(e) => handleChangeData(e, setDataVencimento)}
                InputLabelProps={InputLabelProps}
            />
            <Select
                className="basic-single"
                classNamePrefix="select"
                id="form_select"
                required={false}
                defaultValue={statusList.filter(item => item.value === status)}
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                isSearchable={true}
                options={statusList}
                onChange={(e) => setStatus(e?.value)}
                InputLabelProps={InputLabelProps}
                placeholder={'Status'}
            />
        </FilterForm>
    </>
}