import {Dropdown} from "react-bootstrap";
import SettingsIcon from "@mui/icons-material/Settings";
import React from "react";
import EventDetails from "./EventDetails";
import PopupEditEvent from "./PopupEditEvent";
import {ApiUrl} from "../../../auth/authMethods";
import PopupDeleteEvent from "./PopupDeleteEvent";

export default function EventActions({item, setLoadData, baseUrl}) {

    return <Dropdown drop='down-centered'>
        <Dropdown.Toggle variant="secondary">
            Ações &nbsp;
            <SettingsIcon fontSize="medium"/>
        </Dropdown.Toggle>

        <Dropdown.Menu>
            <EventDetails item={item}/>
            <PopupEditEvent item={item} setLoadData={setLoadData} baseUrl={baseUrl}/>
            <PopupDeleteEvent item={item} setLoadData={setLoadData} baseUrl={baseUrl}/>
        </Dropdown.Menu>

    </Dropdown>
}