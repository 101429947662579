import {useState} from "react";
import TableList from "../../../component/TableList/TableList";
import {normalizeTimestemp} from "../../../utils/normalizeTimestemp";
import AntecipacaoActions from "./AntecipacaoActions";
import FilterAntecipacao from "./FilterAntecipacao";

export default function AntecipacaoList({
                                      baseUrl, loadData,
                                      setLoadData, size, page,
                                      setTotalPages, setTotalElements,
                                      sort, setSort, updateCode, setPage
                                  }) {

    const [data, setData] = useState([])
    const [error, setError] = useState(false)
    const [filter, setFilter] = useState({})

    const titles = [
        'Data da antecipação',
        'Antecipante',
        'Valor Antecipado',
        'Status'
    ]

    return (
        <>
            <FilterAntecipacao filter={filter} setFilter={setFilter} setLoadData={setLoadData} setPage={setPage}/>
            {/*<PopupAddEvent baseUrl={baseUrl} setLoadData={setLoadData}/>*/}
            <TableList
                titles={titles} baseUrl={baseUrl + '/list'}
                page={page} sort={sort} size={size}
                loadData={loadData}
                setTotalPages={setTotalPages} setTotalElements={setTotalElements}
                setData={setData} setLoadData={setLoadData} filter={filter}
                error={error} setError={setError} isFilterByUser={true}
            >
                {data.map((item) =>
                    <tr key={item.id}>
                        <td>{normalizeTimestemp(item.dataAntecipacao)}</td>
                        <td>{item.nomeAntecipante}</td>
                        <td>{item.valor}</td>
                        <td>{item.status}</td>
                        <td><AntecipacaoActions item={item} setLoadData={setLoadData} baseUrl={baseUrl}/></td>
                    </tr>
                )}
            </TableList>
        </>
    )
}