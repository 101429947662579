import React, {useState} from "react";
import PopupEdit from "../../../component/PopupEdit/PopupEdit";
import InputUsersEdit from "./inputs/InputUsersEdit";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import {ApiUrl} from "../../../auth/authMethods";
import InputsParticipante from "./inputs/InputsParticipante";
import Box from "@mui/material/Box";


const PopupEditUsers = ({item, setLoadData}) => {

    const id = item.id

    const [username, setUsername] = useState(item.username);
    const [email, setEmail] = useState(item.email);
    const [roleId, setRoleId] = useState(item.roles[0].id)
    const [isEnabled, setIsEnabled] = useState(item.isEnabled);
    const [isCredentialsNonExpired, setIsCredentialsNonExpired] = useState(item.isCredentialsNonExpired);
    const [isAccountNonExpired, setIsAccountNonExpired] = useState(item.isAccountNonExpired);
    const [isAccountNonLocked, setIsAccountNonLocked] = useState(item.isAccountNonLocked);
    const [isTwoFactorEnabled, setIsTwoFactorEnabled] = useState(item.isTwoFactorEnabled);

    const hasPix =
        (item.participante?.dadosPix.chavePix.length > 0)

    const [hidePix, setHidePix] = useState(!hasPix);


    const [isAdmin, setIsAdmin] = useState(item?.roles.map((item) => item.name).includes('ROLE_ADMIN') ||
        item?.roles.includes('ROLE_ADMIN'));

    const [participante, setParticipante] = useState(item.username);
    const [taxaParceiroComercial, setTaxaParceiroComercial] = useState(item.participante?.parceiroComercial);
    const [roleList, setRoleList] = useState([]);

    const [errorMessage, setErrorMessage] = useState("")
    const [errorColumn, setErrorColumn] = useState("")
    const [blockSubmit, setBlockSubmit] = useState(false)

    const inputs = <>
        <Box mt={2} component={'h5'}>
            Dados do Usuário
        </Box>
        <hr/>
        <InputUsersEdit
            id={id}
            username={username} setUsername={setUsername}
            email={email} setEmail={setEmail}
            roles={roleId} setRoles={setRoleId}
            isEnabled={isEnabled} setIsEnabled={setIsEnabled}
            isCredentialsNonExpired={isCredentialsNonExpired} setIsCredentialsNonExpired={setIsCredentialsNonExpired}
            isAccountNonExpired={isAccountNonExpired} setIsAccountNonExpired={setIsAccountNonExpired}
            isAccountNonLocked={isAccountNonLocked} setIsAccountNonLocked={setIsAccountNonLocked}
            isTwoFactorEnabled={isTwoFactorEnabled} setIsTwoFactorEnabled={setIsTwoFactorEnabled}
            setErrorColumn={setErrorColumn}
            setErrorMessage={setErrorMessage}
            errorMessage={errorMessage}
            errorColumn={errorColumn}
            roleList={roleList} setRoleList={setRoleList}
            setIsAdmin={setIsAdmin}
        />
        {!isAdmin &&
            <InputsParticipante
                participante={item.participante}
                setParticipante={setParticipante}
                setBlockSubmit={setBlockSubmit}
                hideBankDetailsAndPix={hidePix}
                setHideBankDetailsAndPix={hasPix ? false : setHidePix}
                taxaParceiroComercial={taxaParceiroComercial}
                setTaxaParceiroComercial={setTaxaParceiroComercial}
            />
        }
    </>


    const title = "Editar Usuário"

    const returnData = () => {

        let data = {
            username,
            email,
            roleId,
            isEnabled,
            isCredentialsNonExpired,
            isAccountNonExpired,
            isAccountNonLocked,
            isTwoFactorEnabled
        }

        if (!isAdmin)
            data.participanteDto = participante

        console.log(data)

        return data
    }

    const buttonText = <>
        <DriveFileRenameOutlineIcon fontSize="medium"/>
        &nbsp; {title}
    </>

    return (
        <>
            <PopupEdit
                baseUrl={ApiUrl + '/user/' + id} setLoadData={setLoadData}
                inputs={inputs} title={title} returnData={returnData}
                setErrorMessage={setErrorMessage} setErrorColumn={setErrorColumn}
                buttonClass='dropdown-item' buttonText={buttonText}
            />
        </>
    )
}
export default PopupEditUsers