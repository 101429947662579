import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

export default function ConfirmacaoStep({ handleConfirmacao, handleBack, value }) {
    const [isRead, setIsRead] = useState(false);

    const handleCheckboxChange = (event) => {
        setIsRead(event.target.checked);
    };

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "100vh" }}
        >
            <Grid item>
                <Card sx={{ maxWidth: 600 }}>
                    <CardContent>
                        <Typography variant="h5" color="textSecondary" gutterBottom>
                            Documentação
                        </Typography>
                        <Typography variant="body" color="textSecondary">
                            Nós da Rural Crédito LTDA. temos o prazer de anunciar o lançamento do seu novo serviço de antecipação de valores. Agora, você pode contar com a praticidade de antecipar seus recebíveis de forma rápida e segura.

                            Ao optar pela antecipação conosco, você terá acesso a vantagens exclusivas, como taxas competitivas e um processo simplificado. Nosso compromisso é garantir que você tenha o melhor serviço possível.

                            <b>Destacamos que o valor antecipado será pago em até 2 dias úteis</b> após a confirmação. Essa agilidade é parte do nosso compromisso em oferecer soluções eficientes para os nossos clientes.

                            Ficamos à disposição para esclarecer qualquer dúvida e aguardamos ansiosamente para iniciar essa parceria de sucesso. Conte conosco para suas necessidades de antecipação de valores!
                        </Typography>
                    </CardContent>
                    <CardContent>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                textAlign: "center",
                            }}
                        >
                            <Typography variant="h6" color="textSecondary">
                                Deseja confirmar antecipação com o valor de R${value}?
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isRead}
                                        onChange={handleCheckboxChange}
                                        color="primary"
                                    />
                                }
                                label="Eu li e concordo com os termos"
                            />
                            <Box sx={{ display: "flex", gap: 8, mt: 2 }}>
                                <Button
                                    sx={{ width: "50%", height: 50 }}
                                    color="success"
                                    variant="contained"
                                    onClick={handleConfirmacao}
                                    disabled={!isRead}
                                >
                                    Confirmar
                                </Button>
                                <Button
                                    sx={{ width: "50%", height: 50 }}
                                    color="warning"
                                    variant="contained"
                                    onClick={handleBack}
                                >
                                    Voltar para tela de antecipação
                                </Button>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}
