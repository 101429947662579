import React, {useState} from "react";
import axios from "axios";
import Popup from "../Popup/Popup";
import {Button, Form} from "react-bootstrap";
import "./PopupAdd.css";
import displayAlertMsg from "../AlertMsg/displayAlertMsg";

const PopupAdd = ({
                      baseUrl,
                      setLoadData,
                      inputs,
                      title,
                      icon,
                      clearForm,
                      returnData,
                      setErrorMessage,
                      setErrorColumn,
                      blockSubmit,
                      button,
                      openPopupButton,
                      headers,
                      disableButton,
                      buttonClass,
                      validateData,
                      internalErrorMessage,
                      previewButton,
                      contratoButton,
                  }) => {
    // modal control
    const [lgShow, setLgShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    const iconSpan = (
        <>
            {icon}
            <h3>{title}</h3>
        </>
    );

    const buttonText = button ? button : <>Adicionar</>;

    const handleSubmit = (e) => {
        e.preventDefault();

        const data = returnData();

        let dataIsValid = true;
        if (typeof validateData === "function") dataIsValid = validateData();

        setError(false);
        if (!blockSubmit && dataIsValid) {
            setIsLoading(true);
            axios
                .post(baseUrl, data, {headers})
                .then(function () {
                    setLgShow(false);
                    if (setLoadData) setLoadData(true);
                    if (clearForm) clearForm();
                })
                .catch(function (error) {
                    console.log("Error: " + error);
                    if (error.response.status === 409) {
                        setErrorMessage(error.response.data.message);
                        setErrorColumn(error.response.data.column);
                        setError(displayAlertMsg(error.response.data.message));
                    }
                    if (error.response.status >= 500) {
                        setError(displayAlertMsg(internalErrorMessage));
                    }
                })
                .finally(() => {
                    setIsLoading(false); // Certifique-se de que isso seja executado independentemente do resultado da solicitação
                });
        }
    };

    return (
        <>
            <Popup
                backdrop="static"
                title={title}
                iconSpan={iconSpan}
                buttonText={openPopupButton? openPopupButton:buttonText}
                lgShow={lgShow}
                setLgShow={setLgShow}
                buttonClass={buttonClass ? buttonClass : "btn btn-primary button-show"}
            >
                <div>
                    <Form onSubmit={handleSubmit} className="form-inputs">
                        {inputs}

                        {error}

                        <Button
                            type="submit"
                            disabled={isLoading || disableButton}
                            className={isLoading || disableButton ? "btn btn-secondary" : ""}
                        >
                            {isLoading ? (
                                <>
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                    Aguarde...
                                </>
                            ) : (
                                buttonText
                            )}
                        </Button>
                        {previewButton}
                        {contratoButton}
                    </Form>
                </div>
            </Popup>
        </>
    );
};

export default PopupAdd;
