import React, { useState, useEffect } from "react";
import ContentContainer from "../../component/ContentContainer";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import axios from "axios";
import { ApiUrl } from "../../auth/authMethods";
import PopupAddFaq from "./Components/PopupAddFaq";

const FAQ = () => {
    const baseUrl = `${ApiUrl}/faq`;
    const [faqData, setFaqData] = useState([]);
    const [loadData, setLoadData] = useState(false);

    useEffect(() => {
        axios
            .get(baseUrl + "/list")
            .then((response) => setFaqData(response.data.content))
            .catch((error) => console.error("Error fetching FAQs:", error));
    }, [loadData]);

    const handleDelete = (id) => {
        axios
            .delete(`${baseUrl}/${id}`)
            .then(() => {
                setLoadData(!loadData);
            })
            .catch((error) => console.error("Error deleting FAQ:", error));
    };

    return (
        <ContentContainer width="xl">
            <PopupAddFaq baseUrl={baseUrl} setLoadData={setLoadData} />
            <Typography variant="h4" gutterBottom>
                Perguntas Frequentes
            </Typography>
            {faqData.map((item, index) => (
                <Accordion key={index}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`faq${index}-content`}
                        id={`faq${index}-header`}
                        sx={{ alignItems: 'center' }} // Alinhamento vertical centralizado
                    >
                        <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                            {item.question}
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => handleDelete(item.id)}
                                sx={{ marginRight: '8px' }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box>
                            <Typography>{item.answer}</Typography>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            ))}
        </ContentContainer>
    );
};

export default FAQ;
