import { NumericFormat } from 'react-number-format';
import TextField from "@mui/material/TextField";
import React from "react";

export default function CurrencyInput(props){

    return <NumericFormat
        customInput={TextField}
        thousandSeparator="."
        decimalSeparator=","
        prefix="R$ "
        decimalScale={2}
        {...props}
    />
}