import FilterForm from "../../../component/FilterForm/FilterForm";
import React, {useState} from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import {cpfMask} from "../../../utils/cpfMask";
import Select from "react-select";
import Typography from "@mui/material/Typography";
import {Switch} from "@mui/material";
import InputMask from "react-input-mask";
import {validateCpf} from "../../../utils/validateCpf";
import {validateCnpj} from "../../../utils/validateCnpj";
import SelectInput from "../../../component/SelectInput";
import {ApiUrl} from "../../../auth/authMethods";

const aliasStatus = [
    {value: 'COBRANCA_INICIADA', display: 'Cobrança Iniciada'},
    {value: 'PRIMEIRA_PARCELA_PAGA', display: 'Primeira Parcela Paga'},
    {value: 'PROCESSANDO_PARCELAS_RESTANTES', display: 'Processando Parcelas Restantes'},
    {value: 'TODAS_PARCELAS_COBRADAS', display: 'Todas as Parcelas Cobradas'},
    {value: 'UNKNOWN', display: 'Desconhecido'},
]

let returnList = []
aliasStatus.forEach((status) => {
    returnList.push({
        value: status.value,
        label: status.display
    })
})

export default function FilterOrder({filter, setFilter, setLoadData, setPage}) {

    const [lgShow, setLgShow] = useState(false);
    const [eventoId, setEventoId] = useState('');
    const [numero, setNumero] = useState('');
    const [initDate, setInitDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [documento, setDocumento] = useState('');
    const [nomeAnimal, setNomeAnimal] = useState('');
    const [status, setStatus] = useState('');

    const [isCnpj, setIsCnpj] = useState(false)

    const [eventoList, setEventoList] = useState([])

    const InputLabelProps = {
        style: {
            zIndex: 0
        },
    }

    const handlesSubmit = (e) => {

        e.preventDefault();
        let filter = {}

        if (eventoId)
            filter.eventoId = eventoId
        if (numero)
            filter.numero = numero
        if (initDate)
            filter.initDate = initDate + 'T00:00:00'
        if (endDate)
            filter.endDate = endDate + 'T23:59:59'
        if (documento)
            filter.documento = documento
        if (nomeAnimal)
            filter.nomeAnimal = nomeAnimal
        if (status)
            filter.status = status

        setFilter(filter);
        setLgShow(false);
        setLoadData(true);
        setPage(0);
    }

    const clearFilter = () => {
        setEventoId('')
        setNumero('')
        setInitDate('')
        setEndDate('')
        setDocumento('')
        setNomeAnimal('')
        setIsCnpj(false)
        setStatus('')
        setFilter({});
        setLgShow(false);
        setLoadData(true);
        setPage(0);
    }

    const toggleSwitch = () => {
        setIsCnpj(prevState => !prevState)
        setDocumento('')
    }

    return <>
        <FilterForm
            handleSubmit={handlesSubmit} clearFilter={clearFilter}
            lgShow={lgShow} setLgShow={setLgShow}
            showSecondaryButton={Object.keys(filter).length !== 0}
        >

            <SelectInput
                url={ApiUrl + '/evento/list?status=ABERTO&size=20000'}
                list={eventoList}
                set={setEventoList}
                defaultValue={eventoId}
                setObj={setEventoId}
                returnList={() => {
                    let returnList = []
                    eventoList.forEach((item) => {
                        returnList.push({value: item.id, label: `${item.nomeEvento}`})
                    })
                    return returnList
                }}
                placeholder={"Evento"}
                isClearable={true}
                fullWidth
            />

            <TextField
                label="Número do Lote"
                inputProps={{
                    maxLength: 40
                }}
                value={numero}
                onChange={(e) => {
                    setNumero(e.target.value)
                }}
                fullWidth
                InputLabelProps={InputLabelProps}
            />

            <TextField
                label="Nome do animal"
                inputProps={{
                    maxLength: 100
                }}
                value={nomeAnimal}
                onChange={(e) => {
                    setNomeAnimal(e.target.value)
                }}
                fullWidth
                InputLabelProps={InputLabelProps}
            />

            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                {/*<div style={{marginRight: '10px'}}>*/}
                <Typography variant="body1">
                    <b>Documento: &nbsp;</b>
                </Typography>
                <Typography variant="body1">
                    CPF
                </Typography>
                <Switch
                    checked={isCnpj}
                    onChange={toggleSwitch}
                    color="primary"
                    inputProps={{'aria-label': 'alternar entre CNPJ e CPF'}}
                />
                <Typography variant="body1">
                    CNPJ
                </Typography>
            </div>

            {!isCnpj ?
                <InputMask mask="999.999.999-99" value={documento} maskChar={null}
                           onChange={(e) => setDocumento(e.target.value)}>
                    {() =>
                        <TextField
                            label="CPF de um participante" variant="outlined"
                            error={documento.length === 14 && !validateCpf(documento)}
                            helperText={documento.length === 14 && !validateCpf(documento) ? 'CPF inválido' : ''}
                            inputProps={{
                                maxLength: 14,
                                minLength: 14,
                            }}
                            fullWidth
                            InputLabelProps={InputLabelProps}
                        />}
                </InputMask> :
                <InputMask mask="99.999.999/9999-99" value={documento} maskChar={null}
                           onChange={(e) => setDocumento(e.target.value)}>
                    {() =>
                        <TextField
                            label="CNPJ de um participante" variant="outlined"
                            error={documento.length === 18 && !validateCnpj(documento)}
                            helperText={documento.length === 18 && !validateCnpj(documento) ? 'CNPJ inválido' : ''}
                            inputProps={{
                                maxLength: 18,
                                minLength: 18,
                            }}
                            fullWidth
                            InputLabelProps={InputLabelProps}
                        />}
                </InputMask>
            }

            <Box mb={0.5} pb={0.5}>
                Data de criação
            </Box>
            <TextField
                label="Criado a partir de"
                type="date"
                onChange={(e) => setInitDate(e.target.value)}
                value={initDate}
                size="large"
                InputLabelProps={{
                    style: {
                        background: 'white'
                    }
                }}
                inputProps={{
                    min: "2000-01-01",
                    max: "2100-12-31"
                }}
                sx={{width: '49%', marginRight: '2%'}}
            />
            <TextField
                label="Criado até"
                type="date"
                onChange={(e) => setEndDate(e.target.value)}
                value={endDate}
                size="large"
                InputLabelProps={{
                    style: {
                        background: 'white',
                        width: '50%',
                        textAlign: 'left'
                    }
                }}
                inputProps={{
                    min: "2000-01-01",
                    max: "2100-12-31"
                }}
                sx={{width: '49%'}}
            />

            <Select
                className="basic-single"
                classNamePrefix="select"
                id="form_select"
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                isSearchable={true}
                options={returnList}
                defaultValue={returnList.find(item => item.value === status)}
                onChange={(e) => setStatus(e?.value)}
                placeholder='Status'
            />

        </FilterForm>
    </>
}