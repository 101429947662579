import FilterForm from "../../../component/FilterForm/FilterForm";
import React, {useState} from "react";
import TextField from "@mui/material/TextField";
import Select from "react-select";
import {statusList} from "./statusList";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {handleChangeData} from "../../../utils/handleChangeData";

export default function FilterEvent({filter, setFilter, setLoadData, setPage}) {

    const [lgShow, setLgShow] = useState(false);
    const [nomeEvento, setNomeEvento] = useState('');
    const [status, setStatus] = useState('')
    const [dataInicio, setDataInicio] = useState('')


    const handlesSubmit = (e) => {

        e.preventDefault();
        let filterData = {}
        if (nomeEvento)
            filterData.nomeEvento = nomeEvento
        if (dataInicio)
            filterData.dataInicio = dataInicio
        if (status)
            filterData.status = status

        setFilter(filterData);
        setLgShow(false);
        setLoadData(true);
        setPage(0);
    }

    const clearFilter = () => {
        setNomeEvento('')
        setStatus('')
        setDataInicio('')
        setFilter({});
        setLgShow(false);
        setLoadData(true);
        setPage(0);
    }

    return <>
        <FilterForm
            handleSubmit={handlesSubmit} clearFilter={clearFilter}
            lgShow={lgShow} setLgShow={setLgShow}
            showSecondaryButton={Object.keys(filter).length !== 0}
        >
            <TextField
                label="Nome do Evento"
                inputProps={{
                    maxLength: 40
                }}
                errortext="Campo obrigatório!"
                value={nomeEvento}
                onChange={(e) => setNomeEvento(e.target.value)}
                fullWidth
                autoFocus
            />
            <DatePicker
                label="Data de Início a partir de"
                sx={{
                    width: '100%'
                }}
                defaultValue={dataInicio ? dayjs(dataInicio) : null}
                onChange={(e) => handleChangeData(e, setDataInicio)}
            />
            <Select
                className="basic-single"
                classNamePrefix="select"
                id="form_select"
                required={false}
                defaultValue={statusList.filter(item => item.value === status)}
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                isSearchable={true}
                options={statusList}
                onChange={(e) => setStatus(e?.value)}
                placeholder={'Status'}
            />
        </FilterForm>
    </>
}