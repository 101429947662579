import React, {useState} from "react";
import {TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import Table from "@mui/material/Table";
import displayByAlias from "../../../utils/displayByAlias";
import {aliasStatus} from "./aliasStatus";
import {normalizeTimestemp} from "../../../utils/normalizeTimestemp";
import {numberWithPoints} from "../../../utils/numberWithPoints";
import ParcelList from "./ParcelList";
import Popup from "../../../component/Popup/Popup";
import List from "@mui/material/List";
import DisplayDetailsData from "../../../component/DisplayDetailsData/DisplayDetailsData";

import VisibilityIcon from '@mui/icons-material/Visibility';
import PersonIcon from '@mui/icons-material/Person';
import BadgeIcon from '@mui/icons-material/Badge';
import RoomIcon from '@mui/icons-material/Room';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Filter1Icon from '@mui/icons-material/Filter1';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import EventIcon from '@mui/icons-material/Event';
import PetsIcon from '@mui/icons-material/Pets';
import PercentIcon from '@mui/icons-material/Percent';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import displayNullableData from "../../../utils/displayNullableData";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import {entradaTypeList} from "../../InstallmentPattern/Components/entradaTypeList";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import {tipoParcelaList} from "../../InstallmentPattern/Components/InputsAdd/tipoParcelaList";

export default function PopupOrderDetails({item, setLoadData, label, buttonClass}) {

    const [lgShow, setLgShow] = useState(false);
    const compradores = item?.participantes.filter(par => par.tipoPapel === 'COMPRADOR')
    const vendedores = item?.participantes.filter(par => par.tipoPapel === 'VENDEDOR')
    const modParc = item?.modalidadeParcelamento
    const padraoParc = item?.padraoParcelamento

    const display = label ? label : 'Ver Detalhes'

    const button = label ? label : <>
        {display} <VisibilityIcon fontSize="medium"/>
    </>

    const title = "Detalhes do Lote"

    const iconSpan = <>
        <VisibilityIcon fontSize="large" id="icon-svg"/>
        <h3>{title}</h3>
    </>

    const rowsOrder = [
        ['Número', item.numero],
        ['Data de Criação', normalizeTimestemp(item.dateCreated)],
        ['Valor Original', 'R$ ' + numberWithPoints(item.valorOriginal)],
        ['Desconto', 'R$ ' + numberWithPoints(item.desconto)],
        ['Valor Líquido do Contrato', 'R$ ' + numberWithPoints(item.valorOriginal - item.desconto)],
        ['Valor Líquido das parcelas', 'R$ ' + numberWithPoints(item.valor)],
        ['Status', displayByAlias(item.status, aliasStatus)],
        ['Taxa por atraso', numberWithPoints(item.penaltyFee) + ' %'],
        ['Taxa de juros por mês', numberWithPoints(item.arrearsFee) + ' %'],
    ]

    const tables = [
        {title: 'Lote', rows: rowsOrder},
        // {title: 'Pagador', rows: rowsPayer},
    ]

    function getRandomKey() {
        return Math.random().toString(36).substring(7);
    }

    const handleEndereco = (enderecoObj) => {
        return `${enderecoObj.logradouro}, ${enderecoObj.numero} ${enderecoObj.complemento ? '- ' + enderecoObj.complemento : ''}, ${enderecoObj.bairro}, ${enderecoObj.cidade} - ${enderecoObj.estado}, ${enderecoObj.cep}`;
    }

    return <Popup
        buttonText={button}
        title={title}
        iconSpan={iconSpan}
        lgShow={lgShow} setLgShow={setLgShow}
        buttonClass={buttonClass ? buttonClass : 'btn btn-dark'}
    >
        {tables.map(table => (
            <div key={getRandomKey()} style={{width: '80%', margin: '0 10%'}}>
                <br/>
                <Table>
                    <TableHead style={{backgroundColor: '#ebebeb'}}>
                        <TableRow key={getRandomKey()}>
                            <TableCell colSpan={2}>{table.title}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {table.rows.map((rows) => (
                            <TableRow key={getRandomKey()}>
                                {rows.map((row) => (
                                    <TableCell key={getRandomKey()}>{row}</TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        ))}

        <List
            sx={{width: '80%', bgcolor: 'background.paper', marginInline: '10%'}}
            component="nav"
            aria-labelledby="nested-list-subheader"
            className='pointer-event'
        >

            {/* Dados de modalidade de parcelamento*/}
            {!!modParc?
                <DisplayDetailsData
                    key={modParc.id}
                    title={'Modalidade de Parcelamento'}
                    itens={[
                        {
                            icon: <AttachMoneyIcon/>,
                            display: `Entrada:  ${'R$ ' + numberWithPoints(modParc.entrada)}`
                        },
                        {
                            icon: <FormatListNumberedIcon/>,
                            display: `Quantidade de Parcelas:  ${modParc.quantidadeParcelas}`
                        },
                        {
                            icon: <Filter1Icon/>,
                            display: `Aglutinações:  ${modParc.aglutinacao === 0 ? 'Nenhuma' : modParc.quantidadeAglutinacoes + ' aglutinações de ' + modParc.aglutinacao + ' parcelas'}`
                        },
                        {
                            icon: <EventIcon/>,
                            display: `Dia do Vencimento:  ${modParc.diaDoMes}`
                        },
                        {
                            icon: <EventIcon/>,
                            display: `Primeiro Vencimento:  ${normalizeTimestemp(modParc.dataVencimento)}`
                        }
                    ]}
                /> :
                <DisplayDetailsData
                    key={padraoParc.id}
                    title='Padrão de Parcelamento'
                    itens={[
                        {
                            icon: <DriveFileRenameOutlineIcon key="item1"/>,
                            display: `Nome:  ${padraoParc.nome}`
                        },
                        {
                            icon: <AttachMoneyIcon key="item2"/>,
                            display: `Entrada:  ${entradaTypeList.filter(ent => ent.value === padraoParc.entrada)[0].label}  
                            ${!!(item.entrada)? ` - R$ ${numberWithPoints(item.entrada)}`: ""}`
                        },
                        ...(padraoParc.tiposParcela
                            .sort(parc => parc.index)
                            .map(parc => {

                                return {
                                    icon: <LabelImportantIcon key="item2"/>,
                                    display: `${parc.quantidade} parcela(S):  
                                        ${tipoParcelaList.filter(type => type.value === parc.multiplicidade)[0].labelDetails}   
                                    `
                                }
                            }))
                    ]}
                />
            }

            {/* Vendedores */}
            {
                vendedores.map((vendedor, index) => (
                    <DisplayDetailsData
                        key={vendedor.id}
                        title={'Vendedor ' + (index + 1)}
                        itens={[
                            {
                                icon: <PersonIcon/>,
                                display: `Nome:  ${vendedor?.usuario?.participante?.nome}`
                            },
                            {
                                icon: <AlternateEmailIcon/>,
                                display: `Email:  ${vendedor?.usuario?.email}`
                            },
                            {
                                icon: <LocalPhoneIcon/>,
                                display: `Telefone:  ${displayNullableData(vendedor?.usuario?.participante?.phoneNumber)}`
                            },
                            {
                                icon: <BadgeIcon/>,
                                display: `Documento:  ${vendedor?.usuario?.participante?.documento}`
                            },
                            {
                                icon: <RoomIcon/>,
                                display: `Endereço:  ${handleEndereco(vendedor.usuario?.participante?.endereco)}`
                            },
                            {
                                icon: <PercentIcon/>,
                                display: `Porcentagem:  ${numberWithPoints(vendedor.porcentagem)}%`
                            }
                        ]}
                    />
                ))
            }

            {/* Compradores */}
            {
                compradores.map((comprador, index) => (
                    <DisplayDetailsData
                        key={comprador.id}
                        title={'Comprador ' + (index + 1)}
                        itens={[
                            {
                                icon: <PersonIcon/>,
                                display: `Nome:  ${comprador?.usuario?.participante?.nome}`
                            },
                            {
                                icon: <AlternateEmailIcon/>,
                                display: `Email:  ${comprador?.usuario?.email}`
                            },
                            {
                                icon: <LocalPhoneIcon/>,
                                display: `Telefone:  ${displayNullableData(comprador?.usuario?.participante?.phoneNumber)}`
                            },
                            {
                                icon: <BadgeIcon/>,
                                display: `Documento:  ${comprador?.usuario?.participante?.documento}`
                            },
                            {
                                icon: <RoomIcon/>,
                                display: `Endereço:  ${handleEndereco(comprador.usuario?.participante?.endereco)}`
                            },
                            {
                                icon: <PercentIcon/>,
                                display: `Porcentagem:  ${numberWithPoints(comprador.porcentagem)}%`
                            }
                        ]}
                    />
                ))
            }

            {/* Animais */}
            {
                item.animais.length > 0 &&
                <DisplayDetailsData
                    key={'animal'}
                    title={item.animais.length === 1 ? 'Animal' : 'Animais'}
                    itens={
                        item.animais.map(animal => {
                                return {
                                    icon: <PetsIcon/>,
                                    display: `${animal.nomeAnimal} - ${animal.generoAnimal} - ${animal.quantidade}`
                                }
                            }
                        )
                    }
                />
            }

        </List>

        <br/>
        <div style={{width: '80%', margin: '0 10%'}}>
            <ParcelList orderId={item.id} setListLoadData={setLoadData}/>
        </div>
        <br/>
    </Popup>
}