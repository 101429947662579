import {ApiUrl} from "../../../auth/authMethods";
import {useUser} from "../../../auth/AuthProvider";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {Dropdown} from "react-bootstrap";
import SettingsIcon from "@mui/icons-material/Settings";
import PopupOrderDetails from "../../Order/Components/PopupOrderDetails";
import React from "react";
import DowloadEvidences from "./DowloadEvidences";

export default function WriteOffActions({item, setLoadData}) {

    const detailsLabel = <>
        <VisibilityIcon fontSize="medium"/>
        &nbsp; Detalhes do Lote
    </>

    return <Dropdown drop='down-centered'>
        <Dropdown.Toggle variant="secondary">
            Ações &nbsp;
            <SettingsIcon fontSize="medium"/>
        </Dropdown.Toggle>

        <Dropdown.Menu>
            <PopupOrderDetails
                item={item.pedido} setLoadData={setLoadData}
                label={detailsLabel} buttonClass={'dropdown-item'}/>

            {item.evidenciasBaixa.length > 0 &&
                <DowloadEvidences files={item.evidenciasBaixa}/>
            }
        </Dropdown.Menu>

    </Dropdown>
}