import React, {useState} from "react";
import DisplayDetailsData from "../../../component/DisplayDetailsData/DisplayDetailsData";
import Popup from "../../../component/Popup/Popup";
import List from "@mui/material/List";
import {entradaTypeList} from "./entradaTypeList";
import {tipoParcelaList} from "./InputsAdd/tipoParcelaList";

import VisibilityIcon from "@mui/icons-material/Visibility";
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

export default function InstallmentPatternDetails({item}) {

    const [lgShow, setLgShow] = useState(false);

    const button = <>
        <VisibilityIcon fontSize="medium"/>
        &nbsp; Detalhes
    </>

    const title = "Detalhes do Padrão de Parcelamento"

    const iconSpan = <>
        <VisibilityIcon fontSize="large" id="icon-svg"/>
        {title}
    </>


    return (
        <Popup
            buttonText={button}
            title={title}
            iconSpan={iconSpan}
            lgShow={lgShow} setLgShow={setLgShow}
            buttonClass='dropdown-item'
        >
            <List
                sx={{width: '80%', bgcolor: 'background.paper', marginInline: '10%'}}
                component="nav"
                aria-labelledby="nested-list-subheader"
                className='pointer-event'
            >
                {/* Dados principais */}
                <DisplayDetailsData
                    key="primaryData"
                    title='Dados principais'
                    openDefault
                    itens={[
                        {
                            icon: <DriveFileRenameOutlineIcon key="item1"/>,
                            display: `Nome:  ${item.nome}`
                        },
                        {
                            icon: <AttachMoneyIcon key="item2"/>,
                            display: `Entrada:  ${entradaTypeList.filter(ent => ent.value === item.entrada)[0].label}`
                        },
                    ]}
                />
                {/* Parcelas */}
                <DisplayDetailsData
                    key="primaryData"
                    title='Parcelas'
                    openDefault
                    itens={
                        item.tiposParcela
                            .sort(parc => parc.index)
                            .map(parc => {

                                return {
                                    icon: <LabelImportantIcon key="item2"/>,
                                    display: `${parc.quantidade} parcela(S):  
                                        ${tipoParcelaList.filter(type => type.value === parc.multiplicidade)[0].labelDetails}   
                                    `
                                }
                            })
                    }
                />
            </List>
        </Popup>
    )
}