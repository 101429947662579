
export default function Footer(){

    const style = {
        textAlign: 'center',
        fontSize: '70%'
    }

    return <footer style={style}>
        <hr style={{width: '70%', marginLeft: '15%'}}/>
        <b>&copy; {new Date().getFullYear()} RURAL CREDITO CAPITAL LTDA</b>
        <p>
            Av. Adolfo Augusto Pinto, 110, sala 9 <br/>
            CEP 13303-502, Itu Novo Centro, Itú - SP <br/>
            CNPJ: 15.512.758/0001-86 <br/>
            Contato: (+55) 11 99510-1799
        </p>
    </footer>
}