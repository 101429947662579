
const statusList = [
    {value: 'CRIADO', label: 'Criado'},
    {value: 'ENVIADO', label: 'Enviado'},
    {value: 'PAGO', label: 'Pago'},
    {value: 'INATIVO', label: 'Inativo'}
]

const typeList = [
    {value: 'VENDEDOR', label: 'Vendedor'},
    {value: 'PARCEIRO', label: 'Parceiro'},
    {value: 'FUNDO', label: 'Fundo'},
]

export {typeList, statusList}