import React, {useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import {validateCpf} from "../../../../utils/validateCpf";
import InputsAddress from "../../../Order/Components/InputsAdd/InputsAddress";
import Box from "@mui/material/Box";
import InputsPix from "./InputsPix";
import InputMask from "react-input-mask";
import Typography from "@mui/material/Typography";
import {Switch} from "@mui/material";
import {validateCnpj} from "../../../../utils/validateCnpj";
import InputsParceiroComercial from "./InputsParceiroComercial";

export default function InputsParticipante({
                                               participante, setParticipante,
                                               hideBankDetailsAndPix, setHideBankDetailsAndPix,
                                               taxaParceiroComercial, setTaxaParceiroComercial,
                                               setBlockSubmit
                                           }) {

    const part = participante

    const [nome, setNome] = useState(part?.nome)
    const [documento, setDocumento] = useState(part?.documento ? part?.documento : '')
    const [phoneNumber, setPhoneNumber] = useState(part?.phoneNumber ? part?.phoneNumber : '')

    const [endereco, setEndereco] = useState({})

    const [isCnpj, setIsCnpj] = useState(part?.documento?.length === 18)

    const [dadosPix, setDadosPix] = useState(part?.dadosPix)

    const [dadosBancarios, setDadosBancarios] = useState(part?.dadosBancarios)

    // effect que altera a variavel principal a partir dos states
    useEffect(() => {
        setParticipante(prevData => {
            return {
                ...prevData,
                nome,
                documento,
                dadosPix,
                dadosBancarios,
                endereco,
                phoneNumber,
                taxaParceiroComercial
            }
        })
    }, [nome, documento, dadosPix, dadosBancarios, endereco, phoneNumber, taxaParceiroComercial])

    useEffect(() => {
        if (hideBankDetailsAndPix) {
            setDadosPix({
                tipoChave: "CPF",
                chavePix: ""
            })
            setDadosBancarios({
                banco: "",
                agencia: "",
                numero: "",
                tipoPessoa: "F",
                tipoConta: "CC",
                taxId: "15512758000186",
                nome: ""
            })
        }
    }, [hideBankDetailsAndPix])

    const toggleSwitch = () => {
        setIsCnpj(prevState => !prevState)
        setDocumento('')
    }

    return <>
        <Box mt={2} component={'h5'}>
            Dados do participante
        </Box>
        <hr/>
        <TextField
            label="Nome"
            value={nome}
            inputProps={{
                maxLength: 100,
            }}
            onChange={(e) => setNome(e.target.value)}
            required
            fullWidth
            InputLabelProps={{
                style: {
                    zIndex: 0,
                },
            }}
        />

        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            {/*<div style={{marginRight: '10px'}}>*/}
            <Typography variant="body1">
                <b>Documento: &nbsp;</b>
            </Typography>
            <Typography variant="body1">
                CPF
            </Typography>
            <Switch
                checked={isCnpj}
                onChange={toggleSwitch}
                color="primary"
                inputProps={{'aria-label': 'alternar entre CNPJ e CPF'}}
            />
            <Typography variant="body1">
                CNPJ
            </Typography>
        </div>

        {!isCnpj ?
            <InputMask mask="999.999.999-99" value={documento} maskChar={null}
                       onChange={(e) => setDocumento(e.target.value)}>
                {() =>
                    <TextField
                        required label="CPF" variant="outlined"
                        error={documento.length === 14 && !validateCpf(documento)}
                        helperText={documento.length === 14 && !validateCpf(documento) ? 'CPF inválido' : ''}
                        inputProps={{
                            maxLength: 14,
                            minLength: 14,
                        }}
                        fullWidth
                    />}
            </InputMask> :
            <InputMask mask="99.999.999/9999-99" value={documento} maskChar={null}
                       onChange={(e) => setDocumento(e.target.value)}>
                {() =>
                    <TextField
                        required label="CNPJ" variant="outlined"
                        error={documento.length === 18 && !validateCnpj(documento)}
                        helperText={documento.length === 18 && !validateCnpj(documento) ? 'CNPJ inválido' : ''}
                        inputProps={{
                            maxLength: 18,
                            minLength: 18,
                        }}
                        fullWidth
                    />}
            </InputMask>
        }

        <InputMask mask="(99) 99999-9999" value={phoneNumber} maskChar={null}
                   onChange={(e) => setPhoneNumber(e.target.value)}>
            {() =>
                <TextField
                    label="Telefone"
                    variant="outlined"
                    inputProps={{
                        maxLength: 15,
                        minLength: 15,
                    }}
                    fullWidth
                />}
        </InputMask>

        <InputsAddress setEndereco={setEndereco} endereco={part?.endereco}/>

        {typeof setHideBankDetailsAndPix === 'function' && <>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Typography variant="body1">Adicionar pix:</Typography>
                <Switch
                    checked={!hideBankDetailsAndPix}
                    onChange={() => setHideBankDetailsAndPix(prevState => !prevState)}
                    color="primary"
                    inputProps={{'aria-label': 'alternar para adicionar pix'}}
                />
            </div>
        </>}

        {!hideBankDetailsAndPix && <>
            <Box mt={2} component={'h5'}>
                Chave Pix
            </Box>
            <hr/>
            <InputsPix dadosPix={dadosPix} setDadosPix={setDadosPix} setBlockSubmit={setBlockSubmit}/>
        </>}

        {taxaParceiroComercial &&
            <InputsParceiroComercial
                taxaParceiroComercial={taxaParceiroComercial}
                setTaxaParceiroComercial={setTaxaParceiroComercial}
            />
        }
    </>
}