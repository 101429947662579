import Carousel from 'react-bootstrap/Carousel';
import './InstitutionalHomePage.css'

//icons
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import VisibilityIcon from '@mui/icons-material/Visibility';

export default function InstitutionalHomePage() {

    const carouselStyle = {
        boxShadow: 'rgb(85, 85, 85) 0px 20px 30px -10px',
        backgroundColor: '#ffffff',
        color: 'black',
        fontFamily: 'Georgia, serif',
        borderRadius: '2%',
        minWidth: '35%',
        padding: '0',
        flex: '1',

        width: '100%',
        // maxHeight: '400px',
        zIndex: 1,
    }

    const captionStyle = {
        color: 'black',
        zIndex: 0,
        backgroundColor: 'rgba(255, 255, 255, 255)', // Fundo semi-transparente
        borderRadius: '10%',
        fontsize: '2%',
    }
    const boldStyle = {
        fontWeight: 'bold',
        //fontSize: '1.2rem', // Aumentar o tamanho da fonte
        color: 'black', // Contraste de cor
        backgroundColor: 'rgba(255, 255, 255, 255)', // Fundo semi-transparente
        border: '1px solid transparent',
        lineHeight: '1.5', // Ajustar o espaçamento entre linhas
        padding: '5px', // Adicionar algum espaço ao redor do texto
    };
    const images = [
        {
            imgPath: '/img/home/newImages/img1.jpg',
            imgLabelTittle: <h4>Acesso Simples</h4>,
            imgLabel: <p style={boldStyle}><strong>Facilitamos o acesso a boletos para compradores e oferecemos
                visibilidade total aos vendedores
                sobre boletos e lotes.
            </strong></p>
        },
        {
            imgPath: '/img/home/newImages/img2.jpg',
            imgLabelTittle: <h4>Notificações Inteligentes</h4>,
            imgLabel: <p style={boldStyle}><strong>Alertas preditivos mantêm os compradores informados sobre vencimentos
                iminentes, evitando
                pendências.
            </strong></p>
        },
        {
            imgPath: '/img/home/newImages/img3.jpg',
            imgLabelTittle: <h4>Segurança Total</h4>,
            imgLabel: <p style={boldStyle}><strong>Utilizamos tecnologias avançadas para garantir a proteção de dados e
                promover transações
                confiáveis.
            </strong></p>
        },
        {
            imgPath: '/img/home/newImages/img4.jpg',
            imgLabelTittle: <h4>Suporte Dedicado</h4>,
            imgLabel: <p style={boldStyle}><strong>Nossa equipe está pronta para fornecer suporte personalizado,
                garantindo uma experiência sem
                problemas.
            </strong></p>
        },
    ]

    return <>
        <div className='div-about'>
            <div className='container-about content-about'>
                <div className="text-about">
                    <h2>Quem somos?</h2>
                    <hr/>
                    <p>
                        Seja bem-vindo à Rural Crédito, sua parceira confiável na gestão eficiente de recebíveis no
                        vibrante universo agropecuário. Com uma dedicação incomparável ao setor, nós lideramos a
                        transformação digital no gerenciamento de leilões de animais, concentrando-nos principalmente em
                        equinos e bovinos.
                    </p>
                    <div className="div-img-quem-somos">
                        <img src='/img/home/quem-somos.png' id='img-quem-somos'/>
                    </div>
                </div>
            </div>
            <div className='container-about carousel-about'>

                <Carousel style={carouselStyle} data-bs-theme="dark">
                    {images.map(img =>
                        <Carousel.Item>
                            <img src={img.imgPath} className='carousel-img' alt=''/>
                            <Carousel.Caption style={captionStyle}>
                                {img.imgLabelTittle}
                                {img.imgLabel}
                            </Carousel.Caption>
                        </Carousel.Item>
                    )}
                </Carousel>
            </div>
        </div>


        <div className='div-about'>
            <div className='container-about content-about'>
                <div className="text-about">
                    <h3>Missão <ChecklistRtlIcon fontSize='large'/></h3>
                    <hr/>
                    <p>
                        Facilitar e aprimorar as transações agropecuárias, proporcionando aos nossos usuários uma
                        experiência transparente, segura e eficiente na gestão de recebíveis.
                    </p>
                </div>
            </div>
            <div className='container-about content-about'>
                <div className="text-about">
                    <h3>Visão <VisibilityIcon fontSize='large'/></h3>
                    <hr/>
                    <p>
                        Ser reconhecido como o principal facilitador financeiro no mercado agropecuário, impulsionando o
                        crescimento e a confiança em cada transação.
                    </p>
                </div>
            </div>
        </div>
        <br/>
    </>
}