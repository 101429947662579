import TextField from "@mui/material/TextField";
import React from "react";

export default function InputsFaq({
                                        question, setQuestion,
                                        answer, setAnswer,
                                        errorMessage, setErrorMessage,
                                        errorColumn, setErrorColumn
                                    }) {

    return <>
        <TextField
            label="Pergunta"
            required={true}
            inputProps={{
                maxLength: 200
            }}
            errortext="Campo obrigatório!"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            fullWidth
            autoFocus
        />
        <TextField
            label="Resposta"
            required={true}
            inputProps={{
                maxLength: 200
            }}
            errortext="Campo obrigatório!"
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
            fullWidth
        />
    </>
}