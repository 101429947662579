import React, {useState} from "react";
import EventIcon from '@mui/icons-material/Event';
import PopupEdit from "../../../component/PopupEdit/PopupEdit";
import InputsAntecipacao from "./InputsAntecipacao";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";

export default function PopupEditAntecipacao({item, baseUrl, setLoadData}) {

    const [dataAntecipacao, setDataAntecipacao] = useState(item.dataAntecipacao)
    const [status, setStatus] = useState(item.status)

    const [errorMessage, setErrorMessage] = useState("")
    const [errorColumn, setErrorColumn] = useState("")

    const inputs = <InputsAntecipacao
        status={status} setStatus={setStatus}
        dataAntecipacao={dataAntecipacao} setDataAntecipacao={setDataAntecipacao}
    />

    const icon = <EventIcon fontSize="large" id="icon-svg"/>
    const buttonText = <span>
        <DriveFileRenameOutlineIcon fontSize="medium"/> &nbsp;
        Editar Antecipacao
    </span>

    const itemToState = () => {
        setErrorMessage(false)
        setErrorColumn(false)
        setDataAntecipacao(item.dataAntecipacao)
        setStatus(item.status)
    }

    const returnData = () => {
        return {
            status,
            dataAntecipacao,
        }
    }

    return <PopupEdit
        baseUrl={baseUrl + `/${item.id}`} setLoadData={setLoadData}
        inputs={inputs} title={'Editar Antecipação'} icon={icon}
        returnData={returnData} itemToState={itemToState}
        setErrorMessage={setErrorMessage} setErrorColumn={setErrorColumn}
        buttonText={buttonText} buttonClass='dropdown-item'
    />
}