import PopupDelete from "../../../component/PopupDelete/PopupDelete";
import {statusList, typeList} from "./lists";
import {normalizeTimestemp} from "../../../utils/normalizeTimestemp";
import {numberWithPoints} from "../../../utils/numberWithPoints";
import {ApiUrl} from "../../../auth/authMethods";
import React, {useState} from "react";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export default function PopupDeletePayment({
                                               setLoadData,
                                               item
                                           }) {

    const [error, setError] = useState(false)

    const errorMessage = 'Ocorreu um erro ao deletar o pagamento! ' +
        'Tente novamente mais tarde e caso o erro persista contate um administrador.'

    const buttonText = <>
        <HighlightOffIcon fontSize="medium"/>
        &nbsp; Excluir
    </>

    return (
        <PopupDelete
            baseUrl={ApiUrl + '/pagamentos/delete'} setLoadData={setLoadData}
            title={"Confirmar exclusão de pagamento"} id={item.id}
            buttonText={buttonText}
            buttonClass={'dropdown-item link-danger'}
            setError={setError}
            errorMessage={errorMessage}
        >

            <div className="mt-3 mb-3">
                <b>Data de Agendamento: </b>
                <>{normalizeTimestemp(item.scheduledDate, true)}</>
                <br/>

                <b>Valor: </b>
                <>R$ {numberWithPoints(item.valor)}</>
                <br/>

                <b>Status: </b>
                <>{statusList.filter(stt => stt.value === item.status)[0].label}</>
                <br/>

                <b>Tipo: </b>
                <>{typeList.filter(type => type.value === item.tipoPagamento)[0].label}</>
                <br/>

                <b>Participante: </b>
                <>{item.participante.nome} - {item.participante.documento}</>
                <br/>
            </div>
            <p className="mb-3">Você deseja realmente excluir este registro?</p>
            {error}
        </PopupDelete>
    )
}