import React, {useEffect, useState} from "react";
import DescriptionIcon from "@mui/icons-material/Description";
import Box from "@mui/material/Box";
import InputsOrder from "./InputsOrder";
import {Button, FormControl, FormControlLabel, FormLabel, Modal, Radio, RadioGroup, Typography} from "@mui/material";
import InputsInstallment from "./InputsInstallment";
import DisplayManyUsers from "./DisplayManyUsers";
import {ApiUrl} from "../../../../auth/authMethods";
import DisplayManyAnimals from "./DisplayManyAnimals";
import Alert from "react-bootstrap/Alert";
import {returnDataService} from "../../../../service/PreviewService";
import ParcelTable from "../../../../component/ParcelTable";
import {findDuplicates} from "../../../../utils/findDuplicates";
import PopupAdd from "../../../../component/PopupAdd/PopupAdd";


export default function PopupAddOrderV1({setLoadData}) {

    const usersDefaultComprador = [
        {
            papel: 'COMPRADOR'
        }
    ]

    const usersDefaultVendedor = [
        {
            papel: 'VENDEDOR'
        }
    ]

    const [vendedores, setVendedores] = useState(usersDefaultVendedor);
    const [compradores, setCompradores] = useState(usersDefaultComprador);
    const [parcelas, setParcelas] = useState([]);
    const [qtdParcelas, setQtdParcelas] = useState([]);
    const [pedido, setPedido] = useState({})
    const [pagador, setPagador] = useState({})
    useEffect(() => {
        setPedido({
            ...pedido,
            pagador
        })
    }, [pagador])

    const [modalParcelamento, setModalParcelamento] = useState({})
    const [changeModalParcelamento, setChangeModalParcelamento] = useState(false)

    const [entrada, setEntrada] = useState(0)

    const initialAnimalList = [
        {numeroLote: pedido?.numero}
    ]
    const [animal, setAnimal] = useState(initialAnimalList)
    const [chargeBoleto, setChargeBoleto] = useState(false)

    const [errorMessage, setErrorMessage] = useState("")
    const [errorColumn, setErrorColumn] = useState("")
    const [blockSubmit, setBlockSubmit] = useState(false)

    const [error, setError] = useState(false)

    const icon = <DescriptionIcon fontSize="large" id="icon-svg"/>

    const [openPreview, setOpenPreview] = useState(false);
    const handleClosePreview = () => setOpenPreview(false);

    const clearForm = () => {
        setErrorMessage(false)
        setErrorColumn(false)
        setVendedores(usersDefaultVendedor)
        setCompradores(usersDefaultComprador)
        setPedido({})
        setEntrada(0)
        setChargeBoleto(true)
        setPagador({})
        setModalParcelamento({})
        setAnimal(initialAnimalList)
    }

    const returnData = () => {
        const users = [...compradores, ...vendedores]
        return {
            users,
            pedido,
            modalParcelamento,
            animal,
            chargeBoleto
        }
    }

    const inputs = <>
        <Box mt={2} component={'h5'}>
            Dados do pedido
        </Box>
        <hr/>
        <InputsOrder
            setPedido={setPedido}
            setModalParcelamento={setModalParcelamento}
            entrada={entrada} setEntrada={setEntrada}
            setChangeModalParcelamento={setChangeModalParcelamento}
        />

        <FormControl component="fieldset" fullWidth required>
            <FormLabel component="legend">Cobrar valor do boleto do comprador? (R$1.60)</FormLabel>
            <Box display="flex" justifyContent="center">
                <RadioGroup
                    aria-label="chargeBoleto"
                    name="chargeBoleto"
                    value={chargeBoleto}
                    onChange={(e) => setChargeBoleto(e.target.value)}
                    row
                >
                    <FormControlLabel value={false} control={<Radio/>} label="Não"/>
                    <FormControlLabel value={true} control={<Radio/>} label="Sim"/>
                </RadioGroup>
            </Box>
        </FormControl>

        <InputsInstallment
            modalParcelamento={modalParcelamento}
            changeModalParcelamento={changeModalParcelamento}
            setChangeModalParcelamento={setChangeModalParcelamento}
            entrada={entrada} setEntrada={setEntrada}
            setModalParcelamento={setModalParcelamento}
        />

        <Box mt={2} component={'h5'}>
            Vendedores
        </Box>
        <hr/>
        <DisplayManyUsers
            baseUrl={ApiUrl} obj={vendedores} setObj={setVendedores}
            name={'Vendedor'} type={'VENDEDOR'} setBlockSubmit={setBlockSubmit}
        />

        <Box mt={2} component={'h5'}>
            Compradores
        </Box>
        <hr/>
        <DisplayManyUsers
            baseUrl={ApiUrl} obj={compradores} setObj={setCompradores}
            name={'Comprador'} type={'COMPRADOR'} setBlockSubmit={setBlockSubmit}
        />

        <Box mt={2} component={'h5'}>
            Animais
        </Box>
        <hr/>
        <DisplayManyAnimals
            baseUrl={ApiUrl} obj={animal} setObj={setAnimal}
            name={'Animal'} numeroLote={pedido?.numero}
        />

        {error &&
            <Alert key='danger' variant='danger'>
                {error}
            </Alert>
        }
    </>

    const handleOpenPreview = () => {
        const data = returnData();
        const previewData = returnDataService(data);
        const previewParcelas = previewData.parcelas || [];
        setParcelas(previewParcelas);
        setQtdParcelas(data.pedido.qtdParcelas)
        setOpenPreview(true);
    };


    const previewButton = (
        <Button style={{ marginLeft: '20px' }} variant={"outlined"} onClick={handleOpenPreview}>
            Preview
        </Button>
    )

    const previewModal = (
        <Modal
            open={openPreview}
            onClose={handleClosePreview}
            className="modal-xl d-flex align-items-center justify-content-center"
            aria-labelledby="example-modal-sizes-title-lg"
            backdrop="static"
            centered
            style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
        >
            <Box
                width="80%"
                bgcolor="background.paper"
                borderRadius={8}
                boxShadow={24}
                p={4}
            >
                <Typography variant="h6" component="h2" gutterBottom>
                    Preview da Geração de Parcelas
                </Typography>
                <Typography variant="body1" component="pre" style={{ maxHeight: '60vh', overflow: 'auto' }}>
                    <ParcelTable parcelas={parcelas} qtdParcelas={qtdParcelas} />
                </Typography>
                <Button color={"success"} variant={"contained"} onClick={handleClosePreview} style={{ marginTop: '20px' }}>Voltar para Edição</Button>
            </Box>
        </Modal>
    );

    // returns true if data is invalid and false if is valid
    const validateData = () => {

        // validando aglutinações
        if (modalParcelamento.aglutinacoes * modalParcelamento.quantidadeAglutinacoes > pedido?.qtdParcelas) {
            setError(`
                        A quantidade de aglutinações ultrapassa a quantidade de parcelas.
                        Para obedecer as regras passadas de aglutinação, seria preciso ao menos
                        ${modalParcelamento.aglutinacoes * modalParcelamento.quantidadeAglutinacoes}
                        parcelas e não ${pedido.qtdParcelas}.
            `)
            return false
        }

        // validando compradores e/ou vendedores duplicados
        let duplicates = findDuplicates(compradores.map((user) => user.email))
        if (duplicates.length > 0) {
            const firstDuplicateUser = compradores.find(user => user.email === duplicates[0])
            setError(`
                        O email '${firstDuplicateUser.email}'
                        aparece mais de uma vez como comprador.
            `)
            return false
        }
        duplicates = findDuplicates(vendedores.map((user) => user.email))
        if (duplicates.length > 0) {
            const firstDuplicateUser = vendedores.find(user => user.email === duplicates[0])
            setError(`
                        O email '${firstDuplicateUser.email}'
                        aparece mais de uma vez como vendedor.
            `)
            return false
        }

        // validando possiveis ids de usuários em ambas as listas
        const commonIds =
            compradores.map(user => user.email)
                .filter(id =>
                    vendedores.map(user => user.email).includes(id)
                )
        if (commonIds.length > 0) {
            const firstCommonUser = compradores.find(user => user.email === commonIds[0])
            setError(`
                        O email '${firstCommonUser.email}'
                        não pode aparecer como comprador e vendedor no mesmo pedido!
            `)
            return false
        }

        // validando porcentagens
        const porcentagemCompradores = compradores
            .reduce((sum, obj) => parseFloat(sum) + parseFloat(obj.porcentagem), 0);
        if (porcentagemCompradores !== 100) {
            setError(`
                    A soma das porcentagens dos compradores deve ser 
                    100% e está ${porcentagemCompradores}%!
            `)
            return false
        }
        const porcentagemVendedores = vendedores
            .reduce((sum, obj) => parseFloat(sum) + parseFloat(obj.porcentagem), 0);
        if (porcentagemVendedores !== 100) {
            setError(`
                    A soma das porcentagens dos vendedores deve ser 
                    100% e está ${porcentagemVendedores}%!
            `)
            return false
        }

        if (pedido.arrearsFee > 10 || pedido.penaltyFee > 10) {
            setError(`
                    A taxa ${pedido.penaltyFee > 10? 'por atraso':'de juros por dia'}
                    não pode ser maior que 10%!
            `)
            return false
        }

        setError(false)
        setBlockSubmit(false)
        return true
    }

    return (
        <>
            <PopupAdd
                baseUrl={ApiUrl + '/multi-entity/register'} setLoadData={setLoadData}
                inputs={inputs} title={'Adicionar Lote'} icon={icon}
                clearForm={clearForm} returnData={returnData}
                blockSubmit={blockSubmit}
                setErrorMessage={setErrorMessage} setErrorColumn={setErrorColumn}
                validateData={validateData}
                previewButton={previewButton}
                buttonClass={'btn btn-secondary'}
                openPopupButton={'Modelo Antigo'}
            />
            {previewModal}
        </>
    )
}
