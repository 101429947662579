export const normalizeTimestemp = (timestamp, withoutTime) => {

    if (timestamp.length === 10){
        const dataSplit = timestamp.split('-')
        return `${dataSplit[2]}/${dataSplit[1]}/${dataSplit[0]}`
    }

    let data = new Date(timestamp)

    let ano = data.getFullYear();
    let mes = data.getMonth() + 1;
    let dia = data.getDate();
    let hora = data.getHours();
    let minuto = data.getMinutes();
    let segundo = data.getSeconds();

    // Formatar os componentes como strings de dois dígitos
    if (mes < 10) {
        mes = "0" + mes;
    }
    if (dia < 10) {
        dia = "0" + dia;
    }
    if (hora < 10) {
        hora = "0" + hora;
    }
    if (minuto < 10) {
        minuto = "0" + minuto;
    }
    if (segundo < 10) {
        segundo = "0" + segundo;
    }

    // Construir a data normalizada no formato desejado (exemplo: DD/MM/AAAA HH:MM:SS)
    if (hora + ":" + minuto + ":" + segundo === '00:00:00' || withoutTime)
        return dia + "/" + mes + "/" + ano;
    else
        return dia + "/" + mes + "/" + ano + " " + hora + ":" + minuto + ":" + segundo;
};
