import Select from "react-select";
import React, {useEffect, useState} from "react";
import {tipoParcelaList} from "./tipoParcelaList";
import TextField from "@mui/material/TextField";
import applyRegex from "../../../../utils/applyRegex";
import {InputLabelProps} from "../../../../utils/InputLabelProps";

export default function InputsInstallmentType({
                                                  set, index
                                              }) {

    const [multiplicidade, setMultiplicidade] = useState('')
    const [quantidade, setQuantidade] = useState('')

    useEffect(() => {
        set({
            index,
            multiplicidade,
            quantidade,
        })
    }, [multiplicidade, quantidade])

    const handleChangeNumber = (set, value) => {
        const valueToParse = applyRegex(value.replace('e', ''), /\d+/)
        valueToParse.length > 0 ?
            set(parseInt(valueToParse)) : set('')
    };

    return <div className='d-flex'>
        <Select
            className="basic-single"
            classNamePrefix="select"
            id="form-select-50"
            required={true}
            defaultValue={multiplicidade}
            isDisabled={false}
            isLoading={false}
            isClearable={true}
            isRtl={false}
            isSearchable={true}
            options={tipoParcelaList}
            onChange={(e) => setMultiplicidade(e?.value)}
            placeholder={'Tipo de Parcela *'}
        />
        <TextField
            label="Quantidade"
            className='d-flex mt-2 mb-1 '
            type="text"
            style={{width: '48%'}}
            value={quantidade}
            inputProps={{
                minLength: 0,
                maxLength: 3,
            }}
            onChange={(e) => handleChangeNumber(setQuantidade, e.target.value)}
            InputLabelProps={InputLabelProps}
            required
        />
    </div>
}