import {useState} from "react";
import TableList from "../../../component/TableList/TableList";
import {normalizeTimestemp} from "../../../utils/normalizeTimestemp";
import {numberWithPoints} from "../../../utils/numberWithPoints";
import FilterOrder from "./FilterOrder";
import OrderActions from "./OrderActions";
import {useUser} from "../../../auth/AuthProvider";

export default function OrderList({
                                      baseUrl, loadData,
                                      setLoadData, size, page,
                                      setTotalPages, setTotalElements,
                                      sort, setSort, setPage,
                                      filter, setFilter
                                  }) {

    const [data, setData] = useState([])
    const [error, setError] = useState(false)

    const {user} = useUser()

    const titles = [
        'N° Lote',
        'Evento',
        'Valor',
        'Animal(is)',
        'Data do Evento',
        'Data de Cadastro'
    ]

    return (
        <>
            <FilterOrder filter={filter} setFilter={setFilter} setLoadData={setLoadData} setPage={setPage}/>
            <TableList
                titles={titles} baseUrl={baseUrl}
                page={page} sort={sort} size={size}
                loadData={loadData}
                setTotalPages={setTotalPages} setTotalElements={setTotalElements}
                setData={setData} setLoadData={setLoadData} filter={filter}
                error={error} setError={setError} isFilterByUser={!user.isAdmin}
            >
                {data.map((item) =>
                    <tr key={item.id}>
                        <td>{item.numero}</td>
                        <td>{item.evento.nomeEvento}</td>
                        <td>R$ {numberWithPoints(item.valorOriginal)}</td>
                        <td style={{wordWrap: 'break-word', maxWidth: '450px'}}>
                            {item.animais.map((animal) => animal.nomeAnimal).join(", ")}
                        </td>
                        <td>{normalizeTimestemp(item.evento.dataInicio)}</td>
                        <td>{normalizeTimestemp(item.dateCreated)}</td>
                        <td><OrderActions item={item} setLoadData={setLoadData}/></td>
                    </tr>
                )}
            </TableList>
        </>
    )
}