import React from 'react';
import './WhatsAppButton.css';
import WhatsLogo from './images/whatsapp.png'

function WhatsAppButton() {
    return (
        <a
            href="https://wa.me/5511995101799"
            className="whatsapp-button"
            target="_blank"
            rel="noopener noreferrer"
        >
            <img src={WhatsLogo} alt="WhatsApp"/>
        </a>
    );
}

export default WhatsAppButton;
