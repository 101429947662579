import {useState} from "react";
import PaginationControl from "../../component/Pagination/PaginationControl";
import ContentContainer from "../../component/ContentContainer";
import {ApiUrl} from "../../auth/authMethods";
import InstallmentPatternList from "./Components/InstallmentPatternList";

export default function InstallmentPattern() {

    const baseUrl = `${ApiUrl}/padrao-parcelamento`
    const [loadData, setLoadData] = useState(true)

    const [size, setSize] = useState(10)
    const [page, setPage] = useState(0)
    const [sort, setSort] = useState("")

    const [totalPages, setTotalPages] = useState(0)
    const [totalElements, setTotalElements] = useState(0)

    return <ContentContainer width={'xl'}>
        <h1>Lista Padrões de Parcelamento </h1>
        <InstallmentPatternList
            baseUrl={baseUrl}
            loadData={loadData}
            setLoadData={setLoadData}
            page={page}
            setPage={setPage}
            size={size}
            setSize={setSize}
            totalPages={totalPages}
            setTotalPages={setTotalPages}
            totalElements={totalElements}
            setTotalElements={setTotalElements}
            sort={sort} setSort={setSort}
        />
        <PaginationControl
            totalElements={totalElements}
            page={page}
            setPage={setPage}
            size={size}
            setSize={setSize}
            setLoadData={setLoadData}
        />
    </ContentContainer>
}