import React, { useState } from 'react';
import { Container, FormControl, InputLabel, Select, MenuItem, Grid, Card, CardContent, CardActions, Button, Typography, Box } from '@mui/material';
import ContentContainer from "../../component/ContentContainer";

const Report = () => {
    const [selectedFormat, setSelectedFormat] = useState('pdf');
    const reports = [
        { value: 'sales', viewValue: 'Relatório Leilões', description: 'Relatório com os totais e status dos leilões', color: '#ff6666' },
        { value: 'inventory', viewValue: 'Relatório Vendas', description: 'Todas as vendas', color: '#66b3ff' },
        { value: 'customers', viewValue: 'Relatório de Clientes', description: 'Descrição do relatório de clientes', color: '#99ff99' },
        { value: 'finance', viewValue: 'Relatório de Pagamentos', description: 'Relatório de Pagamentos detalhado', color: '#ffcc66' },
        { value: 'performance', viewValue: 'Relatório de Taxas', description: 'Taxas detalhadas', color: '#c266ff' },
        { value: 'marketing', viewValue: 'Relatório de Antecipações', description: 'Relatório de campanhas de marketing', color: 'black' }
    ];
    const formats = [
        { value: 'pdf', viewValue: 'PDF' },
        { value: 'excel', viewValue: 'Excel' },
        { value: 'csv', viewValue: 'CSV' }
    ];

    const handleFormatChange = (event) => {
        setSelectedFormat(event.target.value);
    };

    const generateReport = (reportType) => {
        console.log('Generating report:', {
            reportType: reportType,
            format: selectedFormat
        });
    };

    return (
        <ContentContainer width={'xl'}>
            <Typography variant="h4" gutterBottom>Gerar Relatórios</Typography>
            <FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel>Formato do Relatório</InputLabel>
                <Select value={selectedFormat} onChange={handleFormatChange} label="Formato do Relatório">
                    {formats.map((format) => (
                        <MenuItem key={format.value} value={format.value}>
                            {format.viewValue}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <Grid container spacing={3} mt={2}>
                {reports.map((report, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card>
                            <Box style={{ backgroundColor: report.color, padding: '8px' }}>
                            </Box>
                            <Box style={{ padding: '8px', paddingLeft: '14px', paddingBottom: '2px' }}>
                                <Typography variant="h6" component="div">
                                    {report.viewValue}
                                </Typography>
                            </Box>
                            <CardContent>
                                <Typography variant="body2" color="text.secondary">{report.description}</Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="small" color="primary" onClick={() => generateReport(report.value)}>Gerar Relatório</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </ContentContainer>
    );
};

export default Report;
