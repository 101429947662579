import {
    Box,
    Card,
    CardContent,
    Grid,
    Typography,
    Chip,
    Button,
    TableCell,
    TableRow,
    TableBody,
    TableHead,
    TableContainer,
    Checkbox
} from "@mui/material";
import React, {useEffect, useState} from "react";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Table from "@mui/material/Table";
import axios from "axios";
import {ApiUrl} from "../../../../auth/authMethods";
import {numberWithPoints} from "../../../../utils/numberWithPoints";
import {normalizeTimestemp} from "../../../../utils/normalizeTimestemp";

const color = "warning";
const percentage = 7;

export default function AntecipacaoStep({ handleAntecipar, handleBack, setValue }) {
    const [rows, setRows] = useState([]);

    useEffect(() => {
        axios.get(`${ApiUrl}/parcelas/list-antecipacao?page=0&size=1000`)
            .then((response) => {
                setRows(response.data.content);
            })
            .catch((error) => {
                console.error("Erro ao carregar os dados da API:", error);
            });
    }, []);

    const [selectedParcelas, setSelectedParcelas] = useState(rows.map((row) => row.id));

    useEffect(() => {
        setValue(getTotalAntecipar)
    }, [selectedParcelas]);

    const handleCheckboxChange = (id) => {
        setSelectedParcelas((prevState) =>
            prevState.includes(id)
                ? prevState.filter((parcelaId) => parcelaId !== id)
                : [...prevState, id]
        );
    };

    const handleAdd = () => {
        const nextParcela = rows.find((row) => !selectedParcelas.includes(row.id));
        if (nextParcela) {
            setSelectedParcelas((prevState) => [...prevState, nextParcela.id]);
        }
    };

    const handleRemove = () => {
        setSelectedParcelas((prevState) =>
            prevState.filter((parcelaId) => parcelaId !== prevState[prevState.length - 1])
        );
    };

    const handleCheckAll = () => {
        const allSelected = selectedParcelas.length === rows.length;
        setSelectedParcelas(allSelected ? [] : rows.map((row) => row.id));
    };

    const getTotalAntecipar = () => {
        return selectedParcelas.reduce(
            (total, parcelaId) => total + rows.find((row) => row.id === parcelaId).valor,
            0
        );
    };

    return (
        <Box sx={{ backgroundColor: 'white', padding: 2, display: 'flex', justifyContent: 'center' }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Card sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <CardContent>
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item ml={"auto"}>
                                    <Typography variant="h6" color="textSecondary">
                                        Total para antecipar
                                    </Typography>
                                    <Grid container alignItems="center" spacing={1}>
                                        <Grid item>
                                            <Typography variant="h3" color="inherit">
                                                R${numberWithPoints(Number(getTotalAntecipar()).toFixed(2))}
                                            </Typography>
                                        </Grid>
                                        {percentage && (
                                            <Grid item>
                                                <Chip
                                                    variant="combined"
                                                    color={color}
                                                    label={`${percentage}%`}
                                                    size="small"
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid item  >
                                    <Grid container direction="column" alignItems="center" spacing={1}>
                                        <Grid item>
                                            <IconButton size="large" onClick={handleAdd}>
                                                <AddIcon />
                                            </IconButton>
                                        </Grid>
                                        <Grid item>
                                            <IconButton size="large" onClick={handleRemove}>
                                                <RemoveIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Box sx={{ pt: 1 }}>
                                <Typography variant="caption" color="textSecondary">
                                    Taxa média de antecipação com{' '}
                                    <Typography component="span" variant="caption" sx={{ color: `${color || 'primary'}.main` }}>
                                        7%
                                    </Typography>{' '}
                                    no último mês
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card sx={{ width: '100%', margin: "auto", padding: 2, height: 176, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Button
                            sx={{ mb: 1, width: "100%", height: 48 }}
                            color="success"
                            variant="contained"
                            onClick={handleAntecipar}
                            disabled={getTotalAntecipar() <= 0}
                        >
                            Antecipar
                        </Button>
                        <Button
                            sx={{ mt: 1, width: "100%", height: 48 }}
                            color="warning"
                            variant="contained"
                            onClick={handleBack}
                        >
                            Voltar para Apresentação
                        </Button>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <CardContent  sx={{ width: '100%' }}>
                            <Typography variant="h6" gutterBottom sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                Parcelas que serão antecipadas
                                <Box sx={{ marginLeft: 1 }}>
                                    <Button
                                        variant={"contained"}
                                        color={"success"}
                                        onClick={handleCheckAll}
                                    >
                                        {selectedParcelas.length === rows.length
                                            ? "Desmarcar Todas"
                                            : "Marcar Todas"
                                        }
                                    </Button>
                                </Box>
                            </Typography>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center"><b>Evento</b></TableCell>
                                            <TableCell align="center"><b>Lote</b></TableCell>
                                            <TableCell align="center"><b>Parcela</b></TableCell>
                                            <TableCell align="center"><b>Valor</b></TableCell>
                                            <TableCell align="center"><b>Data de Vencimento</b></TableCell>
                                            <TableCell align="center"><b>Selecionar</b></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell align="center">{row.evento}</TableCell>
                                                <TableCell align="center">{row.lote}</TableCell>
                                                <TableCell align="center">{row.parcela}</TableCell>
                                                <TableCell align="center">{row.valor}</TableCell>
                                                <TableCell align="center">{normalizeTimestemp(row.vencimento)}</TableCell>
                                                <TableCell align="center">
                                                    <Checkbox
                                                        checked={selectedParcelas.includes(row.id)}
                                                        onChange={() => handleCheckboxChange(row.id)}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
}
