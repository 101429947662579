import React, {useState} from "react";
import Box from '@mui/material/Box';
import {Button} from "react-bootstrap";
import DefaultSuccessMessage from "../../../component/DefaultSuccessMessage/DefaultSuccessMessage";
import DefaultProblemMessage from "../../../component/DefaultProblemMessage/DefaultProblemMessage";
import WriteOffForm from "./WriteOffForm";
import Popup from "../../../component/Popup/Popup";
import CancelIcon from '@mui/icons-material/Cancel';

const style = {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    width: '30vw',
    textAlign: 'center'

};

export default function PopupWriteOff({item, setLoadData}) {

    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [needToReload, setNeedToReload] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        if (needToReload)
            setLoadData(true)
        setError(false)
        setOpen(false);
    };

    return (
        <>
            {/*<Button onClick={handleOpen} variant="outline-success">Cancelar <CreditScoreIcon/></Button>*/}
            <Popup
                lgShow={open}
                setLgShow={setOpen}
                modalClass='modal-lg'
                buttonText={<>Cancelar <CancelIcon/></>}
                buttonClass='btn btn-outline-danger'
                title="Confirmar cancelamento da parcela"
                highlighted
            >
                <Box>
                    {
                        !needToReload ?
                            !error ?
                                <WriteOffForm
                                    item={item} setNeedToReload={setNeedToReload}
                                    isLoading={isLoading} setIsLoading={setIsLoading}
                                    setError={setError} handleClose={handleClose}
                                    multiple={true}
                                /> :
                                <DefaultProblemMessage>
                                    <Button onClick={handleClose} variant='outline-secondary'>Fechar</Button>
                                </DefaultProblemMessage>
                            :
                            <DefaultSuccessMessage message='Parcela baixada com sucesso!'>
                                <Button onClick={handleClose} variant='outline-secondary'>Fechar</Button>
                            </DefaultSuccessMessage>
                    }
                </Box>
            </Popup>
        </>
    )
        ;
}