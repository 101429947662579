import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";

export default function ApresentacaoStep({ handleStart }) {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
            }}
        >
            <img
                src={"/img/home/newImages/img2.jpg"}
                alt={"Rural Antecipação"}
                style={{ width: "100%", height: "500px", objectFit: "cover",
                    objectPosition: "center", marginBottom: "2rem"}}
            />
            <Typography variant="h4">Bem-vindo à área de antecipação</Typography>
            <Typography variant="body1" sx={{ mt: 2, width: 1000 }}>
                Aqui você pode antecipar parte do valor disponível em sua conta antes da data de pagamento. Basta selecionar o valor desejado e confirmar a operação para receber o montante antecipado em sua conta bancária. <b>Confira as vantagens:</b>
            </Typography>
            <Grid container spacing={2} sx={{ width: "100%", mt: 4, justifyContent: "center" }}>
                <Grid item xs={12} sm={3}>
                    <Card sx={{ backgroundColor: "#fff", border: "1px solid rgba(0, 0, 0, 0.1)", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}>
                        <Grid container justifyContent="center">
                            <Grid item xs={12}>
                                <img
                                    src={"/img/home/newImages/img6.jpg"}
                                    alt={"Descrição da Imagem"}
                                    style={{ width: "100%", height: "200px", objectFit: "cover" }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CardContent>
                                    <Typography variant="h5" gutterBottom>
                                        Fluxo de caixa otimizado
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Antecipar as parcelas pendentes permite aos vendedores receber o dinheiro mais rapidamente, contribuindo para otimizar o fluxo de caixa e a financiar prontamente novos investimentos ou despesas operacionais.
                                    </Typography>
                                </CardContent>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Card sx={{ backgroundColor: "#fff", border: "1px solid rgba(0, 0, 0, 0.1)", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}>
                        <Grid container justifyContent="center">
                            <Grid item xs={12}>
                                <img
                                    src={"/img/home/newImages/img7.jpg"}
                                    alt={"Descrição da Imagem"}
                                    style={{ width: "100%", height: "200px", objectFit: "cover" }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CardContent>
                                    <Typography variant="h5" gutterBottom>
                                        Redução de riscos financeiros
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Ao antecipar todas as parcelas pendentes de uma vez, os vendedores podem reduzir os riscos financeiros associados ao recebimento futuro, protegendo-se de possíveis inadimplências ou flutuações no mercado.
                                    </Typography>
                                </CardContent>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Card sx={{ backgroundColor: "#fff", border: "1px solid rgba(0, 0, 0, 0.1)", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}>
                        <Grid container justifyContent="center">
                            <Grid item xs={12}>
                                <img
                                    src={"/img/home/newImages/img8.jpg"}
                                    alt={"Descrição da Imagem"}
                                    style={{ width: "100%", height: "200px", objectFit: "cover" }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CardContent>
                                    <Typography variant="h5" gutterBottom>
                                        Flexibilidade e controle
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Oferecer a opção de antecipação das parcelas dá aos vendedores maior flexibilidade e controle sobre suas finanças, permitindo que eles decidam quando e quanto desejam antecipar, de acordo com suas necessidades e objetivos financeiros.
                                    </Typography>
                                </CardContent>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
            <Button sx={{ mb: 6, mt: 6, width: 460, height: 50 }} color={"success"} variant={"contained"} onClick={handleStart}>
                Começar
            </Button>
        </Box>
    );
}
