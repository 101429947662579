import React, {useState} from "react";
import ParcelList from "./ParcelList";
import Popup from "../../../component/Popup/Popup";

import VisibilityIcon from '@mui/icons-material/Visibility';

export default function PopupBoletos({item, setLoadData, buttonClass}) {

    const [lgShow, setLgShow] = useState(false);

    const button = <>
        <VisibilityIcon fontSize="medium"/>
        &nbsp; Ver Boletos
    </>


    const title = "Ver Boletos"

    const iconSpan = <>
        <VisibilityIcon fontSize="large" id="icon-svg"/>
        <h3>{title}</h3>
    </>

    return <Popup
        buttonText={button}
        title={title}
        iconSpan={iconSpan}
        lgShow={lgShow} setLgShow={setLgShow}
        buttonClass={buttonClass ? buttonClass : 'btn btn-dark'}
    >
        <div style={{width: '80%', margin: '0 10%'}}>
            <hr/>
            <ParcelList orderId={item.id} setListLoadData={setLoadData}/>
        </div>
        <br/>
    </Popup>
}