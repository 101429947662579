import TextField from "@mui/material/TextField";
import Select from "react-select";
import React, {useEffect, useState} from "react";
import {genderList, quantityList} from "../../../Animal/Components/lists";

export default function InputsAnimal({
                                         numeroLote, set
                                     }) {

    const [nomeAnimal, setNomeAnimal] = useState('')
    const [generoAnimal, setGeneroAnimal] = useState('')
    const [quantidade, setQuantidade] = useState('')
    const [observacao, setObservacao] = useState('')
    const [documento, setDocumento] = useState('')

    useEffect(() => {
        set({
            numeroLote,
            nomeAnimal,
            generoAnimal,
            quantidade,
            observacao,
            documento,
            status: 'Ativo'
        })
    }, [numeroLote, nomeAnimal, generoAnimal, quantidade, observacao, documento])

    return <>
        <TextField
            label="Nome do Animal"
            required={true}
            inputProps={{
                maxLength: 255
            }}
            errortext="Campo obrigatório!"
            value={nomeAnimal}
            onChange={(e) => setNomeAnimal(e.target.value)}
            fullWidth
            InputLabelProps={{
                style: {
                    zIndex: 0,
                },
            }}
        />
        <TextField
            label="Documento"
            inputProps={{
                maxLength: 255
            }}
            value={documento}
            onChange={(e) => setDocumento(e.target.value)}
            fullWidth
            InputLabelProps={{
                style: {
                    zIndex: 0,
                },
            }}
        />
        <TextField
            label="Observação"
            inputProps={{
                maxLength: 255
            }}
            value={observacao}
            onChange={(e) => setObservacao(e.target.value)}
            fullWidth
            InputLabelProps={{
                style: {
                    zIndex: 0,
                },
            }}
        />
        <Select
            className="basic-single mb-4"
            classNamePrefix="select"
            id="form_select"
            required={true}
            defaultValue={generoAnimal}
            isDisabled={false}
            isLoading={false}
            isClearable={false}
            isRtl={false}
            isSearchable={true}
            options={genderList}
            onChange={(e) => setGeneroAnimal(e.value)}
            placeholder={'Gênero *'}
        />
        <Select
            className="basic-single mb-4"
            classNamePrefix="select"
            id="form_select"
            required={true}
            defaultValue={quantidade}
            isDisabled={false}
            isLoading={false}
            isClearable={false}
            isRtl={false}
            isSearchable={true}
            options={quantityList}
            onChange={(e) => setQuantidade(e.value)}
            placeholder={'Quantidade *'}
        />
    </>
}