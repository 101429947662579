import React, {useState} from "react";
import TableList from "../../../component/TableList/TableList";
import FilterFormUsers from "./FilterFormUsers";
import UserActions from "./UserActions";
import {aliasRoles} from "./aliasRoles";


const ListUser = ({
                      baseUrl, loadData,
                      setLoadData, size, page,
                      setTotalPages, setTotalElements,
                      sort, setSort, updateCode, setPage
                  }) => {

    const [data, setData] = useState([])
    const [error, setError] = useState(false)
    const [filter, setFilter] = useState({})

    const titles = [
        "Nome do usuário",
        "Email do usuário",
        "Tipo de usuário"
    ]

    return (
        <>
            <FilterFormUsers filter={filter} setFilter={setFilter} setLoadData={setLoadData} setPage={setPage}/>
            <TableList
                titles={titles} baseUrl={baseUrl + '/user/list'}
                page={page} sort={sort} size={size}
                loadData={loadData}
                setTotalPages={setTotalPages} setTotalElements={setTotalElements}
                setData={setData} setLoadData={setLoadData} filter={filter}
                error={error} setError={setError}
            >
                {data.map((item) =>
                    <tr key={item.id}>
                        <td>{item.username}</td>
                        <td>{item.email}</td>
                        <td>{aliasRoles.find(role => role.received === item.roles[0].name).display}</td>
                        <td>
                            <UserActions item={item} setLoadData={setLoadData} baseUrl={baseUrl}/>
                        </td>
                    </tr>
                )}
            </TableList>
        </>
    )
}
export default ListUser