import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import applyRegex from "../../../../utils/applyRegex";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { handleChangeData } from "../../../../utils/handleChangeData";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import Box from "@mui/material/Box";

export default function InputsInstallment({
                                              changeModalParcelamento, setChangeModalParcelamento,
                                              modalParcelamento, setModalParcelamento,
                                              entrada, setEntrada,
                                              isEvent
                                          }) {

    const [diaDoMes, setDiaDoMes] = useState(modalParcelamento?.diaDoMes ? modalParcelamento.diaDoMes : (new Date()).getDate());
    const [aglutinacoes, setAglutinacoes] = useState(modalParcelamento?.aglutinacao ? modalParcelamento.aglutinacao : 0);
    const [quantidadeAglutinacoes, setQuantidadeAglutinacoes] = useState(modalParcelamento?.quantidadeAglutinacoes ? modalParcelamento.quantidadeAglutinacoes : 0);
    const [dataVencimento, setDataVencimento] = useState(modalParcelamento?.dataVencimento ? modalParcelamento.dataVencimento.split('T')[0] : false);

    const [juncaoDeParcelasAglutinadas, setJuncaoDeParcelasAglutinadas] = useState(modalParcelamento?.juncaoDeParcelasAglutinadas ? modalParcelamento?.juncaoDeParcelasAglutinadas : true);

    const [periodoParcela, setPeriodoParcela] = useState(modalParcelamento?.periodoParcela ? modalParcelamento?.periodoParcela : 'MENSAL');

    useEffect(() => {
        setModalParcelamento({
            diaDoMes: parseInt(diaDoMes),
            aglutinacoes: parseInt(aglutinacoes),
            quantidadeAglutinacoes: parseInt(quantidadeAglutinacoes),
            dataVencimento: dataVencimento + 'T00:00:00',
            entrada,
            juncaoDeParcelasAglutinadas,
            periodoParcela
        });
    }, [diaDoMes, aglutinacoes, quantidadeAglutinacoes, dataVencimento, entrada, juncaoDeParcelasAglutinadas, periodoParcela]);

    useEffect(() => {
        if (changeModalParcelamento) {
            setDiaDoMes(modalParcelamento?.diaDoMes);
            setAglutinacoes(modalParcelamento?.aglutinacao);
            setQuantidadeAglutinacoes(modalParcelamento?.quantidadeAglutinacoes);
            console.log(modalParcelamento?.dataVencimento.split('T')[0]);
            setDataVencimento(modalParcelamento?.dataVencimento.split('T')[0]);
            setChangeModalParcelamento(false);
        }
    }, [changeModalParcelamento]);

    const handleChangeNumber = (set, value) => {
        set(applyRegex(value.replace('e', ''), /\d+/));
    };

    const InputLabelProps = {
        style: {
            zIndex: 0
        },
    };

    const handleChangeValue = (e, set) => {
        set(e.floatValue);
    };

    return (
        <>
            <TextField
                label="Dia de Vencimento"
                type="text"
                value={diaDoMes}
                inputProps={{
                    minLength: 1,
                    maxLength: 2
                }}
                onChange={(e) => handleChangeNumber(setDiaDoMes, e.target.value)}
                InputLabelProps={InputLabelProps}
                required
                fullWidth
            />

            <b>
                *Os próximos dois campos devem ser preenchidos em
                caso de parcelas aglutinadas no parcelamento.
                <br />Exemplo: em um parcelamento 2+2+2+2+2+20, deve-se preencher
                o campo 'Aglutinacões' com o valor '2'
                e o campo 'Quantidade de Aglutinacões' com o valor '5'
            </b>
            <TextField
                label="Aglutinacões"
                className='mt-4'
                type="text"
                value={aglutinacoes}
                inputProps={{
                    minLength: 0,
                    maxLength: 3,
                }}
                onChange={(e) => handleChangeNumber(setAglutinacoes, e.target.value)}
                InputLabelProps={InputLabelProps}
                required
                fullWidth
            />

            <TextField
                label="Quantidade de Aglutinacões"
                type="text"
                value={quantidadeAglutinacoes}
                inputProps={{
                    minLength: 0,
                    maxLength: 3,
                }}
                onChange={(e) => handleChangeNumber(setQuantidadeAglutinacoes, e.target.value)}
                InputLabelProps={InputLabelProps}
                required
                fullWidth
            />

            <DatePicker
                label="Data de Vencimento da primeira parcela"
                sx={{
                    width: '100%'
                }}
                defaultValue={dataVencimento ? dayjs(dataVencimento) : null}
                onChange={(e) => handleChangeData(e, setDataVencimento)}
                slotProps={{
                    textField: {
                        required: true
                    },
                }}
                InputLabelProps={InputLabelProps}
            />

            <FormControl component="fieldset" fullWidth required>
                <FormLabel component="legend">Você prefere gerar os boletos mensalmente ou anualmente?</FormLabel>
                <Box display="flex" justifyContent="center">
                    <RadioGroup
                        aria-label="periodoParcela"
                        name="periodoParcela"
                        value={periodoParcela}
                        onChange={(e) => setPeriodoParcela(e.target.value)}
                        row
                    >
                        <FormControlLabel value="MENSAL" control={<Radio />} label="Mensal" />
                        <FormControlLabel value="ANUAL" control={<Radio />} label="Anual" />
                    </RadioGroup>
                </Box>
            </FormControl>

            <FormControl component="fieldset" fullWidth required>
                <FormLabel component="legend">Juntar parcelas multiplas em um único boleto?</FormLabel>
                <Box display="flex" justifyContent="center">
                    <RadioGroup
                        aria-label="juncaoDeParcelasAglutinadas"
                        name="juncaoDeParcelasAglutinadas"
                        value={juncaoDeParcelasAglutinadas ? "true" : "false"}
                        onChange={(e) => setJuncaoDeParcelasAglutinadas(e.target.value === "true")}
                        row
                    >
                        <FormControlLabel value="false" control={<Radio />} label="Não" />
                        <FormControlLabel value="true" control={<Radio />} label="Sim" />
                    </RadioGroup>
                </Box>
            </FormControl>
        </>
    );
}
