import {TablePagination} from "@mui/material";
import "./PaginationControl.css"

const PaginationControl = ({totalElements, page, size, setPage, setSize, setLoadData, rowsPerPageOptions}) => {

    const handleChangePage = (event, newpage) => {
        setPage(newpage)
        setLoadData(true)
    }
    const handleChangeRowsPerPage = (event) => {
        const newSize = (parseInt(event.target.value, 10))
        setSize(newSize)
        setPage(0)
        setLoadData(true)
    }

    const rowsPerPageOptionsValues = rowsPerPageOptions ?
        rowsPerPageOptions : [10, 25, 50, 100]

    return (
        <>
            <br/>
            <TablePagination
                component="div"
                count={totalElements}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={size}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={rowsPerPageOptionsValues}
                labelDisplayedRows={
                    ({from, to, count}) => {
                        return '' + from + '-' + to + ' de ' + count
                    }
                }
                labelRowsPerPage={<span>Registros por página:</span>}
                id="pagination"
            />
        </>

    );
}

export default PaginationControl