import React, {useState} from "react";
import axios from "axios";
import {Button, Form} from "react-bootstrap";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {useUser} from "../../auth/AuthProvider";
import Popup from "../Popup/Popup";
import displayAlertMsg from "../AlertMsg/displayAlertMsg";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const PopupDelete = ({
                         buttonClass,
                         baseUrl, setLoadData,
                         title, id, buttonText,
                         children, setError,
                         errorMessage,
                         canBeRemoved,
                         blockRemoveMessage
                     }) => {

    const {user} = useUser()
    // modal control
    const [lgShow, setLgShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const iconSpan = <>
        <DeleteForeverIcon fontSize="large" id="icon-svg"/>
        {title}
    </>

    const button = <span className="link-danger">
        Deletar
        <DeleteForeverIcon fontSize="medium"/>
    </span>

    const renderTooltipDeleteDisabled = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {blockRemoveMessage}
        </Tooltip>
    );

    const buttonDeleteDisabled = <div
        className='dropdown-item'>
        <OverlayTrigger

            placement="right"
            delay={{show: 150, hide: 400}}
            overlay={renderTooltipDeleteDisabled}
        >
        <span style={{
            color: '#828282'
        }}>
            {buttonText ? buttonText : button}
        </span>
        </OverlayTrigger>

    </div>

    const handleSubmit = (e) => {
        e.preventDefault()
        setIsLoading(true)
        axios.delete(`${baseUrl}/${id}`, {
            headers: {
                'Authorization': `Bearer ${user?.token}`
            },
        })
            .then((response) => {
                setLoadData(true)
                setLgShow(false)
            })
            .catch(function (error) {
                console.log("Error: " + error)
                if (setError && errorMessage)
                    setError(displayAlertMsg(errorMessage))
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <>
            {/* Botão e popup */}
            {(canBeRemoved === undefined || !!canBeRemoved) ?
                <Popup
                    buttonClass={buttonClass ? buttonClass : 'text-danger'}
                    buttonText={buttonText ? buttonText : button}
                    title={title}
                    iconSpan={iconSpan}
                    lgShow={lgShow} setLgShow={setLgShow}
                    buttonId={"links-action"}
                >
                    <div>
                        <Form onSubmit={handleSubmit} className="form-inputs">
                            {children}
                            <Button
                                type="submit"
                                disabled={isLoading}
                                className={isLoading ? "btn btn-secondary" : "btn-danger"}
                            >
                                {isLoading ? (
                                    <>
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                        Aguarde...
                                    </>
                                ) : (
                                    'Confirmar'
                                )}
                            </Button>
                        </Form>
                    </div>
                </Popup> : <>
                    {buttonDeleteDisabled}
                </>
            }
        </>
    )
}

export default PopupDelete