import PopupDelete from "../../../component/PopupDelete/PopupDelete";
import {genderList, quantityList} from "./lists";

export default function PopupDeleteAnimal({
                                              baseUrl,
                                              setLoadData,
                                              item
                                          }) {
    return (
        <PopupDelete
            baseUrl={baseUrl} setLoadData={setLoadData}
            title={"Confirmar Exclusão de Animal"} id={item.id}
            buttonClass={'link-danger'}
        >

            <div className="mt-3 mb-3">
                <b>Número do lote: </b>
                <>{item.numeroLote}</>
                <br/>

                <b>Evento: </b>
                <>{item.evento}</>
                <br/>

                <b>Nome: </b>
                <>{item.nomeAnimal}</>
                <br/>

                <b>Gênero: </b>
                <>{item.generoAnimal}</>
                <br/>

                <b>Quantidade: </b>
                <>{item.quantidade}</>
                <br/>

            </div>

            <p className="mb-3">Você deseja realmente excluir este registro?</p>

        </PopupDelete>
    )
}