import {ApiUrl} from "../../../auth/authMethods";
import {useEffect, useState} from "react";
import axios from "axios";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from '@mui/icons-material/Send';

import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import sleep from "../../../utils/sleep";

export default function ResendPayment({pagamento, setLoadData}) {

    const url = ApiUrl + '/pagamentos/reenviar/' + pagamento.id

    const [loading, setLoading] = useState(false)

    const [loaded, setLoaded] = useState(false)
    const [error, setError] = useState(false)

    const [icon, setIcon] = useState(<SendIcon/>)
    const [color, setColor] = useState('primary')

    useEffect(() => {
        if (error) {
            setIcon(<ClearIcon/>)
            setColor('error')
            sleep(1.5 * 1000).then(() => {
                setError(false)
            })
        } else if (loaded) {
            setIcon(<CheckIcon/>)
            setColor('success')
            sleep(1.5 * 1000).then(() => {
                setLoadData(true)
            })
        } else {
            setIcon(<SendIcon/>)
            setColor('primary')
        }
    }, [error, loaded])


    const handleClick = () => {
        if (!error && !loaded) {
            setLoading(true)

            // Realiza a requisição Axios
            axios.put(url)
                .then(response => {
                    setLoaded(true)
                })
                .catch(error => setError(true))
                .finally(() => {
                    setLoading(false)
                })
        }
    }


    return <span className="dropdown-item" style={{cursor: "pointer"}}>
        <LoadingButton
            onClick={handleClick}
            loading={loading}
            loadingPosition="start"
            startIcon={icon}
            color={color}
            variant="text"
        >
            <span>
                &nbsp;{pagamento.status === 'INATIVO' && 'RE'}enviar pagamento
            </span>
        </LoadingButton>
    </span>

}