import {useState} from "react";
import TableList from "../../../component/TableList/TableList";
import FilterEvent from "./FilterEvent";
import PopupAddEvent from "./PopupAddEvent";
import {normalizeTimestemp} from "../../../utils/normalizeTimestemp";
import {statusList} from "./statusList";
import EventActions from "./EventActions";

export default function EventList({
                                      baseUrl, loadData,
                                      setLoadData, size, page,
                                      setTotalPages, setTotalElements,
                                      sort, setSort, updateCode, setPage
                                  }) {

    const [data, setData] = useState([])
    const [error, setError] = useState(false)
    const [filter, setFilter] = useState({})

    const titles = [
        'Nome',
        'Data de Início',
        'Forma de Pagamento',
        'Status'
    ]

    return (
        <>
            <FilterEvent filter={filter} setFilter={setFilter} setLoadData={setLoadData} setPage={setPage}/>
            <PopupAddEvent baseUrl={baseUrl} setLoadData={setLoadData}/>
            <TableList
                titles={titles} baseUrl={baseUrl + '/list'}
                page={page} sort={sort} size={size}
                loadData={loadData}
                setTotalPages={setTotalPages} setTotalElements={setTotalElements}
                setData={setData} setLoadData={setLoadData} filter={filter}
                error={error} setError={setError}
            >
                {data.map((item) =>
                    <tr key={item.id}>
                        <td>{item.nomeEvento}</td>
                        <td>{normalizeTimestemp(item.dataInicio)}</td>
                        <td>{item.formaPagamento}</td>
                        <td>{statusList.filter(stt => stt.value === item.status)[0].label}</td>
                        <td><EventActions item={item} setLoadData={setLoadData} baseUrl={baseUrl}/></td>
                    </tr>
                )}
            </TableList>
        </>
    )
}