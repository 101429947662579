import {useState} from "react";
import InputsAnimal from "./InputsAnimal";
import PetsIcon from '@mui/icons-material/Pets';
import PopupEdit from "../../../component/PopupEdit/PopupEdit";

export default function PopupEditAnimal({item, baseUrl, setLoadData}) {

    const [numeroLote, setNumeroLote] = useState('');
    const [nomeAnimal, setNomeAnimal] = useState('')
    const [generoAnimal, setGeneroAnimal] = useState('')
    const [quantidade, setQuantidade] = useState('')
    const [documento, setDocumento] = useState('')
    const [status, setStatus] = useState('')
    const [pedidoId, setPedidoId] = useState('')
    const [observacao, setObservacao] = useState('')

    const [errorMessage, setErrorMessage] = useState("")
    const [errorColumn, setErrorColumn] = useState("")

    const inputs = <InputsAnimal

        numeroLote={numeroLote} setNumeroLote={setNumeroLote}
        nomeAnimal={nomeAnimal} setNomeAnimal={setNomeAnimal}
        generoAnimal={generoAnimal} setGeneroAnimal={setGeneroAnimal}
        quantidade={quantidade} setQuantidade={setQuantidade}
        documento={documento} setDocumento={setDocumento}
        status={status} setStatus={setStatus}
        pedidoId={pedidoId} setPedidoId={setPedidoId}
        observacao={observacao} setObservacao={setObservacao}

        setErrorMessage={setErrorMessage} errorMessage={errorMessage}
        setErrorColumn={setErrorColumn} errorColumn={errorColumn}
    />

    const icon = <PetsIcon fontSize="large" id="icon-svg"/>

    const itemToState = () => {
        setErrorMessage(false)
        setErrorColumn(false)
        setNumeroLote(item.numeroLote)
        setNomeAnimal(item.nomeAnimal)
        setGeneroAnimal(item.generoAnimal)
        setQuantidade(item.quantidade)
        setStatus(item.status)
        setPedidoId(item.pedidoId)
        setObservacao(item.observacao)
        setDocumento(item.documento)
    }

    const returnData = () => {
        return {
            numeroLote,
            nomeAnimal,
            generoAnimal,
            quantidade,
            status,
            pedidoId,
            observacao,
            documento
        }
    }

    return <PopupEdit
        baseUrl={baseUrl + `/${item.id}`} setLoadData={setLoadData}
        inputs={inputs} title={'Editar Animal'} icon={icon}
        returnData={returnData} itemToState={itemToState}
        setErrorMessage={setErrorMessage} setErrorColumn={setErrorColumn}
        buttonClass='dropdown-item'
    />
}