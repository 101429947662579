import React, {useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import axios from "axios";
import {ApiUrl} from "../../../../auth/authMethods";
import {useUser} from "../../../../auth/AuthProvider";
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import InputsParticipante from "../../../Users/components/inputs/InputsParticipante";

export default function InputsUser({
                                       obj, setObj,
                                       index, type,
                                       setBlockSubmit
                                   }) {

    const {user} = useUser()

    const [userData, setUserData] = useState('')

    const [email, setEmail] = useState('')
    const [porcentagem, setPorcentagem] = useState('')
    const [participante, setParticipante] = useState({})

    const [hidePayerInputs, setHidePayerInputs] = useState(false)

    const [hideBankDetailsAndPix, setHideBankDetailsAndPix] = useState(type === 'COMPRADOR')

    useEffect(() => {
        setObj({
            email,
            porcentagem,
            participante: hidePayerInputs ? null : participante,
            papel: type,
            pagador: index === 0,
            index
        })
    }, [email, porcentagem, participante])

    const handleChangePorcentagem = (event) => {
        // Remover caracteres não numéricos e substituir múltiplos pontos por um único ponto
        const inputValue = event.target.value
            .replace(/[^0-9.]/g, '') // Remove caracteres não numéricos, exceto pontos
            .replace(/(\..*)\./g, '$1'); // Substitui múltiplos pontos por um único ponto

        if (inputValue !== '') {
            const floatValue = parseFloat(inputValue);
            if (!isNaN(floatValue) && floatValue >= 0 && floatValue <= 100) {
                setPorcentagem(inputValue);
            }
        } else {
            setPorcentagem('');
        }
    };

    useEffect(() => {
        const headers = {
            Authorization: `Bearer ${user?.token}`
        }
        if (email?.length > 5) {
            axios.get(ApiUrl + '/user/find-by-email?email=' + email, {headers})
                .then(function (response) {
                    setHidePayerInputs(response.data?.id > 0)
                    if (response.data?.id > 0)
                        setUserData(response.data)
                }).catch(function (errorReceived) {
                setHidePayerInputs(false)
            })
        } else {
            setHidePayerInputs(false)
        }
    }, [email])

    const checkUserCanBeSeller = (part) => {
        return part?.dadosPix.chavePix.length > 0
    }

    return <>
        <TextField
            label="Email"
            required={true}
            inputProps={{
                maxLength: 100
            }}
            type="email"
            erortext="Campo obrigatório!"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            InputLabelProps={{
                style: {
                    zIndex: 0
                },
            }}
        />

        {hidePayerInputs && <>
            {userData?.roles.map((item) => item.name).includes('ROLE_ADMIN') ||
            userData?.roles.includes('ROLE_ADMIN') ?
                <><p style={{color: 'red'}}>
                    <ErrorIcon/> Este usuário é um administrador e não pode ter lotes associados à ele.
                </p> {setBlockSubmit(true)}</> :
                <>{checkUserCanBeSeller(userData.participante) || type === 'COMPRADOR' ?
                    <p style={{color: 'green'}}><CheckIcon/> Usuário já cadastrado em nossa base</p> :
                    <><p style={{color: 'red'}}>
                        <ErrorIcon/> Finalize o cadastro deste usuário para poder atrelar
                        um lote à ele.
                    </p> {setBlockSubmit(true)}</>
                }</>
            }
        </>}

        <TextField
            label="Porcentagem (0-100)"
            variant="outlined"
            value={porcentagem}
            onChange={handleChangePorcentagem}
            fullWidth
            required
            inputProps={{
                maxLength: 5
            }}
            InputLabelProps={{
                style: {
                    zIndex: 0
                },
            }}
        />

        {!hidePayerInputs &&
            <InputsParticipante
                participante={participante}
                setParticipante={setParticipante}
                setBlockSubmit={setBlockSubmit}
                hideBankDetailsAndPix={hideBankDetailsAndPix}
                setHideBankDetailsAndPix={type === 'COMPRADOR' ? setHideBankDetailsAndPix : false}
            />
        }

    </>
}