import {useState} from "react";
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import {ApiUrl} from "../../../auth/authMethods";
import axios from "axios";

export default function DownloadExtract({id, lote}) {

    const url = ApiUrl + '/extratos/generate/' + id

    const [loading, setLoading] = useState(false)

    const handleClick = () => {
        setLoading(true)

        const axiosConfig = {
            method: 'get',
            url: url,
            responseType: 'blob'
        };

        // Realiza a requisição Axios
        axios(axiosConfig)
            .then(response => {
                // Cria um objeto URL para o blob
                const blobUrl = URL.createObjectURL(response.data);

                // Cria um link para download
                const link = document.createElement('a');
                link.href = blobUrl;
                link.download = 'extrato-lote' + lote + '.pdf';

                // Adiciona o link ao corpo do documento
                document.body.appendChild(link);

                // Simula um clique no link para iniciar o download
                link.click();

                // Remove o link do corpo do documento após o download
                document.body.removeChild(link);
            })
            .catch(error => console.error('Erro ao gerar extrato:', error))
            .finally(() => {
                setLoading(false)
            })
    }


    return <span className="dropdown-item" style={{cursor: "pointer"}}>
        <LoadingButton
            onClick={handleClick}
            loading={loading}
            loadingPosition="start"
            startIcon={<SaveIcon/>}
            variant="text"
        >
            <span>
                &nbsp;Baixar Extrato
            </span>
        </LoadingButton>
    </span>

    // return <span className="dropdown-item" style={{cursor: "pointer"}}>
    //     <svg
    //         className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false"
    //         aria-hidden="true" viewBox="0 0 24 24" data-testid="VisibilityIcon"><path
    //         d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"></path></svg>
    //     &nbsp; Detalhes do Lote
    // </span>
}