import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useUser } from './AuthProvider';

export function PrivateAdminRoute() {
    const { user } = useUser();

    const isAuthorized = user?.isAdmin || user?.isGroupLeader;

    return isAuthorized ? <Outlet /> : <Navigate to="/" />;
}