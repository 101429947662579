import FilterForm from "../../../component/FilterForm/FilterForm";
import React, {useState} from "react";
import TextField from "@mui/material/TextField";
import Select from "react-select";
import {statusList} from "./statusList";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {handleChangeData} from "../../../utils/handleChangeData";

export default function FilterAntecipacao({filter, setFilter, setLoadData, setPage}) {

    const [lgShow, setLgShow] = useState(false);
    const [dataAntecipacao, setDataAntecipacao] = useState('');
    const [status, setStatus] = useState('')


    const handlesSubmit = (e) => {

        e.preventDefault();
        let filterData = {}
        if (dataAntecipacao)
            filterData.dataAntecipacao = dataAntecipacao
        if (status)
            filterData.status = status

        setFilter(filterData);
        setLgShow(false);
        setLoadData(true);
        setPage(0);
    }

    const clearFilter = () => {
        setStatus('')
        setDataAntecipacao('')
        setFilter({});
        setLgShow(false);
        setLoadData(true);
        setPage(0);
    }

    return <>
        <FilterForm
            handleSubmit={handlesSubmit} clearFilter={clearFilter}
            lgShow={lgShow} setLgShow={setLgShow}
            showSecondaryButton={Object.keys(filter).length !== 0}
        >
            <DatePicker
                label="Data de Antecipação"
                sx={{
                    width: '100%'
                }}
                defaultValue={dataAntecipacao ? dayjs(dataAntecipacao) : null}
                onChange={(e) => handleChangeData(e, setDataAntecipacao)}
            />
            <Select
                className="basic-single"
                classNamePrefix="select"
                id="form_select"
                required={false}
                defaultValue={statusList.filter(item => item.value === status)}
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                isSearchable={true}
                options={statusList}
                onChange={(e) => setStatus(e?.value)}
                placeholder={'Status'}
            />
        </FilterForm>
    </>
}