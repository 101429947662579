import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import InputsInstallmentType from "./InputsInstallmentType";

export default function DisplayManyInstallmentType({
                                                       obj, setObj, baseUrl,
                                                       setBlockSubmit, name
                                                   }) {

    const addComponent = () => {
        const index = obj.length
        setObj([...obj, {index}]);
    };

    const removeComponent = (index) => {
        if (obj.length > 1) {
            const updatedComponents = [...obj];
            updatedComponents.splice(index, 1);
            setObj(updatedComponents);
        }
    };

    const alterItem = (index, data) => {
        setObj((prevComponents) => {
            const updatedComponents = [...prevComponents];
            updatedComponents[index] = data;
            return updatedComponents;
        });
    };

    return (
        <div>
            {obj.map((componentData, index) => (
                <div key={index}>
                    <>
                        <Box mb={0.5} pb={0.5}>
                            {name} {index + 1}
                            {obj.length > 1 && index !== 0 &&
                                <div
                                    className='d-inline text-danger'
                                    style={{cursor: 'pointer'}}
                                    onClick={() => removeComponent(index)}
                                >
                                    &nbsp; Remover
                                </div>
                            }
                        </Box>
                    </>
                    <InputsInstallmentType
                        set={(newData) => {
                            alterItem(index, newData);
                        }}
                        index={index}
                    />
                </div>
            ))}
            <Button fullWidth onClick={addComponent} type='button'>Adicionar mais um {name} </Button>
        </div>
    );
}