import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import React, {useState} from "react";
import applyRegex from "../../../utils/applyRegex";
import PersonIcon from '@mui/icons-material/Person';
import {Switch} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import {ApiUrl} from "../../../auth/authMethods";
import SelectInput from "../../../component/SelectInput";

const aliasRoles = [
    {received: 'ROLE_USER', display: 'Usuário comum'},
    {received: 'ROLE_ADMIN', display: 'Usuário administrador'}
]

export default function AddUsersForm({
                                         username, setUsername,
                                         email, setEmail,
                                         isAccountNonExpired, setIsAccountNonExpired,
                                         isAccountNonLocked, setIsAccountNonLocked,
                                         isCredentialsNonExpired, setIsCredentialsNonExpired,
                                         isEnabled, setIsEnabled,
                                         isTwoFactorEnabled, setIsTwoFactorEnabled,
                                         roles, setRoles,
                                         button, handleSubmit, error
                                     }) {

    const [roleList, setRoleList] = useState([])

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{mt: 1}}>
            <div className="text-center">
                <PersonIcon fontSize='large' sx={{fontSize: '4rem'}}/><br/>
                <b>Cadastro de novo usuário</b><br/>
            </div>
            {error}
            <TextField
                id="username" type='text'
                margin='normal'
                label="Nome de usuário" name="username"
                autoComplete="username"
                variant='standard'
                value={username}
                onChange={(e) => {
                    setUsername(applyRegex(e.target.value, /([0-9a-zA-Za-z.-])/g))
                }}
                required fullWidth autoFocus
            />
            <TextField
                id="email" type='email'
                margin='normal'
                label="Email" name="email"
                autoComplete="email"
                variant='standard'
                value={email} onChange={(e) => setEmail(e.target.value)}
                className='mb-3'
                required fullWidth
            />
            <SelectInput
                url={ApiUrl + '/role?size=2000'}
                list={roleList} set={setRoleList}
                setObj={setRoles}
                returnList={() => {
                    let returnList = []
                    roleList.forEach((item) => {
                        returnList.push({
                            value: item,
                            label: aliasRoles.filter(alias => alias.received === item.name)[0].display
                        })
                    })
                    return returnList
                }}
                placeholder={"Tipo de usuário"}
                required={true}
                fullWidth
            />
            <div className='align mt-2'>
                {/*<FormControlLabel*/}
                {/*    control={*/}
                {/*        <Switch*/}
                {/*            checked={!isAccountNonExpired}*/}
                {/*            onChange={(e) => setIsAccountNonExpired(!isAccountNonExpired)}*/}
                {/*            name="isAccountNonExpired"*/}
                {/*        />*/}
                {/*    }*/}
                {/*    label="Expirado"*/}
                {/*/> <br/>*/}
                {/*<FormControlLabel*/}
                {/*    control={*/}
                {/*        <Switch*/}
                {/*            checked={!isAccountNonLocked}*/}
                {/*            onChange={(e) => setIsAccountNonLocked(!isAccountNonLocked)}*/}
                {/*            name="isAccountNonLocked"*/}
                {/*        />*/}
                {/*    }*/}
                {/*    label="Bloqueado"*/}
                {/*/> <br/>*/}
                {/*<FormControlLabel*/}
                {/*    control={*/}
                {/*        <Switch*/}
                {/*            checked={isEnabled}*/}
                {/*            onChange={(e) => setIsEnabled(!isEnabled)}*/}
                {/*            name="isEnabled"*/}
                {/*        />*/}
                {/*    }*/}
                {/*    label="Ativo"*/}
                {/*/> <br/>*/}
                <FormControlLabel
                    control={
                        <Switch
                            checked={isTwoFactorEnabled}
                            onChange={(e) => setIsTwoFactorEnabled(!isTwoFactorEnabled)}
                            name="isTwoFactorEnabled"
                        />
                    }
                    label="Autenticação em duas etapas"
                />
            </div>
            {button}
        </Box>
    )
}