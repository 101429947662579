import {ApiUrl} from "../../../auth/authMethods";
import {useState} from "react";
import axios from "axios";
import LoadingButton from "@mui/lab/LoadingButton";
import ReceiptIcon from '@mui/icons-material/Receipt';

export default function DownloadPaymentReceipt({id}) {

    const url = ApiUrl + '/pagamentos/receipt/' + id

    const [loading, setLoading] = useState(false)

    const handleClick = () => {
        setLoading(true)

        const axiosConfig = {
            method: 'get',
            url: url,
            responseType: 'blob'
        };

        // Realiza a requisição Axios
        axios(axiosConfig)
            .then(response => {
                // Cria um objeto URL para o blob
                const blobUrl = URL.createObjectURL(response.data);

                // Cria um link para download
                const link = document.createElement('a');
                link.href = blobUrl;
                link.download = 'comprovante-pagamento-' + id + '.pdf';

                // Adiciona o link ao corpo do documento
                document.body.appendChild(link);

                // Simula um clique no link para iniciar o download
                link.click();

                // Remove o link do corpo do documento após o download
                document.body.removeChild(link);
            })
            .catch(error => console.error('Erro ao gerar relatório:', error))
            .finally(() => {
                setLoading(false)
            })
    }


    return <span className="dropdown-item" style={{cursor: "pointer"}}>
        <LoadingButton
            onClick={handleClick}
            loading={loading}
            loadingPosition="start"
            startIcon={<ReceiptIcon/>}
            variant="text"
        >
            <span>
                &nbsp;Obter Comprovante
            </span>
        </LoadingButton>
    </span>
}