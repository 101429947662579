import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const AlertPopup = ({
                        type = "alert",
                        title,
                        message,
                        onConfirm,
                        onCancel,
                        confirmButtonText = "Confirmar",
                        cancelButtonText = "Cancelar",
                        showPopup,
                        setShowPopup
                    }) => {

    const handleConfirm = () => {
        if (onConfirm) onConfirm();
        setShowPopup(false);
    };

    const handleCancel = () => {
        if (onCancel) onCancel();
        setShowPopup(false);
    };

    const icon = type === "alert" ? <ErrorOutlineIcon fontSize="large" color="error" /> : <HelpOutlineIcon fontSize="large" color="primary" />;

    return (
        <Modal show={showPopup} onHide={handleCancel} centered>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex align-items-center">
                    {icon}
                    <span className="ms-2">{message}</span>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {type === "confirm" && (
                    <Button variant="secondary" onClick={handleCancel}>
                        {cancelButtonText}
                    </Button>
                )}
                <Button variant={type === "alert" ? "danger" : "primary"} onClick={handleConfirm}>
                    {confirmButtonText}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AlertPopup;
