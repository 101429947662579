import {useState} from "react";
import ContentContainer from "../../component/ContentContainer";
import PopupAddUser from "./components/PopupAddUser";
import ListUser from "./components/ListUser";
import PaginationControl from "../../component/Pagination/PaginationControl";
import {ApiUrl} from "../../auth/authMethods";

export default function Users() {

    const [loadData, setLoadData] = useState(true)

    const [size, setSize] = useState(100)
    const [page, setPage] = useState(0)
    const [sort, setSort] = useState("")

    const [totalPages, setTotalPages] = useState("")
    const [totalElements, setTotalElements] = useState(10)

    return <>
        <ContentContainer width='xl'>
            <h1>Lista de Usuários </h1>
            <PopupAddUser baseUrl={ApiUrl} setLoadData={setLoadData}/>
            <ListUser
                setLoadData={setLoadData}
                baseUrl={ApiUrl}
                loadData={loadData}
                page={page}
                setPage={setPage}
                size={size}
                setTotalPages={setTotalPages}
                setSort={setSort}
                sort={sort}
                setTotalElements={setTotalElements}
            />
            <PaginationControl
                totalElements={totalElements}
                page={page}
                setPage={setPage}
                size={size}
                setSize={setSize}
                setLoadData={setLoadData}
                rowsPerPageOptions={[100, 200, 500, 1000]}
            />
        </ContentContainer>

    </>
}