import {useState} from "react";
import TableList from "../../../component/TableList/TableList";
import {normalizeTimestemp} from "../../../utils/normalizeTimestemp";
import {numberWithPoints} from "../../../utils/numberWithPoints";
import FilterWriteOff from "./FilterWriteOff";
import PopupOrderDetails from "../../Order/Components/PopupOrderDetails";
import VisibilityIcon from "@mui/icons-material/Visibility";
import WriteOffActions from "./WriteOffActions";

export default function WriteOffList({
                                         baseUrl, loadData,
                                         setLoadData, size, page,
                                         setTotalPages, setTotalElements,
                                         sort, setSort, setPage
                                     }) {

    const initialFilter = {baixaManual: true}

    const [data, setData] = useState([])
    const [error, setError] = useState(false)
    const [filter, setFilter] = useState(initialFilter)

    const titles = [
        'Id da Parcela',
        'Número do Lote',
        'Data de Criação',
        'Valor',
        'Status',
    ]

    const label = <>
        Ver Lote <VisibilityIcon fontSize="medium"/>
    </>

    return (
        <>
            <FilterWriteOff
                filter={filter} setFilter={setFilter} setLoadData={setLoadData}
                setPage={setPage} initialFilter={initialFilter}/>
            <TableList
                titles={titles} baseUrl={baseUrl}
                page={page} sort={sort} size={size}
                loadData={loadData}
                setTotalPages={setTotalPages} setTotalElements={setTotalElements}
                setData={setData} setLoadData={setLoadData} filter={filter}
                error={error} setError={setError}
            >
                {data.map((item) =>
                    <tr key={item.id}>
                        <td>{item.id}</td>
                        <td>{item.pedido.numero}</td>
                        <td>{normalizeTimestemp(item.dateCreated)}</td>
                        <td>R$ {numberWithPoints(item.valor)}</td>
                        <td>{item.status}</td>
                        <td><WriteOffActions item={item} setLoadData={setLoadData} label={label}/></td>
                    </tr>
                )}
            </TableList>
        </>
    )
}