import PopupDelete from "../../../component/PopupDelete/PopupDelete";
import {normalizeTimestemp} from "../../../utils/normalizeTimestemp";
import {statusList} from "./statusList";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import React from "react";

export default function PopupDeleteEvent({
                                             baseUrl,
                                             setLoadData,
                                             item
                                         }) {

    const buttonText = <span>
        <DeleteForeverIcon fontSize="medium"/> &nbsp;
        Inativar Evento
    </span>

    return (
        <PopupDelete
            baseUrl={baseUrl} setLoadData={setLoadData}
            title={"Confirmar Inativação de Evento"} id={item.id}
            buttonClass='dropdown-item text-danger' buttonText={buttonText}
        >

            <div className="mt-3 mb-3">
                <b>Nome do Evento: </b>
                <>{item.nomeEvento}</>
                <br/>

                <b>Data de Inicio: </b>
                <>{normalizeTimestemp(item.dataInicio)}</>
                <br/>

                <b>Forma de Pagamento: </b>
                <>{item.formaPagamento}</>
                <br/>

                <b>Status: </b>
                <>{statusList.filter(stt => stt.value === item.status)[0].label}</>
                <br/>

            </div>

            <p className="mb-3">Você deseja realmente excluir este registro?</p>

        </PopupDelete>
    )
}