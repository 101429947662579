import {Button, Modal, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import React, {useEffect, useState} from "react";
import axios from "axios";
import displayAlertMsg from "../../../../../component/AlertMsg/displayAlertMsg";
import {ApiUrl} from "../../../../../auth/authMethods";
import PreviewData from "./PreviewData";
import BasicLoader from "../../../../../component/BasicLoader/BasicLoader";

export default function PreviewModal({
                                         openPreview, setOpenPreview, validateData,
                                         returnData, error, setError
                                     }) {
    const [isLoading, setIsLoading] = useState(true);
    const [previewData, setPreviewData] = useState(null);

    useEffect(() => {
        if (openPreview) {

            setError(false);
            setIsLoading(false);

            const data = returnData();

            let dataIsValid = validateData();

            if (dataIsValid) {
                setIsLoading(true);
                axios
                    .post(ApiUrl + '/multi-entity/register-v2/preview', data)
                    .then(function (response) {
                        setPreviewData(response.data)
                    })
                    .catch(function (error) {
                        setPreviewData(null)
                        if (error.response.status === 409) {
                            setError(displayAlertMsg(error.response.data.message));
                        }
                        if (error.response.status >= 500) {
                            setError(displayAlertMsg());
                        }
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            }
            else
                setPreviewData(null)
        }
    }, [openPreview])


    return <Modal
        open={openPreview}
        onClose={() => setOpenPreview(false)}
        className="modal-xl d-flex align-items-center justify-content-center"
        aria-labelledby="example-modal-sizes-title-lg"
        backdrop="static"
        centered
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
    >
        <Box
            width="80%"
            bgcolor="background.paper"
            borderRadius={8}
            boxShadow={24}
            p={4}
        >
            <Typography variant="h6" component="h2" gutterBottom>
                Preview da Geração de Parcelas
            </Typography>
            <Typography variant="body1" component="pre" style={{maxHeight: '60vh', overflow: 'auto'}}>
                {isLoading ?
                    <BasicLoader/> :
                    <>{(!!previewData) ?
                        <PreviewData data={previewData}/> :
                        <p className='text-danger'>
                            {(!!error) ?
                                error :
                                "Preview não disponível! " +
                                "Preencha todos os campos para " +
                                "podermos calcular as parcelas"
                            }
                        </p>
                    }</>
                }
            </Typography>
            <Button color={"success"} variant={"contained"} onClick={() => setOpenPreview(false)}
                    style={{marginTop: '20px'}}>
                Voltar para Edição
            </Button>
        </Box>
    </Modal>
}