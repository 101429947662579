import LargeModal from "../../../component/LargeModel";
import {Button, Form} from "react-bootstrap";
import React, {useState} from "react";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import axios from "axios";
import {useUser} from "../../../auth/AuthProvider";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const PasswordReset = ({baseUrl, setLoadData, item, children}) => {

    const [errorMessage, setErrorMessage] = useState("");
    const [errorColumn, setErrorColumn] = useState("");

    const [lgShow, setLgShow] = useState(false);
    const [blockSubmit, setBlockSubmit] = useState(false);

    const [id, setId] = useState("")


    const {user} = useUser()

    const buttonClass="dropdown-item text-danger"

    const buttonText = <span className="link-danger">
        <RestartAltIcon fontSize="medium"/>
        &nbsp;Redefinir Senha
    </span>

    const iconSpan = <>
        <RestartAltIcon fontSize="large" id="icon-svg"/>
        Redefinir Senha
    </>

    const button = <span className="link-danger">
        Redefinir
        <RestartAltIcon fontSize="medium"/>
    </span>

    const returnData = () => {
        console.log({
            id
        });

        const data = {
        }

        if (id !== '') {
            data.id = id;
        }

        return (data);
    };

    const handleSubmit = (e) => {
        e.preventDefault()

        const data = returnData()

        const headers = {
            Authorization: `Bearer ${user?.token}`
        }

        if (!blockSubmit) {
            axios.put(baseUrl + item.id, data, {headers})
                .then((response) => {
                    setLoadData(true)
                    setLgShow(false)
                    if (typeof setErrorColumn == 'function') {
                        setErrorMessage("")
                        setErrorColumn("")
                    }
                }).catch((error) => {
                // aqui temos acesso ao erro, quando alguma coisa inesperada acontece:
                console.log(error);
                if (error.response && error.response.status === 409) {
                    setErrorMessage(error.response.data.message);
                    setErrorColumn(error.response.data.column);
                }
            })
        }
    }



    return (
        <>
            {/* Botão e popup */}
            <LargeModal
                buttonClass={buttonClass? buttonClass : 'text-danger'}
                buttonText={buttonText? buttonText : button}
                title={"Confirmar redefinição de senha"}
                iconSpan={iconSpan}
                lgShow={lgShow} setLgShow={setLgShow}
                buttonId={"links-action"}
            >
                <div>
                    <Form onSubmit={handleSubmit} className="form-inputs">
                        {children}
                        <Button type="submit" className="btn-danger">
                            Confirmar Redefinição
                        </Button>
                    </Form>
                </div>


            </LargeModal>
        </>
    )
}

export default PasswordReset