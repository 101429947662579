import {useState} from "react";
import TableList from "../../../component/TableList/TableList";
import FilterInstallmentPattern from "./FilterInstallmentPattern";
import PopupAddInstallmentPattern from "./PopupAddInstallmentPattern";
import {entradaTypeList} from "./entradaTypeList";
import InstallmentPatternActions from "./InstallmentPatternActions";

export default function InstallmentPatternList({
                                      baseUrl, loadData,
                                      setLoadData, size, page,
                                      setTotalPages, setTotalElements,
                                      sort, setSort, updateCode, setPage
                                  }) {

    const [data, setData] = useState([])
    const [error, setError] = useState(false)
    const [filter, setFilter] = useState({})

    const titles = [
        'Nome',
        'Entrada'
    ]

    return (
        <>
            <FilterInstallmentPattern filter={filter} setFilter={setFilter} setLoadData={setLoadData} setPage={setPage}/>
            <PopupAddInstallmentPattern baseUrl={baseUrl} setLoadData={setLoadData}/>
            <TableList
                titles={titles} baseUrl={baseUrl + '/list'}
                page={page} sort={sort} size={size}
                loadData={loadData}
                setTotalPages={setTotalPages} setTotalElements={setTotalElements}
                setData={setData} setLoadData={setLoadData} filter={filter}
                error={error} setError={setError}
            >
                {data.map((item) =>
                    <tr key={item.id}>
                        <td>{item.nome}</td>
                        <td>{entradaTypeList.filter(ent => ent.value === item.entrada)[0].label}</td>
                        <td><InstallmentPatternActions item={item} setLoadData={setLoadData} baseUrl={baseUrl}/></td>
                    </tr>
                )}
            </TableList>
        </>
    )
}