import {normalizeTimestemp} from "../../../utils/normalizeTimestemp";
import {Button} from "react-bootstrap";
import React, {useState} from "react";
import axios from "axios";
import {ApiUrl} from "../../../auth/authMethods";
import FileInput from "../../../component/FileInput/FileInput";
import Textarea from "@mui/joy/Textarea";

export default function WriteOffForm({
                                         item, setNeedToReload, isLoading, setIsLoading, setError, handleClose
                                     }) {

    const [motivo, setMotivo] = useState('')
    const [anexos, setAnexos] = useState([])

    const writeOff = (e) => {

        e.preventDefault()


        const formData = new FormData();
        formData.append('motivo', motivo);
        for (let file of anexos) {
            formData.append('files', file);
        }

        setIsLoading(true)
        axios.put(ApiUrl + `/parcelas/baixa-manual/${item.id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(() => {
                setNeedToReload(true)
            })
            .catch(() => {
                setError(true)
            }).finally(() => setIsLoading(false))
    }

    return <>
        <form action={writeOff} style={{textAlign: 'center'}}>
            <h4>Dados da parcela</h4>
            <p id="child-modal-description">
                <b>Id: </b>{item.id} &nbsp;
                <b>Parcela: </b>{item.numero} &nbsp;
                <b>Vencimento: </b>{normalizeTimestemp(item.vencimento, true)} &nbsp;
                <b>Valor: </b>{item.valor} &nbsp;
                <b>Status: </b>{item.status} &nbsp;
            </p>

            <hr/>
            <h4>Formulário de cancelamento</h4>

            <Textarea
                value={motivo}
                onChange={(e) => setMotivo(e.target.value)}
                required
                placeholder='Descreva com detalhes o assunto do contato'
                size="lg"
                variant="outlined"
                className="mt-3 mb-3"
                minRows={2}
                sx={{width: '70%', marginLeft: '15%'}}
            />

            <FileInput
                selectedFile={anexos}
                setSelectedFile={setAnexos}
            />

            <div className='text-center'>
                <Button
                    onClick={writeOff}
                    disabled={isLoading || (!motivo || !anexos.length > 0)}
                    className={isLoading ? "btn btn-secondary" : ""}
                >
                    {isLoading ? (<>
                          <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                          ></span>
                        &nbsp;Aguarde...
                    </>) : (<>Confirmar</>)}
                </Button>
                &nbsp;
                <Button
                    onClick={handleClose} variant='outline-secondary'
                >
                    Cancelar
                </Button>
            </div>
        </form>
    </>
}