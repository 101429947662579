import React, {useEffect, useState} from 'react';
import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField} from '@mui/material';
import InputMask from 'react-input-mask';
import {validateCpf} from "../../../../utils/validateCpf";
import {validateCnpj} from "../../../../utils/validateCnpj";
import Box from "@mui/material/Box";

export default function InputsPix({dadosPix, setDadosPix, setBlockSubmit}) {


    const [tipoChave, setTipoChave] = useState(dadosPix?.tipoChave ? dadosPix?.tipoChave : 'CPF')
    const [chavePix, setChavePix] = useState(dadosPix?.chavePix ? dadosPix?.chavePix : '')

    // effect que altera a variavel principal a partir dos states
    useEffect(() => {

        // retirando caracteres especiais de chave apenas
        // caso a chave selecionada seja CPF, CNPJ ou telefone
        const pixReplaced =
            (tipoChave === 'CPF' || tipoChave === 'CNPJ' || tipoChave === 'PHONE') ?
                chavePix.replace(/[^\w\s]/gi, '') :
                chavePix

        setDadosPix(prevData => {
            return {
                ...prevData,
                tipoChave,
                chavePix: pixReplaced
            }
        })
    }, [tipoChave, chavePix])

    // effect para validações
    useEffect(() => {
        // validação de cpf
        if (tipoChave === 'CPF' && chavePix.length === 14) {
            setBlockSubmit(!validateCpf(chavePix))
        }
        // validação de cnpj
        else if (tipoChave === 'CNPJ' && chavePix.length === 18) {
            setBlockSubmit(!validateCnpj(chavePix))
        }
        // se não for cnpj nem cpf, interrompemos a validacao e deixamos o submit ser feito
        else {
            setBlockSubmit(false)
        }
    }, [chavePix, tipoChave])

    // effect para zerar valor de chave sempre que for alterado o tipo
    useEffect(() => {
        if (dadosPix?.tipoChave !== tipoChave || dadosPix?.chavePix !== chavePix)
            setChavePix('')
    }, [tipoChave])

    return <>
        <FormControl component="fieldset">
            <FormLabel component="legend">Tipo de chave pix:</FormLabel>
            <Box display="flex" justifyContent="center">
                <RadioGroup
                    aria-label="option"
                    name="option"
                    value={tipoChave}
                    onChange={(e) => setTipoChave(e.target.value)}
                    row
                >
                    <FormControlLabel value="CPF" control={<Radio/>} label="CPF"/>
                    <FormControlLabel value="CNPJ" control={<Radio/>} label="CNPJ"/>
                    <FormControlLabel value="PHONE" control={<Radio/>} label="Telefone"/>
                    <FormControlLabel value="EMAIL" control={<Radio/>} label="Email"/>
                    <FormControlLabel value="EVP" control={<Radio/>} label="EVP"/>
                </RadioGroup>
            </Box>

            {tipoChave === 'CPF' && (
                <InputMask mask="999.999.999-99" value={chavePix} maskChar={null}
                           onChange={(e) => setChavePix(e.target.value)}>
                    {() =>
                        <TextField
                            required label="CPF" variant="outlined"
                            error={chavePix.length === 14 && !validateCpf(chavePix)}
                            helperText={chavePix.length === 14 && !validateCpf(chavePix) ? 'CPF inválido' : ''}
                            inputProps={{
                                maxLength: 14,
                                minLength: 14,
                            }}
                        />}
                </InputMask>
            )}

            {tipoChave === 'CNPJ' && (
                <InputMask mask="99.999.999/9999-99" value={chavePix} maskChar={null}
                           onChange={(e) => setChavePix(e.target.value)}>
                    {() =>
                        <TextField
                            required label="CNPJ" variant="outlined"
                            error={chavePix.length === 18 && !validateCnpj(chavePix)}
                            helperText={chavePix.length === 18 && !validateCnpj(chavePix) ? 'CNPJ inválido' : ''}
                            inputProps={{
                                maxLength: 18,
                                minLength: 18,
                            }}
                        />}
                </InputMask>
            )}

            {tipoChave === 'PHONE' && (
                <InputMask mask="(99) 99999-9999" value={chavePix} maskChar={null}
                           onChange={(e) => setChavePix(e.target.value)}>
                    {() =>
                        <TextField
                            required label="Telefone" variant="outlined"
                            inputProps={{
                                maxLength: 15,
                                minLength: 15,
                            }}
                        />}
                </InputMask>
            )}

            {tipoChave === 'EMAIL' && (
                <TextField
                    required label="E-mail" variant="outlined" value={chavePix} type='email'
                    onChange={(e) => setChavePix(e.target.value)}
                    inputProps={{
                        maxLength: 80,
                    }}
                />
            )}

            {tipoChave === 'EVP' && (
                <TextField
                    required label="EVP" variant="outlined" value={chavePix}
                    onChange={(e) => setChavePix(e.target.value)}
                    inputProps={{
                        maxLength: 80,
                    }}
                />
            )}

        </FormControl>
    </>
}