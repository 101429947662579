import LargeModal from "../../../component/LargeModel";
import React, { useState } from "react";
import { useUser } from "../../../auth/AuthProvider";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Button, Form } from "react-bootstrap";

const Impersonate = ({ item, children }) => {
    const [lgShow, setLgShow] = useState(false);
    const { impersonateUser } = useUser();

    const buttonText = <>
        <AccountCircleIcon fontSize="medium" />
        &nbsp; Impersonar Usuário
    </>;

    const iconSpan = (
        <AccountCircleIcon style={{ fontSize: 100 }} id="icon-svg" />
    );

    const handleSubmit = (e) => {
        e.preventDefault();
        impersonateUser(item);
        setLgShow(false);
    };

    return (
        <>
            <LargeModal
                buttonClass={"dropdown-item"}
                buttonText={buttonText}
                title={`Realmente deseja impersonar o usuário ${item.username}?`}
                iconSpan={iconSpan}
                lgShow={lgShow} setLgShow={setLgShow}
                buttonId={"links-action"}
            >
                <div className="message-container">
                    <span><b>Atenção</b></span>
                    <hr />
                    <div className="message-content">
                        <p>
                            Ao utilizar a funcionalidade de impersonação de usuários, lembrem-se que você estará visualizando o sistema sob a perspectiva do usuário selecionado. Porém, é importante destacar que todas as ações realizadas durante o período de impersonação serão registradas com o seu nome.
                        </p>
                        <p>
                            Certifiquem-se de usar essa funcionalidade de maneira responsável e apenas quando necessário.
                        </p>
                        <p>
                            Obrigado pela compreensão.
                        </p>
                    </div>
                    <hr />
                </div>
                <div>
                    <Form onSubmit={handleSubmit} className="form-inputs">
                        {children}
                        <Button type="submit" className="btn">
                            Confirmar Impersonate
                        </Button>
                    </Form>
                </div>
            </LargeModal>
        </>
    );
};

export default Impersonate;
