import CallIcon from "@mui/icons-material/Call";
import TextField from "@mui/material/TextField";
import {phoneMask} from "../../../utils/phoneMask";
import Box from "@mui/material/Box";
import React from "react";
import Textarea from '@mui/joy/Textarea';

export default function ContactUsForm({
                                          phone, setPhone,
                                          email, setEmail,
                                          name, setName,
                                          title, setTitle,
                                          description, setDescription,
                                          button, handleSubmit, error
                                      }){
    return (
        <Box component="form" onSubmit={handleSubmit} sx={{mt: 1}}>
            <div className="text-center">
                <CallIcon fontSize='large' sx={{fontSize: '4rem'}}/><br/>
                <b>Preencha os dados abaixo para entrar em contato com a nossa equipe.</b><br/>
            </div>
            {error}
            <TextField
                id="name" type='text'
                margin='normal'
                label="Seu nome" name="name"
                autoComplete="name"
                variant='standard'
                value={name} onChange={(e) => setName(e.target.value)}
                required fullWidth autoFocus
            />
            <TextField
                id="email" type='email'
                margin='normal'
                label="Email" name="email"
                autoComplete="email"
                variant='standard'
                value={email} onChange={(e) => setEmail(e.target.value)}
                required fullWidth
            />
            <TextField
                variant='standard'
                margin='normal'
                label="Telefone"
                inputProps={{
                    minLength: 15,
                    maxLength: 15
                }}
                type="text"
                erortext="Campo obrigatório!"
                value={phoneMask(phone)}
                onChange={(e) => setPhone(e.target.value)}
                fullWidth required
            />
            <TextField
                id="title" type='text'
                margin='normal'
                label="Assunto" name="title"
                autoComplete="title"
                variant='standard'
                value={title} onChange={(e) => setTitle(e.target.value)}
                required fullWidth
            />
            <Textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
                placeholder='Descreva com detalhes o assunto do contato'
                size="lg"
                variant="outlined"
                className="mt-3 mb-3"
                minRows={2}
            />
            {button}
        </Box>
    )
}