import {useState} from "react";
import JobManageList from "./Components/JobManageList";
import ContentContainer from "../../component/ContentContainer";
import {ApiUrl} from "../../auth/authMethods";

export default function JobManage() {

    const baseUrl = `${ApiUrl}/jobs`
    const [loadData, setLoadData] = useState(true)

    const [size, setSize] = useState(100)
    const [page, setPage] = useState(0)
    const [sort, setSort] = useState("")

    const [totalPages, setTotalPages] = useState(0)
    const [totalElements, setTotalElements] = useState(0)

    return <ContentContainer width='xl'>
        <h1>Gerenciamento de Jobs </h1>
        <JobManageList
            baseUrl={baseUrl}
            loadData={loadData}
            setLoadData={setLoadData}
            page={page}
            setPage={setPage}
            size={size}
            setSize={setSize}
            totalPages={totalPages}
            setTotalPages={setTotalPages}
            totalElements={totalElements}
            setTotalElements={setTotalElements}
            sort={sort} setSort={setSort}
        />
        <br/>
    </ContentContainer>
}