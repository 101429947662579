const validaCep = (cepRecebido) => {
    if (cepRecebido === undefined)
        return false
    cepRecebido = cepRecebido.replace(/[^0-9]/gi, "");
    return cepRecebido?.length === 8
}

export const buscarCep = async (cepRecebido) => {
    if (validaCep(cepRecebido)) {
        let url = `https://viacep.com.br/ws/${cepRecebido}/json/`

        // const resp = await fetch(url, {mode:'no-cors'})
        const resp = await fetch(url, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'content-type': 'aplication/json'
            }
        })
        return await resp.json()
    } else return null
}