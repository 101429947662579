import {numberWithPoints} from "../../../utils/numberWithPoints";

export default function PaymentsReport({data}) {

    const numTotal = data?.length
    const valueTotal = data?.reduce((partialSum, item) => partialSum + item.valor, 0)

    const styleTr = {
        textAlign: 'left',
    }

    return <>
        <div>
            <tr style={styleTr}>
                <th>Pagamentos: &nbsp;</th>
                <td>{numTotal}</td>
            </tr>
            <tr style={styleTr}>
                <th>Valor total: </th>
                <td>R$ {numberWithPoints(valueTotal)}</td>
            </tr>
        </div>
    </>
}