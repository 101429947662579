import React, {createContext, useContext, useState} from 'react';
import useStorage from "../utils/storage/useStorage";

// Crie um contexto para o token JWT
const UserContext = createContext();

// Crie um provedor de contexto para envolver seus componentes
export function AuthProvider({children}) {
    const {state: userData, set: setUserStorage, remove: removeUser} = useStorage('user');
    const [impersonatedUser, setImpersonatedUser] = useState(null);

    let user = userData
    try {
        user = JSON.parse(atob(userData))
    } catch (e) {
    }

    const setUserAndStorage = (userPassed) => {
        setUserStorage(btoa(JSON.stringify(userPassed)));
        setUser(userPassed);
    };

    const impersonateUser = (userToImpersonate) => {
        const impersonatedUserWithToken = {
            ...userToImpersonate,
            token: user.token,
            mainUser: user.username
        };
        setImpersonatedUser(impersonatedUserWithToken);
    };

    const setUser = (userPassed) => {
        setUserStorage(btoa(JSON.stringify(userPassed)))
    }
    const stopImpersonating = () => {
        setImpersonatedUser(null);
    };

    return (
        <UserContext.Provider value={{
            user: impersonatedUser || user,
            setUser: setUserAndStorage,
            removeUser,
            impersonateUser,
            stopImpersonating,
            isImpersonating: !!impersonatedUser
        }}>            {children}
        </UserContext.Provider>
    );
}

// Crie um hook personalizado para acessar o contexto do usuário
export function useUser() {
    const context = useContext(UserContext);

    if (!context) {
        throw new Error('useUser deve ser usado dentro de um UserProvider');
    }

    return context;
}
