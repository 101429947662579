import React, {useState} from "react";
import EventIcon from '@mui/icons-material/Event';
import PopupEdit from "../../../component/PopupEdit/PopupEdit";
import InputsEvent from "./InputsEvent";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";

export default function PopupEditEvent({item, baseUrl, setLoadData}) {

    const [nomeEvento, setNomeEvento] = useState(item.nomeEvento)
    const [dataInicio, setDataInicio] = useState(item.dataInicio)
    const [formaPagamento, setFormaPagamento] = useState(item.formaPagamento)
    const [status, setStatus] = useState(item.status)
    const [qtdParcelas, setQtdParcelas] = useState(item?.modalidadeParcelamento?.quantidadeParcelas)
    const [modalParcelamento, setModalParcelamento] = useState(item.modalidadeParcelamento)
    const [padraoParcelamentoId, setPadraoParcelamentoId] = useState(item?.padraoParcelamento?.id);

    const [usePadrao, setUsePadrao] = useState(!(item?.modalidadeParcelamento));

    const [errorMessage, setErrorMessage] = useState("")
    const [errorColumn, setErrorColumn] = useState("")

    const inputs = <InputsEvent
        nomeEvento={nomeEvento} setNomeEvento={setNomeEvento}
        formaPagamento={formaPagamento} setFormaPagamento={setFormaPagamento}
        status={status} setStatus={setStatus}
        dataInicio={dataInicio} setDataInicio={setDataInicio}
        modalParcelamento={modalParcelamento} setModalParcelamento={setModalParcelamento}
        qtdParcelas={qtdParcelas} setQtdParcelas={setQtdParcelas}
        padraoParcelamentoId={padraoParcelamentoId} setPadraoParcelamentoId={setPadraoParcelamentoId}
        usePadrao={usePadrao} setUsePadrao={setUsePadrao}
        setErrorMessage={setErrorMessage} errorMessage={errorMessage}
        setErrorColumn={setErrorColumn} errorColumn={errorColumn}
    />

    const icon = <EventIcon fontSize="large" id="icon-svg"/>
    const buttonText = <span>
        <DriveFileRenameOutlineIcon fontSize="medium"/> &nbsp;
        Editar Evento
    </span>

    const itemToState = () => {
        setErrorMessage(false)
        setErrorColumn(false)
        setNomeEvento(item.nomeEvento)
        setDataInicio(item.dataInicio)
        setFormaPagamento(item.formaPagamento)
        setStatus(item.status)
        setQtdParcelas(item?.modalidadeParcelamento?.quantidadeParcelas)
        setModalParcelamento(item.modalidadeParcelamento)
    }

    const returnData = () => {
        const parcelamento = usePadrao ?
            {padraoParcelamentoId} : {
                modalParcelamento: {
                    ...modalParcelamento,
                    qtdParcelas: qtdParcelas
                }
            }
        return {
            nomeEvento,
            formaPagamento,
            status,
            dataInicio,
            ...parcelamento
        }
    }

    return <PopupEdit
        baseUrl={baseUrl + `/${item.id}`} setLoadData={setLoadData}
        inputs={inputs} title={'Editar Evento'} icon={icon}
        returnData={returnData} itemToState={itemToState}
        setErrorMessage={setErrorMessage} setErrorColumn={setErrorColumn}
        buttonText={buttonText} buttonClass='dropdown-item'
    />
}