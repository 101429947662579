import FilterForm from "../../../component/FilterForm/FilterForm";
import React, {useState} from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

export default function FilterWriteOff({filter, setFilter, setLoadData, setPage, initialFilter}) {

    const [lgShow, setLgShow] = useState(false);
    const [idParcela, setIdParcela] = useState('');
    const [initDate, setInitDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const handlesSubmit = (e) => {

        e.preventDefault();
        let filterData = initialFilter
        if (idParcela)
            filterData.id = idParcela
        if (initDate)
            filterData.initDate = initDate + 'T00:00:00'
        if (endDate)
            filterData.endDate = endDate + 'T23:59:59'

        console.log(filterData)
        setFilter(filterData);
        setLgShow(false);
        setLoadData(true);
        setPage(0);
    }

    const clearFilter = () => {
        setIdParcela('')
        setInitDate('')
        setEndDate('')
        setFilter(initialFilter);
        setLgShow(false);
        setLoadData(true);
        setPage(0);
    }

    return <>
        <FilterForm
            handleSubmit={handlesSubmit} clearFilter={clearFilter}
            lgShow={lgShow} setLgShow={setLgShow}
            showSecondaryButton={Object.keys(filter).length !== 1}
        >

            <TextField
                label="Id da parcela"
                inputProps={{
                    min: 1
                }}
                type='number'
                value={idParcela}
                onChange={(e) => {
                    setIdParcela(e.target.value)
                }}
                fullWidth
            />

            <Box mb={0.5} pb={0.5}>
                Data de criação
            </Box>
            <TextField
                label="Criado a partir de"
                type="date"
                onChange={(e) => setInitDate(e.target.value)}
                value={initDate}
                size="large"
                InputLabelProps={{
                    style: {
                        background: 'white'
                    }
                }}
                inputProps={{
                    min: "2000-01-01",
                    max: "2100-12-31"
                }}
                sx={{width: '49%', marginRight: '2%'}}
            />
            <TextField
                label="Criado até"
                type="date"
                onChange={(e) => setEndDate(e.target.value)}
                value={endDate}
                size="large"
                InputLabelProps={{
                    style: {
                        background: 'white',
                        width: '50%',
                        textAlign: 'left'
                    }
                }}
                inputProps={{
                    min: "2000-01-01",
                    max: "2100-12-31"
                }}
                sx={{width: '49%'}}
            />

        </FilterForm>
    </>
}