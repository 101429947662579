import TextField from "@mui/material/TextField";
import React, {useEffect, useState} from "react";
import SelectInput from "../../../../component/SelectInput";
import {aliasRoles} from "../aliasRoles";
import applyRegex from "../../../../utils/applyRegex";
import displayAlertMsg from "../../../../component/AlertMsg/displayAlertMsg";
import {ApiUrl} from "../../../../auth/authMethods";
import InputsParticipante from "./InputsParticipante";
import InputsLiderDeGrupo from "./InputsLiderDeGrupo";

export default function InputsUsers({
                                        username, setUsername,
                                        errorColumn, errorMessage, email, setEmail,
                                        participante, setParticipante,
                                        lider, setLider,
                                        role, setRole, setBlockSubmit,
                                        taxaParceiroComercial, setTaxaParceiroComercial,
                                        setErrorMessage, setErrorColumn
                                    }) {

    const [roleList, setRoleList] = useState([])
    const [hideBankDetailsAndPix, setHideBankDetailsAndPix] = useState(false)

    useEffect(() => {

        const needParticipante = ['ROLE_USER', 'ROLE_PARCEIRO_COMERCIAL', 'ROLE_FUNDO']
        setParticipante(
            (needParticipante.includes(role?.name))?
                {} : null
        )

        setLider(
            (role?.name === 'ROLE_GROUP_LEADER')?
                {} : null
        )

        setTaxaParceiroComercial(
            (role?.name === 'ROLE_PARCEIRO_COMERCIAL' && typeof  setTaxaParceiroComercial === 'function')?
                {}: null
        )

    }, [role])

    return <>
        <TextField
            label="Username"
            inputProps={{
                maxLength: 25
            }}
            error={errorColumn === 'username'}
            helperText={errorColumn === 'username' ? errorMessage : ''}
            value={username}
            onChange={(e) => setUsername(applyRegex(e.target.value, /[^ ]+/))}
            required
            fullWidth
            autoFocus
        />
        <TextField
            label="Email"
            type={"email"}
            inputProps={{
                maxLength: 45
            }}
            error={errorColumn === 'email'}
            helperText={errorColumn === 'email' ? errorMessage : ''}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            fullWidth
        />
        <SelectInput
            url={ApiUrl + '/role?size=2000'}
            list={roleList} set={setRoleList}
            setObj={setRole}
            returnList={() => {
                let returnList = []
                roleList.forEach((item) => {
                    returnList.push({
                        value: item,
                        label: aliasRoles.filter(alias => alias.received === item.name)[0].display
                    })
                })
                return returnList
            }}
            placeholder={"Tipo de usuário"}
            required={true}
            fullWidth
        />

        {participante &&
            <InputsParticipante
                participante={participante}
                setParticipante={setParticipante}
                setBlockSubmit={setBlockSubmit}
                taxaParceiroComercial={taxaParceiroComercial}
                setTaxaParceiroComercial={setTaxaParceiroComercial}
                hideBankDetailsAndPix={hideBankDetailsAndPix}
                setHideBankDetailsAndPix={setHideBankDetailsAndPix}
            />
        }

        {lider &&
            <InputsLiderDeGrupo
                lider={lider}
                setLider={setLider}
                setBlockSubmit={setBlockSubmit}
                errorColumn={errorColumn}
                errorMessage={errorMessage}
            />
        }
        <br/>

        {errorMessage && displayAlertMsg(errorMessage)}
    </>
}

