import TextField from "@mui/material/TextField";
import Select from "react-select";
import React, {useEffect} from "react";
import {entradaTypeList} from "./entradaTypeList";
import CurrencyInput from "../../../component/CurrencyInput";
import DisplayManyInstallmentType from "./InputsAdd/DisplayManyInstallmentType";
import Box from "@mui/material/Box";

export default function InputsInstallmentPattern({
                                                     nome, setNome,
                                                     entrada, setEntrada,
                                                     tiposParcela, setTiposParcela,
                                                     errorMessage, setErrorMessage,
                                                     errorColumn, setErrorColumn
                                                 }) {

    return <>
        <TextField
            label="Nome do Padrão de Parcelamento"
            required={true}
            inputProps={{
                maxLength: 80
            }}
            errortext="Campo obrigatório!"
            error={errorColumn === "nome"}
            helperText={errorMessage}
            value={nome}
            onChange={(e) => setNome(e.target.value)}
            fullWidth
            autoFocus
        />
        <Select
            className="basic-single"
            classNamePrefix="select"
            id="form_select"
            required={true}
            defaultValue={entradaTypeList.filter(item => item.value === entrada)}
            isDisabled={false}
            isLoading={false}
            isClearable={false}
            isRtl={false}
            isSearchable={true}
            options={entradaTypeList}
            onChange={(e) => setEntrada(e.value)}
            placeholder={'Entrada *'}
        />

        <Box mt={2} component={'h5'}>
            Tipos de parcela
        </Box>
        <hr/>
        <DisplayManyInstallmentType
            obj={tiposParcela} setObj={setTiposParcela}
            name={'Tipo de parcela'} numeroLote={1}
        />
    </>
}