import './FileInput.css'
import {Input} from "@mui/material";
import React, {useEffect, useState} from "react";
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';

export default function FileInput({selectedFile, setSelectedFile, format}, multiple) {

    const [multipleDisplay, setMultipleDisplay] = useState("")

    const handleFileChange = (event) => {
        if (multiple) {
            setSelectedFile(event.target.files)
            setMultipleDisplay(() => {
                let list = []
                for (const file of event.target.files) {
                    list.push(file.name)
                }
                return list
            })
        } else
            setSelectedFile(event.target.files[0])
    };

    useEffect(() => {
        console.log(selectedFile)
    }, [selectedFile])

    return (
        <>
            <label id='div-input-file'>
                <span id='input-file'>

                    <CreateNewFolderIcon fontSize="medium"/>&nbsp;
                    Importar arquivo
                    <Input
                        type="file"
                        onChange={handleFileChange}
                        inputProps={{accept: format ? '.' + format : '*', multiple}}
                        hidden

                    />
                </span>

                {(!multiple && selectedFile) || (multiple && selectedFile.length > 0) ?
                    <p>
                        {multiple ?
                            <>Selecionado(s): <br/><br/>{multipleDisplay.map(fileName =>
                                <p style={{lineHeight: '.5vw'}}>{fileName}</p>
                            )}</> :
                            <>Selecionado: {selectedFile?.name}</>
                        }
                    </p> :
                    <p>
                        Nenhum arquivo selecionado
                    </p>
                }
            </label>
            <br/>
        </>
    )
}