import {ApiUrl} from "../../auth/authMethods";
import {useState} from "react";
import ContentContainer from "../../component/ContentContainer";
import PaymentList from "../Payment/Components/PaymentList";


export default function PaymentsToAprove() {

    const baseUrl = `${ApiUrl}/pagamentos`
    const [loadData, setLoadData] = useState(true)

    const [size, setSize] = useState(0)
    const [page, setPage] = useState(0)
    const [sort, setSort] = useState("")

    const [totalPages, setTotalPages] = useState(0)
    const [totalElements, setTotalElements] = useState(0)

    return <ContentContainer width={'xl'}>
        <h1>Lista de Aprovações Pendentes </h1>
        <PaymentList
            baseUrl={baseUrl + '/list/to-aprove'}
            loadData={loadData}
            setLoadData={setLoadData}
            page={page}
            setPage={setPage}
            size={size}
            setSize={setSize}
            totalPages={totalPages}
            setTotalPages={setTotalPages}
            totalElements={totalElements}
            setTotalElements={setTotalElements}
            sort={sort} setSort={setSort}
            displayReport
        />
        <br/>
    </ContentContainer>
}