import {useState} from "react";
import PaginationControl from "../../component/Pagination/PaginationControl";
import BoletoList from "./Components/BoletoList";
import ContentContainer from "../../component/ContentContainer";
import {ApiUrl} from "../../auth/authMethods";
import {useUser} from "../../auth/AuthProvider";
import {useParams} from "react-router-dom";
import {switchClasses} from "@mui/material";

export default function Boleto() {

    const {user} = useUser()

    const baseUrl = `${ApiUrl}/boletos`
    const [loadData, setLoadData] = useState(true)

    const {type} = useParams();
    const filterDefault = !type ?
        {} :
        type === 'atrasados' ?
            {status: 'VENCIDO'} :
            type === 'pagos' ?
                {status: 'PAGO'} :
                type === 'pendentes' ?
                    {status: 'CRIADO'} :
                    {}

    const [size, setSize] = useState(10)
    const [page, setPage] = useState(0)
    const [sort, setSort] = useState(!user.isAdmin ? 'dataExpiracao' : '')

    const [totalPages, setTotalPages] = useState(0)
    const [totalElements, setTotalElements] = useState(0)

    const label = () => {
        switch (type) {
            case 'pagos':
                return 'Pagos'
            case 'atrasados':
                return 'Atrasados'
            case 'pendentes':
                return 'Pendentes'
            default:
                return ''
        }
    }

    return <ContentContainer width={'xl'}>
        <h1>Lista de Boletos {label()}</h1>
        <BoletoList
            baseUrl={baseUrl}
            loadData={loadData}
            setLoadData={setLoadData}
            page={page}
            setPage={setPage}
            size={size}
            setSize={setSize}
            totalPages={totalPages}
            setTotalPages={setTotalPages}
            totalElements={totalElements}
            setTotalElements={setTotalElements}
            sort={sort} setSort={setSort}
            filterDefault={filterDefault}
            type={type}
        />
        <PaginationControl
            totalElements={totalElements}
            page={page}
            setPage={setPage}
            size={size}
            setSize={setSize}
            setLoadData={setLoadData}
        />
    </ContentContainer>
}