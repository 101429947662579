import React, {useState} from "react";
import Popup from "../../../component/Popup/Popup";
import DisplayDetailsData from "../../../component/DisplayDetailsData/DisplayDetailsData";
import {normalizeTimestemp} from "../../../utils/normalizeTimestemp";
import {numberWithPoints} from "../../../utils/numberWithPoints";

import PersonIcon from "@mui/icons-material/Person";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AlarmIcon from '@mui/icons-material/Alarm';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import BadgeIcon from "@mui/icons-material/Badge";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import RoomIcon from "@mui/icons-material/Room";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import StoreIcon from "@mui/icons-material/Store";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import NumbersIcon from "@mui/icons-material/Numbers";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Filter1Icon from '@mui/icons-material/Filter1';
import EventNoteIcon from '@mui/icons-material/EventNote';
import BallotIcon from '@mui/icons-material/Ballot';

export default function PopupPaymentDetails({item}){
    const [lgShow, setLgShow] = useState(false);

    const button = <>
        <VisibilityIcon fontSize="medium"/>
        &nbsp; Detalhes do Pagamento
    </>

    const title = "Detalhes do Pagamento"

    const iconSpan = <>
        <VisibilityIcon fontSize="large" id="icon-svg"/>
        {title}
    </>

    const part = item.participante

    const handleEndereco = (enderecoObj) => {
        return `${enderecoObj.logradouro}, ${enderecoObj.numero} ${enderecoObj.complemento? '- ' + enderecoObj.complemento : ''}, ${enderecoObj.bairro}, ${enderecoObj.cidade} - ${enderecoObj.estado}, ${enderecoObj.cep}`;
    }

    return (
        <Popup
            buttonText={button}
            title={title}
            iconSpan={iconSpan}
            lgShow={lgShow} setLgShow={setLgShow}
            buttonClass='dropdown-item'
        >
            {/* Dados primários */}
            <DisplayDetailsData
                key="primaryData"
                title='Dados Primários'
                itens={[
                    {
                        icon: <AttachMoneyIcon key="item1"/>,
                        display: `Valor:  R$ ${numberWithPoints(item.valor)}`
                    },
                    {
                        icon: <AlarmIcon key="item1"/>,
                        display: `Data de agendamento:  ${normalizeTimestemp(item.scheduledDate, true)}`
                    },
                    {
                        icon: <EventAvailableIcon key="item2"/>,
                        display: `Data efetiva do pagamento:  ${item.effectivePaymentDate? 
                            normalizeTimestemp(item.effectivePaymentDate):
                            'Pagamento ainda não efetuado'
                        }`
                    },
                    {
                        icon: <AutorenewIcon key="item2"/>,
                        display: `Status do pagamento:  ${item.status}`
                    },
                ]}
                openDefault={true}
            />
            {/* Dados de Participante */}
            {part &&
                <DisplayDetailsData
                    key="participant" // Adicione uma chave única aqui
                    title='Dados de Participante'
                    itens={[
                        {
                            icon: <PersonIcon key="item1"/>,
                            display: `Nome:  ${part.nome}`
                        },
                        {
                            icon: <BadgeIcon key="item7"/>,
                            display: `Documento:  ${part?.documento}`
                        },
                        {
                            icon: <MonetizationOnIcon key="item8"/>,
                            display: `Chave Pix:  ${part.dadosPix.tipoChave} - ${part.dadosPix.chavePix}`
                        },
                        {
                            icon: <RoomIcon key="item9"/>,
                            display: `Endereço:  ${handleEndereco(part.endereco)}`
                        }
                    ]}
                />
            }
            {/* Dados Bancários */}
            {part?.dadosBancarios &&
                <DisplayDetailsData
                    key="bankDetails"
                    title='Dados Bancários'
                    itens={[
                        {
                            icon: <PersonIcon key="item9"/>,
                            display: `Nome do Titular: ${part.dadosBancarios.nome}`
                        },
                        {
                            icon: <AccountBalanceIcon key="item1"/>,
                            display: `Banco:  ${part.dadosBancarios.banco}`
                        },
                        {
                            icon: <AccountBalanceWalletIcon key="item7"/>,
                            display: `Conta:  ${part.dadosBancarios.numero}`
                        },
                        {
                            icon: <StoreIcon key="item7"/>,
                            display: `Agência:  ${part.dadosBancarios.agencia}`
                        },
                        {
                            icon: <BadgeIcon key="item7"/>,
                            display: `Tipo: Pessoa ${part.dadosBancarios.tipoPessoa === 'F' ? 'Física' : 'Jurídica'}`
                        },
                        {
                            icon: <FormatAlignJustifyIcon key="item7"/>,
                            display: `Tipo de Conta:  ${part.dadosBancarios.tipoConta}`
                        },
                        {
                            icon: <NumbersIcon key="item7"/>,
                            display: `TaxId:  ${part.dadosBancarios.taxId}`
                        }
                    ]}
                />
            }

            {/* Parcelas */}
            {item.parcelas.map((parcela, index)=> <>
                <DisplayDetailsData
                    key={index}
                    title={`Item ${index + 1} do pagamento`}
                    itens={[
                        {
                            icon: <Filter1Icon key="item9"/>,
                            display: `Número da parcela: ${parcela.numero}`
                        },
                        {
                            icon: <BallotIcon key="item1"/>,
                            display: `Lote:  ${parcela.pedido.numero}`
                        },
                        {
                            icon: <EventNoteIcon key="item7"/>,
                            display: `Leilão:  ${parcela.pedido.evento.nomeEvento}`
                        },
                        {
                            icon: <AttachMoneyIcon key="item7"/>,
                            display: `Valor Original: R$ ${numberWithPoints(parcela.valor)}`
                        },
                        {
                            icon: <AttachMoneyIcon key="item7"/>,
                            display: `Valor Pago: R$ ${numberWithPoints(parcela.valorPago)}`
                        },
                        {
                            icon: <PersonIcon key="item7"/>,
                            display: `Comprador: ${parcela.participante.nome}`
                        },
                    ]}
                />
            </>)}
        </Popup>
    )
}

