import {Dropdown} from "react-bootstrap";
import SettingsIcon from "@mui/icons-material/Settings";
import React from "react";
import PopupEditAntecipacao from "./PopupEditAntecipacao";
import PopupDeleteAntecipacao from "./PopupDeleteAntecipacao";

export default function AntecipacaoActions({item, setLoadData, baseUrl}) {

    return <Dropdown drop='down-centered'>
        <Dropdown.Toggle variant="secondary">
            Ações &nbsp;
            <SettingsIcon fontSize="medium"/>
        </Dropdown.Toggle>

        <Dropdown.Menu>
            <PopupEditAntecipacao item={item} setLoadData={setLoadData} baseUrl={baseUrl}/>
            <PopupDeleteAntecipacao item={item} setLoadData={setLoadData} baseUrl={baseUrl}/>
        </Dropdown.Menu>

    </Dropdown>
}