import React, {useState} from "react";
import DisplayDetailsData from "../../../component/DisplayDetailsData/DisplayDetailsData";
import Popup from "../../../component/Popup/Popup";
import {normalizeTimestemp} from "../../../utils/normalizeTimestemp";
import List from "@mui/material/List";
import {statusList} from "./statusList";

import VisibilityIcon from "@mui/icons-material/Visibility";
import EventIcon from '@mui/icons-material/Event';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import Filter1Icon from '@mui/icons-material/Filter1';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import {entradaTypeList} from "../../InstallmentPattern/Components/entradaTypeList";
import {tipoParcelaList} from "../../InstallmentPattern/Components/InputsAdd/tipoParcelaList";

export default function EventDetails({item}) {

    const modalParc = item?.modalidadeParcelamento
    const padraoParc = item?.padraoParcelamento

    const [lgShow, setLgShow] = useState(false);

    const button = <>
        <VisibilityIcon fontSize="medium"/>
        &nbsp; Detalhes do Evento
    </>

    const title = "Detalhes do Evento"

    const iconSpan = <>
        <VisibilityIcon fontSize="large" id="icon-svg"/>
        {title}
    </>


    return (
        <Popup
            buttonText={button}
            title={title}
            iconSpan={iconSpan}
            lgShow={lgShow} setLgShow={setLgShow}
            buttonClass='dropdown-item'
        >
            <List
                sx={{width: '80%', bgcolor: 'background.paper', marginInline: '10%'}}
                component="nav"
                aria-labelledby="nested-list-subheader"
                className='pointer-event'
            >
                {/* Dados principais */}
                <DisplayDetailsData
                    key="primaryData"
                    title='Dados principais'
                    itens={[
                        {
                            icon: <DriveFileRenameOutlineIcon key="item1"/>,
                            display: `Nome do Evento:  ${item.nomeEvento}`
                        },
                        {
                            icon: <EventIcon key="item2"/>,
                            display: `Data de Inicio:  ${normalizeTimestemp(item.dataInicio)}`
                        },
                        {
                            icon: <PointOfSaleIcon key="item2"/>,
                            display: `Forma de Pagamento:  ${item.formaPagamento}`
                        },
                        {
                            icon: <LabelImportantIcon key="item2"/>,
                            display: `Status:  ${statusList.filter(stt => stt.value === item.status)[0].label}`
                        },
                    ]}
                />
                {/* Modalidade de pagamento */}
                {modalParc &&
                    <DisplayDetailsData
                        key="secondData" // Adicione uma chave única aqui
                        title='Modalidade de Pagamento'
                        itens={[
                            {
                                icon: <Filter1Icon key="item2"/>,
                                display: `Quantidade de Parcelas:  ${modalParc.quantidadeParcelas}`
                            },
                            {
                                icon: <EventIcon/>,
                                display: `Dia do Vencimento:  ${modalParc.diaDoMes}`
                            },
                            {
                                icon: <Filter1Icon/>,
                                display: `Aglutinações:  ${modalParc.aglutinacao === 0 ? 'Nenhuma' : modalParc.quantidadeAglutinacoes + ' aglutinações de ' + modalParc.aglutinacao + ' parcelas'}`
                            },
                            {
                                icon: <EventIcon key="item2"/>,
                                display: `Data de Vencimento:  ${normalizeTimestemp(modalParc.dataVencimento)}`
                            },
                        ]}
                    />
                }
                {/* Padrao de Parcelamento */}
                {padraoParc &&
                    <DisplayDetailsData
                        key="secondData" // Adicione uma chave única aqui
                        title='Padrão de Parcelamento'
                        itens={[
                            {
                                icon: <DriveFileRenameOutlineIcon key="item1"/>,
                                display: `Nome:  ${padraoParc.nome}`
                            },
                            {
                                icon: <AttachMoneyIcon key="item2"/>,
                                display: `Entrada:  ${entradaTypeList.filter(ent => ent.value === padraoParc.entrada)[0].label}`
                            },
                            ...(padraoParc.tiposParcela
                                .sort(parc => parc.index)
                                .map(parc => {

                                    return {
                                        icon: <LabelImportantIcon key="item2"/>,
                                        display: `${parc.quantidade} parcela(S):  
                                        ${tipoParcelaList.filter(type => type.value === parc.multiplicidade)[0].labelDetails}   
                                    `
                                    }
                                }))
                        ]}
                    />
                }


            </List>
        </Popup>
    )
}