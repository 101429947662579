import React, {useEffect, useState} from "react";
import axios from "axios";
import Popup from "../../../component/Popup/Popup";

import {
    Button,
    Divider,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import DownloadIcon from '@mui/icons-material/Download';
import {ApiUrl} from "../../../auth/authMethods";
import AlertPopup from "../../../component/AlertPopup";
import LoadingButton from "@mui/lab/LoadingButton";
import BasicLoader from "../../../component/BasicLoader/BasicLoader";

export default function AddContrato({pedidoId}) {

    const [lgShow, setLgShow] = useState(false);

    const display = 'Salvar/Baixar Contrato';

    const button = <>
        <SaveIcon fontSize="medium"/> &nbsp; {display}
    </>;

    const title = "Salvar/Baixar contratos e demais evidências";

    const iconSpan = <>
        <SaveIcon fontSize="large" id="icon-svg"/>
        <h3>{title}</h3>
    </>;

    const [files, setFiles] = useState([]);
    const [existingFiles, setExistingFiles] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [titlePopup, setTitlePopup] = useState('');
    const [message, setMessage] = useState('');

    const [isLoading, setIsLoading] = useState(true);

    const [isLoadingUpload, setIsLoadingUpload] = useState(false);
    const [isLoadingRemove, setIsLoadingRemove] = useState([]);
    const [isLoadingDownload, setIsLoadingDownload] = useState([]);

    useEffect(() => {
        // Fetch existing files for the given pedidoId
        axios.get(`${ApiUrl}/arquivoPedido/pedido/${pedidoId}`)
            .then(response => {
                setExistingFiles(response.data);
            })
            .catch(error => {
                console.error("There was an error fetching the files!", error);
            })
            .finally(() => {
                setIsLoading(false)
            });
    }, [pedidoId]);

    const handleAddFiles = (event) => {
        const selectedFiles = Array.from(event.target.files);
        if (selectedFiles.length > 5) {
            setTitlePopup('Limite de arquivos')
            setMessage('Você pode adicionar no máximo 5 arquivos por vez.')
            setShowAlert(true)
        } else {
            setFiles([...files, ...selectedFiles]);
        }
    };

    const handleRemoveFile = (index) => {
        setFiles(files.filter((_, i) => i !== index));
    };

    const handleRemoveExistingFile = (fileId) => {
        setIsLoadingRemove(true)

        setIsLoadingRemove([...isLoadingRemove, fileId])
        axios.delete(`${ApiUrl}/arquivoPedido/${fileId}`)
            .then(response => {
                setExistingFiles(existingFiles.filter(file => file.id !== fileId));
            })
            .catch(error => {
                console.error("There was an error deleting the file!", error);
            })
            .finally(() => {
                setIsLoadingRemove(isLoadingRemove.splice(isLoadingRemove.indexOf(fileId), 1))
            });
    };

    const handleDownloadFile = (file) => {
        const byteCharacters = atob(file.data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: 'application/octet-stream'});
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file.fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleUploadFiles = () => {
        const formData = new FormData();
        files.forEach(file => {
            formData.append('files', file);
        });
        formData.append('pedidoId', pedidoId);

        setIsLoadingUpload(true)
        axios.post(`${ApiUrl}/arquivoPedido/upload`, formData)
            .then(response => {
                setExistingFiles([...existingFiles, ...response.data]);
                setFiles([]);
                // setLgShow(false);
            })
            .catch(error => {
                console.error("There was an error uploading the files!", error);
            })
            .finally(() => {
                setIsLoadingUpload(false)
            });
    };

    return (
        <>
            <Popup
                buttonText={button}
                title={title}
                iconSpan={iconSpan}
                lgShow={lgShow} setLgShow={setLgShow}
                buttonClass={'dropdown-item'}
            >
                <Typography variant="h6" gutterBottom>
                    Documentos Anexados
                </Typography>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Nome do Documento</TableCell>
                                <TableCell>Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(isLoading) ?

                                <tr >
                                    <td colSpan={2}>
                                        <BasicLoader message='Buscando arquivos...' height={10}/>
                                    </td>
                                </tr>:
                                <>
                                    {existingFiles.map((file) => (
                                        <TableRow key={file.id}>
                                            <TableCell>{file.fileName}</TableCell>
                                            <TableCell>
                                                <LoadingButton
                                                    title='Fazer download do arquivo'
                                                    loading={isLoadingDownload.find(id => id === file.id)}
                                                    onClick={() => handleDownloadFile(file)}
                                                    variant="text"
                                                >
                                                    <DownloadIcon/>
                                                </LoadingButton>
                                                <LoadingButton
                                                    title='Excluir arquivo'
                                                    loading={isLoadingRemove.find(id => id === file.id)}
                                                    onClick={() => handleRemoveExistingFile(file.id)}
                                                    variant="text"
                                                    color='error'
                                                >
                                                    <DeleteIcon/>
                                                </LoadingButton>
                                                {/*<IconButton onClick={() => handleDownloadFile(file)} aria-label="download file">*/}
                                                {/*    <DownloadIcon/>*/}
                                                {/*</IconButton>*/}
                                                {/*<IconButton onClick={() => handleRemoveExistingFile(file.id)}*/}
                                                {/*            aria-label="remove file">*/}
                                                {/*    <DeleteIcon/>*/}
                                                {/*</IconButton>*/}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <br/>
                <Divider/>
                <Typography variant="h6" gutterBottom>
                    Novos Documentos
                </Typography>
                <ul>
                    {files.map((file, index) => (
                        <li key={index}>
                            {file.name}
                            <IconButton onClick={() => handleRemoveFile(index)} aria-label="remove file">
                                <DeleteIcon/>
                            </IconButton>
                        </li>
                    ))}
                </ul>
                <input
                    accept="image/*,application/pdf"
                    style={{display: 'none'}}
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={handleAddFiles}
                />
                <label htmlFor="contained-button-file">
                    <Button variant="contained" color="primary" component="span" style={{marginRight: '10px'}}>
                        Adicionar Arquivos
                    </Button>
                </label>
                {/*<Button*/}
                {/*    variant="contained"*/}
                {/*    color="primary"*/}
                {/*    disabled={files.length === 0}*/}
                {/*    onClick={handleUploadFiles}*/}
                {/*>*/}
                {/*    Enviar*/}
                {/*</Button>*/}
                <Button
                    variant="contained"
                    color={(isLoadingUpload || files.length === 0) ? "secondary" : "primary"}
                    onClick={handleUploadFiles}
                    disabled={isLoadingUpload || files.length === 0}
                    // className={(isLoadingUpload || files.length === 0) ? "btn btn-secondary" : ""}
                >
                    {isLoadingUpload ? (
                        <>
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                            &nbsp;Enviando...
                        </>
                    ) : (
                        "Enviar"
                    )}
                </Button>
            </Popup>
            <AlertPopup
                type="alert"
                title={titlePopup}
                message={message}
                showPopup={showAlert}
                setShowPopup={setShowAlert}
                onConfirm={() => console.log("Alerta confirmado!")}
            />
        </>
    );
}
