import React, {useState} from "react";
import InputsUsers from "./inputs/InputsUsers";
import PopupAdd from "../../../component/PopupAdd/PopupAdd";
import PeopleIcon from '@mui/icons-material/People';


export default function PopupAddUser({baseUrl, setLoadData}) {

    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState([])
    const [participante, setParticipante] = useState(null)
    const [lider, setLider] = useState(null)
    const [taxaParceiroComercial, setTaxaParceiroComercial] = useState(null)

    const [errorMessage, setErrorMessage] = useState("")
    const [errorColumn, setErrorColumn] = useState("")
    const [blockSubmit, setBlockSubmit] = useState(false)

    const inputs = <InputsUsers
        username={username} setUsername={setUsername}
        email={email} setEmail={setEmail}
        role={role} setRole={setRole}
        participante={participante} setParticipante={setParticipante}
        lider={lider} setLider={setLider}
        errorMessage={errorMessage} errorColumn={errorColumn}
        setBlockSubmit={setBlockSubmit}
        taxaParceiroComercial={taxaParceiroComercial}
        setTaxaParceiroComercial={setTaxaParceiroComercial}
    />

    const icon = <PeopleIcon fontSize="large" id="icon-svg"/>

    const clearForm = () => {
        setErrorMessage(false)
        setErrorColumn(false)
        setUsername('')
        setEmail('')
        setRole('')
    }

    const returnData = () => {
        return {
            username,
            email,
            participante,
            lider,
            roles: [role],
            isEnabled: true,
            isCredentialsNonExpired: true,
            isAccountNonExpired: true,
            isAccountNonLocked: true,
            isTwoFactorEnabled: true
        }
    }
    return <PopupAdd
        baseUrl={baseUrl + '/auth/register'} setLoadData={setLoadData}
        inputs={inputs} title={'Adicionar Usuários'} icon={icon}
        returnData={returnData}
        clearForm={clearForm} username={username} email={email} roles={role}
        setErrorMessage={setErrorMessage} setErrorColumn={setErrorColumn} blockSubmit={blockSubmit}
    />
}