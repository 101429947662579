import {useState} from "react";
import TableList from "../../../component/TableList/TableList";
import FilterAnimal from "./FilterAnimal";
import {genderList, quantityList} from "./lists";
import PopupEditAnimal from "./PopupEditAnimal";
import PopupDeleteAnimal from "./PopupDeleteAnimal";

export default function AnimalList({
                                       baseUrl, loadData,
                                       setLoadData, size, page,
                                       setTotalPages, setTotalElements,
                                       sort, setSort, updateCode, setPage
                                   }) {

    const [data, setData] = useState([])
    const [error, setError] = useState(false)
    const [filter, setFilter] = useState({})

    const titles = [
        'Número do Lote',
        'Nome',
        'Gênero',
        'Quantidade'
    ]

    return (
        <>
            <FilterAnimal filter={filter} setFilter={setFilter} setLoadData={setLoadData} setPage={setPage}/>
            {/*<PopupAddAnimal baseUrl={baseUrl} setLoadData={setLoadData}/>*/}
            <TableList
                titles={titles} baseUrl={baseUrl + '/list'}
                page={page} sort={sort} size={size}
                loadData={loadData}
                setTotalPages={setTotalPages} setTotalElements={setTotalElements}
                setData={setData} setLoadData={setLoadData} filter={filter}
                error={error} setError={setError}
            >
                {data.map((item) =>
                    <tr key={item.id}>
                        <td>{item.numeroLote}</td>
                        <td>{item.nomeAnimal}</td>
                        <td>{genderList.filter(qtt => qtt.value === item.generoAnimal)[0].label}</td>
                        <td>{quantityList.filter(qtt => qtt.value === item.quantidade)[0].label}</td>
                        <td>
                            <PopupEditAnimal item={item} baseUrl={baseUrl} setLoadData={setLoadData}/>
                        </td>
                        <td>
                            <PopupDeleteAnimal item={item} baseUrl={baseUrl} setLoadData={setLoadData}/>
                        </td>
                    </tr>
                )}
            </TableList>
        </>
    )
}