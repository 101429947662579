import PopupDelete from "../../../component/PopupDelete/PopupDelete";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import React from "react";
import {numberWithPoints} from "../../../utils/numberWithPoints";

export default function PopupDeleteOrder({
                                             baseUrl,
                                              setLoadData,
                                              item
                                          }) {
    const buttonText = <span>
        <DeleteForeverIcon fontSize="medium"/>
        &nbsp; Excluir Lote
    </span>

    return (
        <PopupDelete
            baseUrl={baseUrl} setLoadData={setLoadData}
            title={"Confirmar Exclusão de Lote"} id={item.id}
            buttonClass='dropdown-item text-danger' buttonText={buttonText}
        >

            <div className="mt-3 mb-3">
                <b>Número do lote: </b>
                <>{item.numero}</><br/>

                <b>Evento: </b>
                <>{item.evento.nomeEvento}</><br/>

                <b>Valor: </b>
                <>R$ {numberWithPoints(item.valor)}</><br/>
            </div>

            <p className="mb-3">Você deseja realmente excluir este registro?</p>
            <p className="mb-3">
                Isso irá <b>cancelar todos os boletos</b> não pagos no banco
                e marcar todas as parcelas não pagas como 'CANCELADA'!
            </p>

        </PopupDelete>
    )
}