import axios from "axios";
import {useUser} from "../../../auth/AuthProvider";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Popup from "../../../component/Popup/Popup";
import React, {useEffect, useState} from "react";
import AlertMsg from "../../../component/AlertMsg/AlertMsg";
import DefaultSuccessMessage from "../../../component/DefaultSuccessMessage/DefaultSuccessMessage";
import BasicLoader from "../../../component/BasicLoader/BasicLoader";


export default function TriggerJob({item, setLoadData, baseUrl}) {

    const {user} = useUser()

    const [lgShow, setLgShow] = useState(false)
    const [confirm, setConfirm] = useState(false)
    const [running, setRunning] = useState(false)
    const [error, setError] = useState(false)

    const title = 'Disparar processamento'

    const button = <span title='Disparar processamento'>
        <PlayArrowIcon fontSize='medium' style={{cursor: 'pointer'}}/>
    </span>

    const iconSpan = <>
        <PlayArrowIcon fontSize="large" id="icon-svg"/> <br/>
        <h3>{title}</h3>
    </>

    useEffect(() => {
        if (!lgShow){
            setConfirm(false)
            setRunning(false)
            setError(false)
        }
    }, [lgShow])


    const triggerJob = () => {

        setConfirm(true)
        setRunning(true)

        const headers = {
            'Authorization': `Bearer ${user?.token}`
        }

        axios.get(baseUrl + '/trigger-job?jobId=' +  item.id, {headers})
            .then(function (response) {
                setRunning(false)
            })
            .catch(function (errorReceived) {
                setError(true)
            }).finally(() => {
            setRunning(false)
        })
    }

    return <Popup
        title={title}
        iconSpan={iconSpan}
        buttonText={button}
        lgShow={lgShow}
        setLgShow={setLgShow}
        backdrop={'static'}
        buttonClass='btn btn-outline-secondary'
    >
        {!confirm ?
            <p className='text-center mt-5'>
                <h5>Deseja mesmo disparar o job '{item.nome}'?</h5>
                <button className='btn btn-primary' onClick={triggerJob}>
                    Sim
                </button>
                &nbsp;
                <button className='btn btn-outline-secondary' onClick={() => setLgShow(false)}>
                    Não
                </button>
            </p> :
            <>
                {error ?
                    <AlertMsg message='Ocorreu um problema durante a execução do job, verifique
                        o log da aplicação para ver detalhes.'/> :
                    <>
                        {!running ?
                            <DefaultSuccessMessage message={'O job foi executado com sucesso!'}/>
                            :
                            <BasicLoader height={20}
                                         message="O job foi disparado e está sendo executado.
                                     Acompanhe o log da aplicação para ver detalhes."
                            />
                        }
                    </>
                }
            </>
        }
    </Popup>
}