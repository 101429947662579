import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";

export default function InputsLiderDeGrupo({
                                               lider, setLider,
                                               errorColumn, errorMessage,
                                               setBlockSubmit
                                           }) {

    const lid = lider;

    const [nome, setNome] = useState(lid?.nome || '');
    const [emailMembro, setEmailMembro] = useState('');
    // Inicialize membros com uma lista de objetos se lid?.membros não estiver definido
    const [membros, setMembros] = useState(
        lid?.membros?.map(membro => ({ email: membro })) || []
    );

    useEffect(() => {
        setLider(prevData => ({
            ...prevData,
            nome,
            membros
        }));
    }, [nome, membros]);

    useEffect(() => {
        setBlockSubmit(membros.length === 0);
    }, [membros, setBlockSubmit]);

    const handleAddMembro = () => {
        if (emailMembro && !membros.some(membro => membro.email === emailMembro)) {
            setMembros(prevMembros => [...prevMembros, { email: emailMembro }]);
            setEmailMembro(''); // Limpa o campo de email após adicionar
        }
    }

    const handleRemoveMembro = (index) => {
        setMembros(prevMembros => prevMembros.filter((_, i) => i !== index));
    }

    return (
        <>
            <Box mt={2} component={'h5'}>
                Nome e membros do grupo
            </Box>
            <hr/>
            <TextField
                label="Nome do Grupo"
                value={nome}
                inputProps={{
                    maxLength: 100,
                }}
                onChange={(e) => setNome(e.target.value)}
                required
                fullWidth
                InputLabelProps={{
                    style: {
                        zIndex: 0,
                    },
                }}
            />

            <div style={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                    label="Email do Membro"
                    type="email"
                    inputProps={{
                        maxLength: 45
                    }}
                    error={errorColumn === 'emailMembro'}
                    helperText={errorColumn === 'emailMembro' ? errorMessage : ''}
                    value={emailMembro}
                    onChange={(e) => setEmailMembro(e.target.value)}
                    fullWidth
                />
                <IconButton
                    color="primary"
                    onClick={handleAddMembro}
                    aria-label="adicionar membro"
                    style={{ marginLeft: 8, marginBottom: 18 }}
                >
                    <AddIcon />
                </IconButton>
            </div>

            <Box>
                <List>
                    {membros.map((membro, index) => (
                        <ListItem
                            key={index}
                            sx={{
                                backgroundColor: index % 2 === 0 ? '#f5f5f5' : '#e0e0e0',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <ListItemText
                                primary={index + 1}
                            />
                            <ListItemText
                                primary={membro.email}
                                sx={{ textAlign: 'center' }}
                            />
                            <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => handleRemoveMembro(index)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </>
    );
}
