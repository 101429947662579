import React from "react";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {handleChangeData} from "../../../utils/handleChangeData";
import {Button, IconButton} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadFileIcon from '@mui/icons-material/UploadFile';

export default function InputsBoleto({
                                         dueDate, setDueDate,
                                         files, setFiles
                                     }) {

    const handleAddFiles = (event) => {
        const selectedFiles = Array.from(event.target.files);
        setFiles([...files, ...selectedFiles]);
    };

    const handleRemoveFile = (index) => {
        setFiles(files.filter((_, i) => i !== index));
    };

    return <>


        <DatePicker
            label="Nova Data de Vencimento"
            sx={{
                width: '100%'
            }}
            defaultValue={dueDate ? dayjs(dueDate) : null}
            onChange={(e) => handleChangeData(e, setDueDate)}
            slotProps={{
                textField: {
                    required: true
                },
            }}
        />


        <div>
            <input
                accept="image/*,application/pdf"
                style={{display: 'none'}}
                id="contained-button-file"
                multiple
                type="file"
                onChange={handleAddFiles}
            />
            <label htmlFor="contained-button-file">
                <Button variant="contained" color="primary" component="span" style={{marginRight: '10px'}}>
                    <UploadFileIcon/>&nbsp;&nbsp;Adicionar Evidências
                </Button>
            </label>

            <ul style={{listStyle: 'none'}}>
                {files.map((file, index) => (
                    <li key={index}>
                        {file.name}
                        <IconButton onClick={() => handleRemoveFile(index)} aria-label="remove file">
                            <DeleteIcon/>
                        </IconButton>
                    </li>
                ))}
            </ul>
        </div>

    </>
}