import "./TableList.css"
import Table from "@mui/material/Table";
import React, {useEffect} from "react";
import axios from "axios";
import BasicLoader from "../BasicLoader/BasicLoader";
import DefaultErrorMsg from "../DefaultErrorMsg/DefaultErrorMsg";
import {useUser} from "../../auth/AuthProvider";

const TableList = ({
                       titles, children,
                       loadData, baseUrl,
                       page, size, sort,
                       setTotalPages, setTotalElements,
                       setData, setLoadData, filter,
                       error, setError,
                       readData, isFilterByUser = false
                   }) => {

    const {user} = useUser()
    const userId = user?.id

    useEffect(() => {

        if (loadData) {

            let params = null
            if(isFilterByUser){
                params = {userId, page, size, sort: sort ? sort : 'id,desc'}
            } else {
                params = {page, size, sort: sort ? sort : 'id,desc'}
            }

            if (filter)
                params = Object.assign({}, params, filter)

            axios.defaults.headers.common['Authorization'] = `Bearer ${user?.token}`;

            axios.get(baseUrl, {params})
                .then(function (response) {
                    console.log("searching data")
                    setTotalPages(response.data.totalPages)
                    setTotalElements(response.data.totalElements)
                    if (readData)
                        setData(readData(response))
                    else
                        setData(response.data.content)
                    // console.log(response.data.content)
                    setError(false)
                    setLoadData(false)
                }).catch(function (errorReceived) {
                // aqui temos acesso ao erro, quando alguma coisa inesperada acontece:
                console.log(errorReceived);
                setLoadData(false)
                setError(true)
            })
        }

    }, [loadData]);


    return (
        <>
            {loadData ?
                <BasicLoader/> :
                error ?
                    <DefaultErrorMsg/> :
                    <Table id='table-list'>
                        <thead>
                        <tr>
                            {titles.map((title, index) =>
                                <th key={index}>{title}</th>
                            )}
                        </tr>
                        </thead>
                        <tbody>
                        {children}
                        </tbody>
                    </Table>
            }
        </>
    )
}

export default TableList