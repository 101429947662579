import TextField from "@mui/material/TextField";
import Select from "react-select";
import React, {useState} from "react";
import {statusList} from "./statusList";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {handleChangeData} from "../../../utils/handleChangeData";
import InputsInstallment from "../../Order/Components/InputsAdd/InputsInstallment";
import Box from "@mui/material/Box";
import applyRegex from "../../../utils/applyRegex";
import Typography from "@mui/material/Typography";
import {Switch} from "@mui/material";
import SelectInput from "../../../component/SelectInput";
import {ApiUrl} from "../../../auth/authMethods";

export default function InputsEvent({
                                        nomeEvento, setNomeEvento,
                                        formaPagamento, setFormaPagamento,
                                        status, setStatus,
                                        dataInicio, setDataInicio,
                                        modalParcelamento, setModalParcelamento,
                                        qtdParcelas, setQtdParcelas,
                                        padraoParcelamentoId, setPadraoParcelamentoId,
                                        usePadrao, setUsePadrao,
                                        errorMessage, setErrorMessage,
                                        errorColumn, setErrorColumn
                                    }) {
    const InputLabelProps = {
        style: {
            zIndex: 0
        },
    }

    const [padraoParcList, setPadraoParcList] = useState([]);

    const handleChangeNumber = (set, value) => {
        const intValue = parseInt(applyRegex(value.replace('e', ''), /\d+/))
        set(intValue ? intValue : '')
    }

    return <>
        <TextField
            label="Nome do Evento"
            required={true}
            inputProps={{
                maxLength: 40
            }}
            errortext="Campo obrigatório!"
            value={nomeEvento}
            onChange={(e) => setNomeEvento(e.target.value)}
            fullWidth
            autoFocus
        />
        <TextField
            label="Forma de Pagamento"
            required={true}
            inputProps={{
                maxLength: 40
            }}
            errortext="Campo obrigatório!"
            value={formaPagamento}
            onChange={(e) => setFormaPagamento(e.target.value)}
            fullWidth
        />
        <DatePicker
            label="Data de Início"
            sx={{
                width: '100%'
            }}
            defaultValue={dataInicio ? dayjs(dataInicio) : null}
            onChange={(e) => handleChangeData(e, setDataInicio)}
            slotProps={{
                textField: {
                    required: true
                },
            }}
        />
        <Select
            className="basic-single"
            classNamePrefix="select"
            id="form_select"
            required={true}
            defaultValue={statusList.filter(item => item.value === status)}
            isDisabled={false}
            isLoading={false}
            isClearable={false}
            isRtl={false}
            isSearchable={true}
            options={statusList}
            onChange={(e) => setStatus(e.value)}
            placeholder={'Status *'}
        />


        <Box mt={2} component={'h5'}>
            Parcelamento
        </Box>
        <hr/>

        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            {/*<div style={{marginRight: '10px'}}>*/}
            <Typography variant="body1">
                Modelo Antigo
            </Typography>
            <Switch
                checked={usePadrao}
                onChange={() => {
                    setUsePadrao(prevState => !prevState)
                }}
                color="primary"
                inputProps={{'aria-label': 'alternar entre CNPJ e CPF'}}
            />
            <Typography variant="body1">
                Modelo Novo
            </Typography>
        </div>

        {!usePadrao ? <>
            <TextField
                label="Quantidade total de Parcelas"
                type="text"
                inputProps={{
                    minLength: 1,
                    maxLength: 10
                }}
                value={qtdParcelas}
                onChange={(e) =>
                    handleChangeNumber(setQtdParcelas, e.target.value)
                }
                required
                InputLabelProps={InputLabelProps}
                fullWidth
            />

            <InputsInstallment
                modalParcelamento={modalParcelamento} setModalParcelamento={setModalParcelamento}
                isEvent={true}
            />
        </> : <>
            <SelectInput
                url={ApiUrl + '/padrao-parcelamento/list?size=20000'}
                list={padraoParcList}
                set={setPadraoParcList}
                defaultValue={padraoParcelamentoId}
                setObj={setPadraoParcelamentoId}
                returnList={() => {
                    let returnList = []
                    padraoParcList.forEach((item) => {
                        returnList.push({value: item.id, label: `${item.nome}`})
                    })
                    return returnList
                }}
                placeholder={"Padrão de Parcelamento *"}
                required={true}
                fullWidth
            />
        </>}
    </>
}