import {useState} from "react";
import EventIcon from '@mui/icons-material/Event';
import InputsEvent from "./InputsEvent";
import PopupAdd from "../../../component/PopupAdd/PopupAdd";

export default function PopupAddEvent({baseUrl, setLoadData}) {

    const [nomeEvento, setNomeEvento] = useState('');
    const [formaPagamento, setFormaPagamento] = useState('')
    const [status, setStatus] = useState('')
    const [dataInicio, setDataInicio] = useState('')
    const [qtdParcelas, setQtdParcelas] = useState(1);
    const [modalParcelamento, setModalParcelamento] = useState({})
    const [padraoParcelamentoId, setPadraoParcelamentoId] = useState(null);

    const [usePadrao, setUsePadrao] = useState(true);

    const [errorMessage, setErrorMessage] = useState("")
    const [errorColumn, setErrorColumn] = useState("")

    const inputs = <InputsEvent
        nomeEvento={nomeEvento} setNomeEvento={setNomeEvento}
        formaPagamento={formaPagamento} setFormaPagamento={setFormaPagamento}
        status={status} setStatus={setStatus}
        dataInicio={dataInicio} setDataInicio={setDataInicio}
        modalParcelamento={modalParcelamento} setModalParcelamento={setModalParcelamento}
        qtdParcelas={qtdParcelas} setQtdParcelas={setQtdParcelas}
        padraoParcelamentoId={padraoParcelamentoId} setPadraoParcelamentoId={setPadraoParcelamentoId}
        usePadrao={usePadrao} setUsePadrao={setUsePadrao}
        setErrorMessage={setErrorMessage} errorMessage={errorMessage}
        setErrorColumn={setErrorColumn} errorColumn={errorColumn}
    />

    const icon = <EventIcon fontSize="large" id="icon-svg"/>

    const clearForm = () => {
        setErrorMessage(false)
        setErrorColumn(false)
        setUsePadrao(true)
        setFormaPagamento('')
        setNomeEvento('')
        setDataInicio('')
        setModalParcelamento({})
        setStatus(null)
    }

    const returnData = () => {
        const parcelamento = usePadrao ?
            {padraoParcelamentoId} : {
                modalParcelamento: {
                    ...modalParcelamento,
                    qtdParcelas: qtdParcelas
                }
            }
        return {
            nomeEvento,
            formaPagamento,
            status,
            dataInicio,
            ...parcelamento
        }
    }

    return <PopupAdd
        baseUrl={baseUrl + '/register'} setLoadData={setLoadData}
        inputs={inputs} title={'Adicionar Evento'} icon={icon}
        clearForm={clearForm} returnData={returnData}
        setErrorMessage={setErrorMessage} setErrorColumn={setErrorColumn}
    />
}