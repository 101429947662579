import TextField from "@mui/material/TextField";
import {NumericFormat} from "react-number-format";
import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";

export default function InputsParceiroComercial({taxaParceiroComercial, setTaxaParceiroComercial}){

    const [taxaTotal, setTaxaTotal] = useState(
        taxaParceiroComercial?.taxaTotal? taxaParceiroComercial?.taxaTotal: 3
    );

    const [taxaSplit, setTaxaSplit] = useState(
        taxaParceiroComercial?.taxaSplit? taxaParceiroComercial?.taxaSplit: 1
    );

    const handleChangeValue = (e, set) => {
        set(e.floatValue)
    }

    useEffect(() => {
        setTaxaParceiroComercial({
            taxaTotal,
            taxaSplit
        })
    }, [taxaTotal, taxaSplit])

    return <>

        <Box mt={2} component={'h5'}>
            Taxas
        </Box>
        <hr/>

        <NumericFormat
            customInput={TextField}
            thousandSeparator="."
            decimalSeparator=","
            suffix=' %'
            decimalScale={2}
            label="Taxa de Administração (%)"
            max='999999'
            inputProps={{
                min: 1,
                max: 10,
                maxLength: 5,
                step: ".01"
            }}
            InputLabelProps={{
                style: {
                    zIndex: 0,
                },
            }}
            value={taxaTotal}
            onValueChange={(e) => handleChangeValue(e, setTaxaTotal)}
            required
            fullWidth
        />

        <NumericFormat
            customInput={TextField}
            thousandSeparator="."
            decimalSeparator=","
            suffix=' %'
            decimalScale={2}
            label="Taxa de Split (%)"
            max='999999'
            inputProps={{
                min: 1,
                max: 10,
                maxLength: 5,
                step: ".01"
            }}
            InputLabelProps={{
                style: {
                    zIndex: 0,
                },
            }}
            value={taxaSplit}
            onValueChange={(e) => handleChangeValue(e, setTaxaSplit)}
            required
            fullWidth
        />
    </>
}