import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Grid,
    Box,
} from "@mui/material";
import TextField from "@mui/material/TextField";

const ParcelTable = ({ parcelas, qtdParcelas }) => {

    if (!parcelas.length) {
        return <p>Nenhuma parcela disponível.</p>;
    }

    const parcelasPorComprador = parcelas.reduce((acc, parcela) => {
        const email = parcela.participante.split(' ')[0];
        if (!acc[email]) {
            acc[email] = [];
        }
        acc[email].push(parcela);
        return acc;
    }, {});

    return (
        <TableContainer component={Paper}>
            {Object.keys(parcelasPorComprador).map((email, index) => {
                const parcelasDoComprador = parcelasPorComprador[email];
                const valorTotal = parcelasDoComprador.reduce((sum, parcela) => sum + parcela.valor, 0);
                const valorEntrada = parcelasDoComprador[0]?.pedido?.modalParcelamento?.entrada || 0;

                const quantidadeParcelas = qtdParcelas;
                const modalidadePagamentoAglutinacao = parcelasDoComprador[0]?.pedido?.modalParcelamento?.aglutinacoes || 'Não especificada';
                const modalidadePagamentoQtdAglutinacao = parcelasDoComprador[0]?.pedido?.modalParcelamento?.quantidadeAglutinacoes || 'Não especificada';
                const desconto = parcelasDoComprador[0]?.pedido?.modalParcelamento?.desconto || 0;
                const totalLiquido = valorTotal - desconto;

                const entrada = {
                    numero: 'Entrada',
                    valor: valorEntrada,
                    vencimento: new Date(),
                    status: 'PAGO'
                };

                const todasParcelas = [entrada, ...parcelasDoComprador];

                const impares = todasParcelas.filter((_, idx) => idx % 2 === 0);
                const pares = todasParcelas.filter((_, idx) => idx % 2 !== 0);

                return (
                    <div key={index} style={{ marginBottom: '20px' }}>
                        <Box sx={{ bgcolor: 'black', color: 'white', textAlign: 'center', mt: 3 }}>
                            <Typography variant="h6" gutterBottom>
                                Dados da Venda
                            </Typography>
                        </Box>
                        <Typography variant="h6" mt={3} mb={3} gutterBottom>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box>
                                        <TextField
                                            fullWidth
                                            label="Email do Comprador"
                                            value={email}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            variant="outlined"
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box>
                                        <TextField
                                            fullWidth
                                            label="Quantidade de Parcelas"
                                            value={quantidadeParcelas}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            variant="outlined"
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box>
                                        <TextField
                                            fullWidth
                                            label="Aglutinações"
                                            value={modalidadePagamentoAglutinacao}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            variant="outlined"
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box>
                                        <TextField
                                            fullWidth
                                            label="Quantidade de Aglutinações"
                                            value={modalidadePagamentoQtdAglutinacao}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            variant="outlined"
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={3}>
                                    <Box>
                                        <TextField
                                            fullWidth
                                            label="Valor Total"
                                            value={`R$ ${valorTotal.toFixed(2)}`}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            variant="outlined"
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={3}>
                                    <Box>
                                        <TextField
                                            fullWidth
                                            label="Entrada"
                                            value={`R$ ${valorEntrada.toFixed(2)}`}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            variant="outlined"
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={3}>
                                    <Box>
                                        <TextField
                                            fullWidth
                                            label="Desconto"
                                            value={`R$ ${desconto.toFixed(2)}`}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            variant="outlined"
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={3}>
                                    <Box>
                                        <TextField
                                            fullWidth
                                            label="Total Líquido"
                                            value={`R$ ${totalLiquido.toFixed(2)}`}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            variant="outlined"
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Typography>
                        <Box sx={{ bgcolor: 'black', color: 'white', textAlign: 'center', mt: 3, mb: 3}}>
                            <Typography variant="h6" gutterBottom>
                                Forma de Pagamento
                            </Typography>
                        </Box>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Table size="small" aria-label="table with impares background">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Parcela</TableCell>
                                            <TableCell>Valor</TableCell>
                                            <TableCell>Vencimento</TableCell>
                                            <TableCell>Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {impares.map((parcela, idx) => (
                                            <TableRow key={idx} className={idx % 2 === 0 ? 'odd-row' : 'even-row'}>
                                                <TableCell>{parcela.numero}</TableCell>
                                                <TableCell>{parcela.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                                <TableCell>{new Date(parcela.vencimento).toLocaleDateString()}</TableCell>
                                                <TableCell>{parcela.status}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>

                            <Grid item xs={6}>
                                <Table size="small" aria-label="table with pares background">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Parcela</TableCell>
                                            <TableCell>Valor</TableCell>
                                            <TableCell>Vencimento</TableCell>
                                            <TableCell>Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {pares.map((parcela, idx) => (
                                            <TableRow key={idx} className={idx % 2 === 0 ? 'odd-row' : 'even-row'}>
                                                <TableCell>{parcela.numero}</TableCell>
                                                <TableCell>{parcela.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                                <TableCell>{new Date(parcela.vencimento).toLocaleDateString()}</TableCell>
                                                <TableCell>{parcela.status}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </div>
                );
            })}
            <style>
                {`
                    .odd-row {
                        background-color: #f2f2f2;
                    }
                    .even-row {
                        background-color: #ffffff;
                    }
                `}
            </style>
        </TableContainer>
    );
};

export default ParcelTable;
