import React, { useState } from "react";
import ApresentacaoStep from "./Components/Steps/ApresentacaoStep";
import "./Antecipacao.css";
import AntecipacaoStep from "./Components/Steps/AntecipacaoStep";
import { useNavigate } from "react-router-dom";
import ConfirmacaoStep from "./Components/Steps/ConfirmacaoStep";
import { useUser } from "../../auth/AuthProvider";
import EventList from "../Event/Components/EventList";
import PaginationControl from "../../component/Pagination/PaginationControl";
import ContentContainer from "../../component/ContentContainer";
import {ApiUrl} from "../../auth/authMethods";
import AntecipacaoList from "./Components/AntecipacaoList";

export default function Antecipacao() {
    const [step, setStep] = useState(0);
    const [value, setValue] = useState(0);
    const { user } = useUser();
    const navigation = useNavigate();

    const handleStart = () => {
        setStep(1);
    };

    const handleAntecipar = () => {
        setStep(2);
    };

    const handleBack = () => {
        setStep(step - 1);
    };

    const handleConfirmacao = () => {
        navigation("/home");
    };

    const baseUrl = `${ApiUrl}/antecipacoes`
    const [loadData, setLoadData] = useState(true)

    const [size, setSize] = useState(10)
    const [page, setPage] = useState(0)
    const [sort, setSort] = useState("")

    const [totalPages, setTotalPages] = useState(0)
    const [totalElements, setTotalElements] = useState(0)

    return (
        <div className="antecipacao-container">
            {!user.isAdmin ? (
                <>
                    {step === 0 && <ApresentacaoStep handleStart={handleStart} />}
                    {step === 1 && (
                        <AntecipacaoStep handleAntecipar={handleAntecipar} handleBack={handleBack} setValue={setValue} />
                    )}
                    {step === 2 && (
                        <ConfirmacaoStep handleConfirmacao={handleConfirmacao} handleBack={handleBack} value={value} />
                    )}
                </>
            ) : (
                <ContentContainer width={'xl'}>
                    <h1>Lista de Antecipações</h1>
                    <AntecipacaoList
                        baseUrl={baseUrl}
                        loadData={loadData}
                        setLoadData={setLoadData}
                        page={page}
                        setPage={setPage}
                        size={size}
                        setSize={setSize}
                        totalPages={totalPages}
                        setTotalPages={setTotalPages}
                        totalElements={totalElements}
                        setTotalElements={setTotalElements}
                        sort={sort} setSort={setSort}
                    />
                    <PaginationControl
                        totalElements={totalElements}
                        page={page}
                        setPage={setPage}
                        size={size}
                        setSize={setSize}
                        setLoadData={setLoadData}
                    />
                </ContentContainer>
            )}
        </div>
    );
}
