import React, {useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import axios from "axios";
import {ApiUrl} from "../../../../auth/authMethods";
import {useUser} from "../../../../auth/AuthProvider";
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import InputsParticipante from "../../../Users/components/inputs/InputsParticipante";
import SelectInput from "../../../../component/SelectInput";

export default function InputsUser({
                                       obj, setObj,
                                       index, type,
                                       setBlockSubmit
                                   }) {

    const {user} = useUser()

    const [id, setId] = useState('')
    const [porcentagem, setPorcentagem] = useState('')

    const [userList, setUserList] = useState({})

    useEffect(() => {
        setObj({
            id,
            porcentagem,
            papel: type,
            index
        })
    }, [id, porcentagem])

    const handleChangePorcentagem = (event) => {
        // Remover caracteres não numéricos e substituir múltiplos pontos por um único ponto
        const inputValue = event.target.value
            .replace(/[^0-9.]/g, '') // Remove caracteres não numéricos, exceto pontos
            .replace(/(\..*)\./g, '$1'); // Substitui múltiplos pontos por um único ponto

        if (inputValue !== '') {
            const floatValue = parseFloat(inputValue);
            if (!isNaN(floatValue) && floatValue >= 0 && floatValue <= 100) {
                setPorcentagem(inputValue);
            }
        } else {
            setPorcentagem('');
        }
    };




    return <>
        <SelectInput
            url={ApiUrl + `/user/list/papel?size=20000&tipoPapel=${type}`}
            list={userList}
            set={setUserList}
            setObj={setId}
            returnList={() => {
                let returnList = []
                userList.forEach((item) => {
                    returnList.push({
                        value: item.id,
                        label: `${item.participante.nome} - ${item.participante.documento}`
                    })
                })
                return returnList
            }}
            placeholder={"Usuário *"}
            required={true}
            fullWidth
        />

        <TextField
            label="Porcentagem (0-100)"
            variant="outlined"
            className='mt-2'
            value={porcentagem}
            onChange={handleChangePorcentagem}
            fullWidth
            required
            inputProps={{
                maxLength: 5
            }}
            InputLabelProps={{
                style: {
                    zIndex: 0
                },
            }}
        />
    </>
}