import {Dropdown} from "react-bootstrap";
import SettingsIcon from "@mui/icons-material/Settings";
import React from "react";
import PopupEditBoleto from "./PopupEditBoleto";
import PopupCancelBoleto from "./PopupCancelBoleto";


export default function BoletoActions({item, setLoadData}) {

    return <Dropdown drop='down-centered'>
        <Dropdown.Toggle
            variant="secondary"
            disabled={!(item.status === 'CRIADO' || item.status === 'VENCIDO')}
        >
            Ações &nbsp;
            <SettingsIcon fontSize="medium"/>
        </Dropdown.Toggle>

        <Dropdown.Menu>
            {(item.status === 'CRIADO' || item.status === 'VENCIDO') && <>
                <PopupEditBoleto setLoadData={setLoadData} item={item}/>
                <PopupCancelBoleto setLoadData={setLoadData} item={item}/>
            </>}
        </Dropdown.Menu>

    </Dropdown>
}