import ContentContainer from "../../component/ContentContainer";
import React, {useState} from "react";
import axios from "axios";
import {ApiUrl} from "../../auth/authMethods";
import displayAlertMsg from "../../component/AlertMsg/displayAlertMsg";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddUsersForm from "./Components/AddUsersForm";

export default function AddUser() {

    const [isLoading, setIsLoading] = useState(false);
    const [sent, setSent] = useState(false);
    const [error, setError] = useState(false);

    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [isAccountNonExpired, setIsAccountNonExpired] = useState(true)
    const [isAccountNonLocked, setIsAccountNonLocked] = useState(true)
    const [isCredentialsNonExpired, setIsCredentialsNonExpired] = useState(true)
    const [isEnabled, setIsEnabled] = useState(true)
    const [isTwoFactorEnabled, setIsTwoFactorEnabled] = useState(true)
    const [roles, setRoles] = useState([])

    const handleSubmit = (e) => {
        e.preventDefault()

        const data = {
            username,
            email,
            isAccountNonExpired,
            isAccountNonLocked,
            isCredentialsNonExpired,
            isEnabled,
            isTwoFactorEnabled,
            roles: [roles]
        }



        setIsLoading(true)
        axios.post(ApiUrl + '/auth/register', data)
            .then(() => {
                setSent(true)
            }).catch((error) => {
            if (error.response.data.message)
                setError(displayAlertMsg(error.response.data.message))
            else
                setError(displayAlertMsg())
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const clearForm = () => {
        setUsername('')
        setEmail('')
        setIsAccountNonExpired(true)
        setIsAccountNonLocked(true)
        setIsCredentialsNonExpired(true)
        setIsEnabled(true)
        setIsTwoFactorEnabled(true)
        setRoles([])
        setIsLoading(false)
        setSent(false)
        setError(false)
    }

    const button = isLoading ?
        <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{mt: 1, mb: 2, backgroundColor: 'secondary'}}
            disabled
        >
            Aguarde ...
        </Button> :
        <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{mt: 1, mb: 2, backgroundColor: 'primary'}}
        >
            Cadastrar
        </Button>

    return <ContentContainer>
        {!sent ?
            <AddUsersForm
                username={username} setUsername={setUsername}
                email={email} setEmail={setEmail}
                isAccountNonExpired={isAccountNonExpired} setIsAccountNonExpired={setIsAccountNonExpired}
                isAccountNonLocked={isAccountNonLocked} setIsAccountNonLocked={setIsAccountNonLocked}
                isCredentialsNonExpired={isCredentialsNonExpired}
                setIsCredentialsNonExpired={setIsCredentialsNonExpired}
                isEnabled={isEnabled} setIsEnabled={setIsEnabled}
                isTwoFactorEnabled={isTwoFactorEnabled} setIsTwoFactorEnabled={setIsTwoFactorEnabled}
                roles={roles} setRoles={setRoles}
                button={button} handleSubmit={handleSubmit} error={error}
            /> :
            <Box component="div" sx={{mt: 1}} className="text-center">
                <p style={{color: 'green'}}>
                    <CheckCircleIcon sx={{
                        fontSize: 40,
                        height: '5vw',
                        width: '5vw',
                    }}/><br/>
                    <h4>Usuário cadastrado com sucesso!</h4>
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{mt: 1, mb: 2, backgroundColor: 'primary'}}
                        onClick={clearForm}
                    >
                        Cadastrar outro usuário
                    </Button>
                </p>
            </Box>
        }
    </ContentContainer>
}