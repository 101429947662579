
export const findDuplicates = (list) => {
    const checked = new Set();
    const duplicates = new Set();

    for (const item of list) {
        if (checked.has(item)) {
            duplicates.add(item);
        } else {
            checked.add(item);
        }
    }

    return Array.from(duplicates);
}
