import TextField from "@mui/material/TextField";
import SelectInput from "../../../../component/SelectInput";
import FormControlLabel from "@mui/material/FormControlLabel";
import {Switch} from "@mui/material";
import React, {useEffect} from "react";
import {aliasRoles} from "../aliasRoles";
import applyRegex from "../../../../utils/applyRegex";
import {ApiUrl} from "../../../../auth/authMethods";
import {useUser} from "../../../../auth/AuthProvider";


export default function InputUsersEdit({
                                           id,
                                           username, setUsername,
                                           email, setEmail,
                                           roles, setRoles,
                                           isEnabled, setIsEnabled,
                                           isCredentialsNonExpired, setIsCredentialsNonExpired,
                                           isAccountNonExpired, setIsAccountNonExpired,
                                           isAccountNonLocked, setIsAccountNonLocked,
                                           isTwoFactorEnabled, setIsTwoFactorEnabled,
                                           roleList, setRoleList,
                                           setIsAdmin
                                       }) {

    const {user} = useUser()
    const ownUser = id === user.id

    useEffect(() => {
        if (roleList.length > 0) {
            const filteredRoles = roleList.find(
                item => item.id === roles

            );
            setIsAdmin(filteredRoles.name === 'ROLE_ADMIN')
        }
    }, [roles])

    return <>
        <TextField
            label="Username"
            inputProps={{
                maxLength: 25
            }}
            value={username}
            onChange={(e) => setUsername(applyRegex(e.target.value, /[^ ]+/))}
            fullWidth
            autoFocus
        />
        <TextField
            label="Email"
            type={"email"}
            inputProps={{
                maxLength: 45
            }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
        />

        {!ownUser && <>
            <SelectInput
                url={ApiUrl + '/role?size=2000'}
                list={roleList} set={setRoleList}
                setObj={setRoles}
                defaultValue={roles[0]?.id || roles}
                returnList={() => {
                    let returnList = []
                    roleList.forEach((item) => {
                        returnList.push({
                            value: item.id,
                            label: aliasRoles.filter(alias => alias.received === item.name)[0].display
                        })
                    })
                    return returnList
                }}
                placeholder={"Tipo de usuário"}
                required={false}
                fullWidth
            />

            <FormControlLabel control={
                <Switch checked={isEnabled} onChange={
                    () => setIsEnabled(!isEnabled)
                }/>
            } label="Usuário Ativo"/>

            <FormControlLabel control={
                <Switch checked={!isAccountNonExpired} onChange={
                    () => setIsAccountNonExpired(!isAccountNonExpired)
                }/>
            } label="Conta expirada"/>

            <FormControlLabel control={
                <Switch checked={!isAccountNonLocked} onChange={
                    () => setIsAccountNonLocked(!isAccountNonLocked)
                }/>
            } label="Conta Bloqueada"/>

        </>}

        <FormControlLabel control={
            <Switch checked={isTwoFactorEnabled} onChange={
                () => setIsTwoFactorEnabled(!isTwoFactorEnabled)
            }/>
        } label="Verificação de duas etapas"/>
        <br/>
    </>
}
