
export function numberWithPoints(number, decimalPlaces=2){
    const formattedNumber = parseFloat(number).toFixed(decimalPlaces);
    if (isNaN(formattedNumber)){
        let value = "0" + ((decimalPlaces > 0)? '.' : '')
        for (var i = 0; i < decimalPlaces; i++) {
            value += '0';
        }
        return value
    }

    return formattedNumber
        .replace('.', ',')
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}