import React, {useEffect, useState} from "react";
import TableList from "../../../component/TableList/TableList";
import FilterBoleto from "./FilterBoleto";
import {statusList} from "./statusList";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DownloadIcon from '@mui/icons-material/Download';
import axios from "axios";
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import {normalizeTimestemp} from "../../../utils/normalizeTimestemp";
import sleep from "../../../utils/sleep";
import BoletoActions from "./BoletoActions";
import {useUser} from "../../../auth/AuthProvider";
import LoadingButton from "@mui/lab/LoadingButton";
import {useParams} from "react-router-dom";

export default function BoletoList({
                                       baseUrl, loadData,
                                       setLoadData, size, page,
                                       setTotalPages, setTotalElements,
                                       sort, setSort, updateCode, setPage,
                                       filterDefault, type
                                   }) {

    const {user} = useUser()

    const [data, setData] = useState([])
    const [error, setError] = useState(false)


    const [filter, setFilter] = useState(filterDefault)
    useEffect(() => {
        setFilter(filterDefault)
        setLoadData(true)
    }, [type])

    const [idCopied, setIdCopied] = useState(null)

    const [downloadingList, setDownloadingList] = useState([])

    const titles = user.isAdmin ? [
        'Evento',
        'Lote',
        'Parcela',
        'Vencimento',
        'Status',
        'Código de Barras'
    ] : [
        'Evento',
        'Lote',
        'Parcela',
        'Vencimento',
        'Status',
        'Código de Barras'
    ]

    function downloadBoleto(id, filename) {
        const url = baseUrl + '/generate/' + id
        setDownloadingList([...downloadingList, id])

        axios.post(url, {}, {responseType: 'arraybuffer'})
            .then(response => {
                // Cria um objeto Blob a partir do array de bytes recebido na resposta
                const blob = new Blob([response.data], {type: 'application/octet-stream'});

                // Cria um link temporário para o Blob e o adiciona ao documento
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = filename ? filename : 'boleto.pdf'; // Define o nome do arquivo a ser baixado
                document.body.appendChild(link);

                // Simula um clique no link para iniciar o download
                link.click();

                // Remove o link temporário do documento
                document.body.removeChild(link);
            })
            .catch(error => console.error('Erro ao gerar boleto:', error))
            .finally(() => {
                setDownloadingList(downloadingList.splice(downloadingList.indexOf(id), 1))
            })
    }

    const copy = (item) => {
        const textField = document.createElement('textarea');
        textField.innerText = item.linhaDigitavel;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        document.body.removeChild(textField);
        setIdCopied(item.id);
    }

    useEffect(() => {
        if (idCopied) {
            sleep(3000).then(() => {
                setIdCopied(null)
            });
        }
    }, [idCopied])

    const listUrl = user.isAdmin ?
        `${baseUrl}/list` :
        `${baseUrl}/client/list`

    return (
        <>
            {user.isAdmin &&
                <FilterBoleto filter={filter} setFilter={setFilter} setLoadData={setLoadData} setPage={setPage}/>
            }
            <TableList
                titles={titles} baseUrl={listUrl}
                page={page} sort={sort} size={size}
                loadData={loadData}
                setTotalPages={setTotalPages} setTotalElements={setTotalElements}
                setData={setData} setLoadData={setLoadData} filter={filter}
                error={error} setError={setError} isFilterByUser={true}
            >
                {data.map((item) =>
                    <tr key={item.id}>
                        <td style={{
                            color: user.isAdmin? 'default' :
                                item.tipoPapel === 'COMPRADOR'? 'red': 'green'
                        }}>{item.nomeEvento}</td>
                        <td style={{
                            color: user.isAdmin? 'default' :
                                item.tipoPapel === 'COMPRADOR'? 'red': 'green'
                        }}>{item.numeroLote}</td>
                        <td>
                            {item.parcela}
                        </td>
                        <td>{normalizeTimestemp(item.dataVencimento)}</td>
                        <td>{statusList.filter(stt => stt.value === item.status)[0].label}</td>
                        <td>
                            <span
                                style={{maxWidth: '100px', wordWrap: 'break-word', cursor: 'pointer'}}
                                onClick={() => copy(item)}
                            >
                                {user.isAdmin ?
                                    `${item.linhaDigitavel.slice(0, 6)}...${item.linhaDigitavel.slice(-6)}`:
                                    'Copiar'
                                }
                                {item.id === idCopied ?
                                    <span className='text-success'>&nbsp;<FileDownloadDoneIcon/></span> :
                                    <>&nbsp;<ContentCopyIcon/></>
                                }
                            </span>
                        </td>
                        <td>
                            <span className='d-inline-block' style={{marginInline: '0%'}}>

                                <LoadingButton
                                    title='Fazer download do boleto'
                                    loading={downloadingList.find(id => id === item?.id)}
                                    onClick={() => downloadBoleto(item.id, item.filename)}
                                    variant="text">
                                  <DownloadIcon/>
                                </LoadingButton>
                                {user.isAdmin && <>
                                    &nbsp;&nbsp;
                                    <span className='d-inline-block'>
                                    <BoletoActions item={item} setLoadData={setLoadData}/>
                                </span>
                                </>}
                            </span>
                        </td>
                    </tr>
                )}
            </TableList>
            {!user.isAdmin && <>
                <br/>
                <ul className="legenda-boletos">
                    <li style={{color: 'red'}}>Boleto a pagar</li>
                    <li style={{color: 'green'}}>Boleto a receber</li>
                </ul>
            </>}
        </>
    )
}