import React, {useEffect, useState} from "react";
import {ApiUrl} from "../../../../auth/authMethods";
import PopupAdd from "../../../../component/PopupAdd/PopupAdd";
import DescriptionIcon from "@mui/icons-material/Description";
import InputsOrder from "./InputsOrder";
import CurrencyInput from "../../../../component/CurrencyInput";
import TextField from "@mui/material/TextField";
import {InputLabelProps} from "../../../../utils/InputLabelProps";
import SelectInput from "../../../../component/SelectInput";
import Box from "@mui/material/Box";
import DisplayManyUsers from "./DisplayManyUsers";
import DisplayManyAnimals from "../InputsAdd/DisplayManyAnimals";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {handleChangeData} from "../../../../utils/handleChangeData";
import applyRegex from "../../../../utils/applyRegex";
import {findDuplicates} from "../../../../utils/findDuplicates";
import Alert from "react-bootstrap/Alert";
import PreviewModal from "./Preview/PreviewModal";
import {Button} from "@mui/material";


export default function PopupAddOrderV2({setLoadData}) {

    const [pedido, setPedido] = useState({})
    const [padraoParcelamentoId, setPadraoParcelamentoId] = useState('')
    const [entrada, setEntrada] = useState(0)
    const [diaDoMes, setDiaDoMes] = useState(null)
    const [primeiroVencimento, setPrimeiroVencimento] = useState(null)

    const initialAnimalList = [
        {numeroLote: pedido?.numero}
    ]
    const [animal, setAnimal] = useState(initialAnimalList)

    const [compradores, setCompradores] = useState([{
        index: 0,
        papel: 'COMPRADOR'
    }])
    const [vendedores, setVendedores] = useState([{
        index: 0,
        papel: 'VENDEDOR'
    }])

    const [padraoParcelamentoList, setPadraoParcelamentoList] = useState([])
    const [padraoParcelamento, setPadraoParcelamento] = useState(null)

    const [blockSubmit, setBlockSubmit] = useState(false);
    const [errorMessage, setErrorMessage] = useState("")
    const [errorColumn, setErrorColumn] = useState("")
    const [error, setError] = useState(false)

    const [needInput, setNeedInput] = useState(false);

    const handleChangeValue = (e, set) => {
        set(e.floatValue)
    }
    const handleChangeNumber = (set, value) => {
        set(applyRegex(value.replace('e', ''), /\d+/));
    };


    useEffect(() => {
        setNeedInput(padraoParcelamento?.entrada === 'NAO_INCLUSA_PARCELAS')
        setPadraoParcelamentoId(padraoParcelamento?.id)
    }, [padraoParcelamento])

    const inputs = <>
        <InputsOrder
            setPedido={setPedido}
            setPadraoParcelamentoId={setPadraoParcelamentoId}
            setNeedInput={setNeedInput}
        />

        <SelectInput
            url={ApiUrl + '/padrao-parcelamento/list?size=20000'}
            list={padraoParcelamentoList}
            set={setPadraoParcelamentoList}
            defaultValue={padraoParcelamento}
            setObj={setPadraoParcelamento}
            returnList={() => {
                let returnList = []
                padraoParcelamentoList.forEach((item) => {
                    returnList.push({value: item, label: `${item.nome}`})
                })
                return returnList
            }}
            placeholder={"Padrão de parcelamento *"}
            required={true}
            fullWidth
        />

        {needInput &&
            <CurrencyInput
                label="Valor da Entrada (Manter 0 caso não possua)"
                customInput={TextField}
                className='mt-3'
                prefix='R$'
                value={entrada}
                inputProps={{
                    min: 1,
                    step: ".01"
                }}
                onValueChange={(e) => handleChangeValue(e, setEntrada)}
                InputLabelProps={InputLabelProps}
                required
                fullWidth
            />
        }
        <TextField
            label="Dia de Vencimento"
            type="text"
            className='mt-2'
            value={diaDoMes}
            inputProps={{
                minLength: 1,
                maxLength: 2
            }}
            onChange={(e) => handleChangeNumber(setDiaDoMes, e.target.value)}
            InputLabelProps={InputLabelProps}
            required
            fullWidth
        />

        <DatePicker
            label="Data de Vencimento da primeira parcela boletada"
            sx={{
                width: '100%',
                zIndex: 0
            }}
            defaultValue={primeiroVencimento ? dayjs(primeiroVencimento) : null}
            onChange={(e) => handleChangeData(e, setPrimeiroVencimento)}
            slotProps={{
                textField: {
                    required: true,
                },
            }}
            InputLabelProps={InputLabelProps}
        />

        <Box mt={2} component={'h5'}>
            Vendedores
        </Box>
        <hr/>
        <DisplayManyUsers
            obj={vendedores} setObj={setVendedores}
            name={'Vendedor'} type={'VENDEDOR'} setBlockSubmit={setBlockSubmit}
        />

        <Box mt={2} component={'h5'}>
            Compradores
        </Box>
        <hr/>
        <DisplayManyUsers
            obj={compradores} setObj={setCompradores}
            name={'Comprador'} type={'COMPRADOR'} setBlockSubmit={setBlockSubmit}
        />

        <Box mt={2} component={'h5'}>
            Animais
        </Box>
        <hr/>
        <DisplayManyAnimals
            baseUrl={ApiUrl} obj={animal} setObj={setAnimal}
            name={'Animal'} numeroLote={pedido?.numero}
        />

        {error &&
            <Alert key='danger' variant='danger'>
                {error}
            </Alert>
        }
    </>

    const icon = <DescriptionIcon fontSize="large" id="icon-svg"/>

    const clearForm = () => {
        setPedido({})
        setPadraoParcelamentoId('')
        setEntrada(0)
        setDiaDoMes(null)
        setPrimeiroVencimento(null)
        setPadraoParcelamentoList([])
        setPadraoParcelamento(null)
        setBlockSubmit(false)
        setErrorMessage("")
        setErrorColumn("")
        setError(false)
        setNeedInput(false)
        setAnimal(initialAnimalList)
        setCompradores([{
            index: 0,
            papel: 'COMPRADOR'
        }])
        setVendedores([{
            index: 0,
            papel: 'VENDEDOR'
        }])
    }
    const returnData = () => {

        setErrorMessage(false)
        setErrorColumn(false)
        setError(false)

        return {
            pedido,
            padraoParcelamentoId,
            entrada,
            diaDoMes,
            primeiroVencimento: primeiroVencimento + "T00:00:00",
            animal,
            users: [...compradores, ...vendedores]
        }
    }

    // returns true if data is invalid and false if is valid
    const validateData = () => {

        //validando se existem compradores e vendedores
        if (compradores[0]?.id === '' || vendedores[0]?.id === '')
            return false

        const nomesAnimais = animal.map(item => item.nomeAnimal)
        if (!(nomesAnimais.every(str => str !== null && str.length > 0)))
            return false

        if (pedido?.valor <= 0){
            setError(
                "O valor líquido das parcelas deve ser maior que 0."
            )
            return false
        }

        // validando compradores e/ou vendedores duplicados
        let duplicates = findDuplicates(compradores.map((user) => user.id))
        if (duplicates.length > 0) {
            setError(
                "Um usuáro não pode aparecer mais de uma vez como comprador."
            )
            return false
        }
        duplicates = findDuplicates(vendedores.map((user) => user.id))
        if (duplicates.length > 0) {
            setError("Um usuáro não pode aparecer mais de uma vez como vendedor.")
            return false
        }

        // validando possiveis ids de usuários em ambas as listas
        const commonIds =
            compradores.map(user => user.id)
                .filter(id =>
                    vendedores.map(user => user.id).includes(id)
                )
        if (!!commonIds[0]) {
            setError("Um usuário não pode aparecer como comprador e vendedor no mesmo pedido!")
            return false
        }

        // validando porcentagens
        const porcentagemCompradores = compradores
            .reduce((sum, obj) => parseFloat(sum) + parseFloat(obj.porcentagem), 0);
        if (porcentagemCompradores !== 100) {
            if (!isNaN(porcentagemCompradores))
                setError(
                    `A soma das porcentagens dos compradores deve ser 100% e está ${porcentagemCompradores}%!`
                )
            return false
        }
        const porcentagemVendedores = vendedores
            .reduce((sum, obj) => parseFloat(sum) + parseFloat(obj.porcentagem), 0);
        if (porcentagemVendedores !== 100) {
            if (!isNaN(porcentagemVendedores))
                setError(
                    `A soma das porcentagens dos vendedores deve ser 100% e está ${porcentagemVendedores}%!`
                )
            return false
        }

        if (pedido.arrearsFee > 10 || pedido.penaltyFee > 10) {
            setError(
                `A taxa ${pedido.penaltyFee > 10 ? 'por atraso' : 'de juros por dia'} não pode ser maior que 10%!`
            )
            return false
        }

        setError(false)
        setBlockSubmit(false)
        return true
    }


    // PreviewData
    const [openPreview, setOpenPreview] = useState(false);

    const handleOpenPreview = () => {
        setOpenPreview(true)
    }

    const previewButton = (
        <Button style={{marginLeft: '20px'}} variant={"outlined"} onClick={handleOpenPreview}>
            Preview
        </Button>
    )


    return <>
        <PopupAdd
            baseUrl={ApiUrl + '/multi-entity/register-v2'} setLoadData={setLoadData}
            inputs={inputs} title={'Adicionar Lote'} icon={icon}
            clearForm={clearForm} returnData={returnData}
            blockSubmit={blockSubmit}
            setErrorMessage={setErrorMessage} setErrorColumn={setErrorColumn}
            validateData={validateData}
            buttonClass={'btn btn-primary'}
            openPopupButton={'Modelo Novo'}
            previewButton={previewButton}
        />
        <PreviewModal
            openPreview={openPreview}
            setOpenPreview={setOpenPreview}
            validateData={validateData}
            returnData={returnData}
            error={error}
            setError={setError}
        />
    </>
}
