import PopupDelete from "../../../component/PopupDelete/PopupDelete";
import {normalizeTimestemp} from "../../../utils/normalizeTimestemp";
import {statusList} from "./statusList";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import React from "react";

export default function PopupDeleteAntecipacao({
                                             baseUrl,
                                             setLoadData,
                                             item
                                         }) {

    const buttonText = <span>
        <DeleteForeverIcon fontSize="medium"/> &nbsp;
        Inativar Evento
    </span>

    return (
        <PopupDelete
            baseUrl={baseUrl} setLoadData={setLoadData}
            title={"Confirmar Cancvelamento da Antecipação"} id={item.id}
            buttonClass='dropdown-item text-da nger' buttonText={buttonText}
        >

            <div className="mt-3 mb-3">
                <b>Antecipação: </b>

                <b>Data de Inicio: </b>
                <>{normalizeTimestemp(item.dataAntecipacao)}</>
                <br/>

                <b>Antecipante: </b>
                <>{item.nomeAntecipante}</>
                <br/>

                <b>Status: </b>
                <>{statusList.filter(stt => stt.value === item.status)[0].label}</>
                <br/>

            </div>

            <p className="mb-3">Você deseja realmente excluir este registro?</p>

        </PopupDelete>
    )
}