import React, {useEffect, useState} from "react";
import axios from "axios";
import Popup from "../../../component/Popup/Popup";
import DefaultSuccessMessage from "../../../component/DefaultSuccessMessage/DefaultSuccessMessage";
import BasicLoader from "../../../component/BasicLoader/BasicLoader";
import {ApiUrl} from "../../../auth/authMethods";
import {Button} from "react-bootstrap";
import NoteIcon from '@mui/icons-material/Note';
import BorderAllIcon from '@mui/icons-material/BorderAll';
import SuperscriptIcon from '@mui/icons-material/Superscript';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

export default function PopupExport({type, filter}) {

    const [lgShow, setLgShow] = useState("")
    const [extension, setExtension] = useState("")
    const [downloadedText, setDownloadedText] = useState(false);
    const [showCloseButton, setShowCloseButton] = useState(true);
    const [backdrop, setBackdrop] = useState("static");

    const title = "Extrair Relátório"

    const iconSpan = <>
        <NoteIcon fontSize="large" id="icon-svg"/> <br/>
        <h3>{title}</h3>
    </>

    const button = <Button variant='success'
                           onClick={() => setExtension('xlsx')}>
        Excel &nbsp;
        <SuperscriptIcon fontSize="medium"/>
    </Button>

    const button2 = <Button
        onClick={() => setExtension('csv')}>
        CSV &nbsp;
        <BorderAllIcon fontSize="medium"/>
    </Button>

    const button3 = <Button variant='danger'
                            onClick={() => setExtension('pdf')}>
        PDF &nbsp;
        <PictureAsPdfIcon fontSize="medium"/>
    </Button>

    useEffect(() => {
        if (lgShow === true) {
            handleDownload("xlsx")
        } else {
            handleModalHide()
        }
    }, [lgShow])

    const handleDownload = () => {

        // Configurar os dados para a requisição aqui
        const requestData = {
            extension,
            type,
            ...filter
        };

        console.log(requestData)

        // Fazer a requisição usando o axios
        axios({
            url: `${ApiUrl}/pedidos/export`,
            params: requestData,
            method: 'GET',
            responseType: 'blob', // Important: This tells Axios to treat the response as binary data
        }).then(response => {
            const blob = new Blob([response.data], {type: response.headers['content-type']});
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'relatorio-lotes.' + extension
            link.click();


            setDownloadedText("Download efetuado com sucesso!");
            setShowCloseButton(false);
            setBackdrop(true)
        });
    };

    const handleModalHide = () => {
        // Define todos os estados de volta aos valores iniciais
        setLgShow(false);
        setShowCloseButton(true);
        setBackdrop("static")
        setDownloadedText(false)
    };

    return (
        <>
            <span className="buttons">
                <h5 className='d-inline-block'>Exportar: &nbsp;</h5>
                <span className="btn-csv">
                    <Popup
                        title={title}
                        iconSpan={iconSpan}
                        buttonText={<>
                            {button} {button2} {button3}
                        </>}
                        lgShow={lgShow}
                        setLgShow={setLgShow}
                        backdrop={backdrop}
                        hideCloseButton={showCloseButton}
                        buttonClass=' '
                    >
                        {downloadedText ? ( // Verifica se há texto de download efetuado
                            <DefaultSuccessMessage message={downloadedText}/>
                        ) : (
                            <BasicLoader height={20}
                                message="O relatório está sendo gerado, isso pode demorar um pouco..."
                            />
                        )}
                    </Popup>
                </span>
            </span>
        </>
    );
};
