export const validateCpf = (cpf) => {
    // Remover caracteres não numéricos
    cpf = cpf.replace(/[^\d]/g, '');

    // Verificar se o CPF tem 11 dígitos
    if (cpf.length !== 11) {
        return false;
    }

    // Verificar se todos os dígitos são iguais
    if (/^(\d)\1{10}$/.test(cpf)) {
        return false;
    }

    // Calcular dígitos verificadores
    for (let j = 0; j < 2; j++) {
        let soma = 0;
        const peso = (j === 0) ? 10 : 11;

        for (let i = 0; i < 9 + j; i++) {
            soma += parseInt(cpf.charAt(i)) * (peso - i);
        }

        const resto = 11 - (soma % 11);
        const digito = (resto === 10 || resto === 11) ? 0 : resto;

        // Verificar se o dígito verificador está correto
        if (digito !== parseInt(cpf.charAt(9 + j))) {
            return false;
        }
    }

    // Se todas as verificações passaram, o CPF é válido
    return true;
}
