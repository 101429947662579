import {useState} from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import axios from "axios";
import {ApiUrl} from "../../../auth/authMethods";

export default function DowloadEvidences({files}) {


    const [loading, setLoading] = useState(false)

    const downloadFiles = () => {

        setLoading(true)

        files.forEach((file) => {
            downloadFile(file)
        })

        setLoading(false)
    }


    const downloadFile = ({id, nomeArquivo}) => {

        const axiosConfig = {
            method: 'get',
            url: ApiUrl + '/parcelas/evidencia/' + id,
            responseType: 'blob'
        };

        // Realiza a requisição Axios
        axios(axiosConfig)
            .then(response => {
                // Cria um objeto URL para o blob
                const blobUrl = URL.createObjectURL(response.data);

                // Cria um link para download
                const link = document.createElement('a');
                link.href = blobUrl;
                link.download = nomeArquivo;

                // Adiciona o link ao corpo do documento
                document.body.appendChild(link);

                // Simula um clique no link para iniciar o download
                link.click();

                // Remove o link do corpo do documento após o download
                document.body.removeChild(link);
            })
            .catch(error => console.error('Erro baixar evidencia de id ', id, ': ', error))

    }


    return <span className="dropdown-item" style={{cursor: "pointer"}}>
        <LoadingButton
            onClick={downloadFiles}
            loading={loading}
            loadingPosition="start"
            startIcon={<SaveIcon/>}
            variant="text"
        >
            <span>
                &nbsp;Baixar Evidências
            </span>
        </LoadingButton>
    </span>
}