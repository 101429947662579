import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import KeyIcon from '@mui/icons-material/Key';
import {useState} from "react";
import ContentContainer from "../../../component/ContentContainer";


export default function TokenVerificationForm({error, handleSubmit, isLoading}) {

    const [token, setToken] = useState('');

    const handleTokenChange = (event) => {
        const inputToken = event.target.value.replace(/[^0-9]/g, '').slice(0, 6); // Aceita apenas números e limita a 6 dígitos
        setToken(inputToken);
    };

    const zeroPadding = '000000';


    const button = isLoading ?
        <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{mt: 1, mb: 2, backgroundColor: 'secondary'}}
            disabled
        >
            Aguarde ...
        </Button> :
        <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{mt: 1, mb: 2, backgroundColor: 'primary'}}
        >
            Confirmar token
        </Button>

    return (
        <ContentContainer>
            <Box
                component="form" onSubmit={(event) => handleSubmit(event, token)}
                sx={{mt: 1}}
                className="text-center"
            >
                <div>
                    <h4>Verificação em duas etapas</h4>
                    <KeyIcon fontSize='large' sx={{fontSize: '4rem'}}/><br/>
                    Por favor, insira o código de verificação de duas etapas para concluir o processo de login. Este código foi enviado para o seu endereço de e-mail.
                </div>
                {error}
                <TextField
                    label=""
                    variant='standard'
                    id='token-2-steps'
                    fullWidth
                    value={token}
                    onChange={handleTokenChange}
                    InputProps={{
                        startAdornment: (
                            <span onClick={() => {
                                document.getElementById('token-2-steps').focus()
                            }} style={{color: 'gray'}}>
                                {zeroPadding.slice(0, 6 - token.length)}
                            </span>
                        ),
                    }}
                    sx={{
                        width: '3.6rem',
                        marginTop: '1rem',
                    }}
                />
                {button}
            </Box>
        </ContentContainer>
    );
}