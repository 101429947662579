import TextField from "@mui/material/TextField";
import Select from "react-select";
import React from "react";
import {statusList} from "./statusList";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {handleChangeData} from "../../../utils/handleChangeData";
import InputsInstallment from "../../Order/Components/InputsAdd/InputsInstallment";
import Box from "@mui/material/Box";
import applyRegex from "../../../utils/applyRegex";

export default function InputsAntecipacao({
                                     dataAntecipacao, setDataAntecipacao,
                                     status, setStatus
                                    }) {

    return <>
        <DatePicker
            label="Data da antecipação"
            sx={{
                width: '100%'
            }}
            defaultValue={dataAntecipacao ? dayjs(dataAntecipacao) : null}
            onChange={(e) => handleChangeData(e, setDataAntecipacao)}
            slotProps={{
                textField: {
                    required: true
                },
            }}
        />
        <Select
            className="basic-single"
            classNamePrefix="select"
            id="form_select"
            required={true}
            defaultValue={statusList.filter(item => item.value === status)}
            isDisabled={false}
            isLoading={false}
            isClearable={false}
            isRtl={false}
            isSearchable={true}
            options={statusList}
            onChange={(e) => setStatus(e.value)}
            placeholder={'Status *'}
        />
    </>
}