import FilterForm from "../../../component/FilterForm/FilterForm";
import React, {useState} from "react";
import TextField from "@mui/material/TextField";
import Select from "react-select";
import {statusList, typeList} from "./lists";
import CurrencyInput from "../../../component/CurrencyInput";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {handleChangeData} from "../../../utils/handleChangeData";
import InputMask from "react-input-mask";
import {validateCpf} from "../../../utils/validateCpf";
import Typography from "@mui/material/Typography";
import {Switch} from "@mui/material";
import {validateCnpj} from "../../../utils/validateCnpj";


export default function FilterPayment({filter, setFilter, setLoadData, setPage}) {

    const [lgShow, setLgShow] = useState(false);
    const [valor, setValor] = useState('');
    const [scheduledDate, setScheduledDate] = useState('')
    const [status, setStatus] = useState('')
    const [documento, setDocumento] = useState('')
    const [nomeParticipante, setNomeParticipante] = useState('')

    const [isCnpj, setIsCnpj] = useState(false)
    const toggleSwitch = () => {
        setIsCnpj(prevState => !prevState)
        setDocumento('')
    }

    const handlesSubmit = (e) => {

        e.preventDefault();
        let filterData = {}

        if (valor)
            filterData.valor = valor
        if (scheduledDate)
            filterData.scheduledDate = scheduledDate + "T00:00:00"
        if (status)
            filterData.status = status
        if (documento)
            filterData.documento = documento
        if (nomeParticipante)
            filterData.nomeParticipante = nomeParticipante

        setFilter(filterData);
        setLgShow(false);
        setLoadData(true);
        setPage(0);
    }

    const clearFilter = () => {

        setValor('')
        setScheduledDate('')
        setStatus('')
        setDocumento('')
        setNomeParticipante('')

        setIsCnpj(false)

        setFilter({});
        setLgShow(false);
        setLoadData(true);
        setPage(0);
    }

    const handleChangeValue = (e, set) => {
        set(e.floatValue)
    }

    return <>
        <FilterForm
            handleSubmit={handlesSubmit} clearFilter={clearFilter}
            lgShow={lgShow} setLgShow={setLgShow}
            showSecondaryButton={Object.keys(filter).length !== 0}
        >
            <CurrencyInput
                label="Valor"
                customInput={TextField}
                prefix='R$'
                value={valor}
                inputProps={{
                    min: 1,
                    step: ".01"
                }}
                onValueChange={(e) => handleChangeValue(e, setValor)}
                fullWidth
            />

            <DatePicker
                label="Data de Agendamento"
                sx={{
                    width: '100%'
                }}
                defaultValue={scheduledDate ? dayjs(scheduledDate) : null}
                onChange={(e) => handleChangeData(e, setScheduledDate)}
            />

            <TextField
                label="Nome do recebedor"
                inputProps={{
                    maxLength: 100
                }}
                value={nomeParticipante}
                onChange={(e) => {
                    setNomeParticipante(e.target.value)
                }}
                fullWidth
            />

            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                {/*<div style={{marginRight: '10px'}}>*/}
                <Typography variant="body1">
                    <b>Documento: &nbsp;</b>
                </Typography>
                <Typography variant="body1">
                    CPF
                </Typography>
                <Switch
                    checked={isCnpj}
                    onChange={toggleSwitch}
                    color="primary"
                    inputProps={{'aria-label': 'alternar entre CNPJ e CPF'}}
                />
                <Typography variant="body1">
                    CNPJ
                </Typography>
            </div>

            {!isCnpj ?
                <InputMask mask="999.999.999-99" value={documento} maskChar={null}
                           onChange={(e) => setDocumento(e.target.value)}>
                    {() =>
                        <TextField
                            label="CPF do recebedor" variant="outlined"
                            error={documento.length === 14 && !validateCpf(documento)}
                            helperText={documento.length === 14 && !validateCpf(documento) ? 'CPF inválido' : ''}
                            inputProps={{
                                maxLength: 14,
                                minLength: 14,
                            }}
                            fullWidth
                        />}
                </InputMask> :
                <InputMask mask="99.999.999/9999-99" value={documento} maskChar={null}
                           onChange={(e) => setDocumento(e.target.value)}>
                    {() =>
                        <TextField
                            label="CNPJ do recebedor" variant="outlined"
                            error={documento.length === 18 && !validateCnpj(documento)}
                            helperText={documento.length === 18 && !validateCnpj(documento) ? 'CNPJ inválido' : ''}
                            inputProps={{
                                maxLength: 18,
                                minLength: 18,
                            }}
                            fullWidth
                        />}
                </InputMask>
            }



            <Select
                className="basic-single"
                classNamePrefix="select"
                id="form_select"
                required={false}
                defaultValue={statusList.filter(item => item.value === status)}
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                isSearchable={true}
                options={statusList}
                onChange={(e) => setStatus(e?.value)}
                placeholder={'Status'}
            />
        </FilterForm>
    </>
}