import FilterForm from "../../../component/FilterForm/FilterForm";
import React, {useState} from "react";
import TextField from "@mui/material/TextField";
import Select from "react-select";
import {entradaTypeList} from "./entradaTypeList";

export default function FilterInstallmentPattern({filter, setFilter, setLoadData, setPage}) {

    const [lgShow, setLgShow] = useState(false);
    const [nome, setNome] = useState('');
    const [entrada, setEntrada] = useState('')


    const handlesSubmit = (e) => {

        e.preventDefault();
        let filterData = {}
        if (nome)
            filterData.nome = nome
        if (entrada)
            filterData.entrada = entrada

        setFilter(filterData);
        setLgShow(false);
        setLoadData(true);
        setPage(0);
    }

    const clearFilter = () => {
        setNome('')
        setEntrada('')
        setFilter({});
        setLgShow(false);
        setLoadData(true);
        setPage(0);
    }

    return <>
        <FilterForm
            handleSubmit={handlesSubmit} clearFilter={clearFilter}
            lgShow={lgShow} setLgShow={setLgShow}
            showSecondaryButton={Object.keys(filter).length !== 0}
        >
            <TextField
                label="Nome"
                inputProps={{
                    maxLength: 40
                }}
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                fullWidth
                autoFocus
            />
            <Select
                className="basic-single"
                classNamePrefix="select"
                id="form_select"
                required={false}
                defaultValue={entradaTypeList.filter(item => item.value === entrada)}
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                isSearchable={true}
                options={entradaTypeList}
                onChange={(e) => setEntrada(e?.value)}
                placeholder={'Entrada'}
            />
        </FilterForm>
    </>
}