import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import InputsAnimal from "./InputsAnimal";

export default function DisplayManyAnimals({
                                               obj, setObj, baseUrl,
                                               setBlockSubmit, name,
                                               numeroLote
                                           }) {
    function extrairParteNumerica(string) {
        // Use expressão regular para encontrar apenas os dígitos na string
        const digitos = string?.match(/\d+/g);

        // Se não houver dígitos na string, retorne 0 ou null, dependendo dos seus requisitos
        if (!digitos) {
            return 1;
        }

        // Junte os dígitos encontrados em uma única string
        const parteNumerica = digitos.join('');

        // Converta a parte numérica para um número inteiro usando parseInt
        return parseInt(parteNumerica, 10);
    }

    const [numeroLoteParsed, setNumeroLoteParsed] = useState(extrairParteNumerica(numeroLote))

    useEffect(() => {
        setNumeroLoteParsed(extrairParteNumerica(numeroLote))
    }, [numeroLote])

    const addComponent = () => {
        const index = obj.length
        setObj([...obj, {index}]);
    };

    const removeComponent = (index) => {
        if (obj.length > 1) {
            const updatedComponents = [...obj];
            updatedComponents.splice(index, 1);
            setObj(updatedComponents);
        }
    };

    const alterItem = (index, data) => {
        setObj((prevComponents) => {
            const updatedComponents = [...prevComponents];
            updatedComponents[index] = data;
            return updatedComponents;
        });
    };

    return (
        <div>
            {obj.map((componentData, index) => (
                <div key={index}>
                    <>
                        <Box mb={0.5} pb={0.5}>
                            {name} {index + 1}

                            {obj.length > 1 && index !== 0 &&
                                <div
                                    className='d-inline text-danger'
                                    style={{cursor: 'pointer'}}
                                    onClick={() => removeComponent(index)}
                                >
                                    &nbsp; Remover
                                </div>
                            }
                        </Box>
                    </>
                    <InputsAnimal
                        set={(newData) => {
                            alterItem(index, newData);
                        }}
                        numeroLote={numeroLoteParsed}
                    />
                </div>
            ))}
            <Button fullWidth onClick={addComponent} type='button'>Adicionar mais um {name} </Button>
        </div>
    );
}