import {useState} from "react";
import InputsInstallmentPattern from "./InputsInstallmentPattern";
import PopupAdd from "../../../component/PopupAdd/PopupAdd";
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';

export default function PopupAddInstallmentPattern({baseUrl, setLoadData}) {

    const [nome, setNome] = useState('');
    const [entrada, setEntrada] = useState('')

    const tiposParcelaDefault = [{
        index: 1,
        multiplicidade: 1,
        quantidade: 1
    }]
    const [tiposParcela, setTiposParcela] = useState(tiposParcelaDefault)

    const [errorMessage, setErrorMessage] = useState("")
    const [errorColumn, setErrorColumn] = useState("")

    const inputs = <InputsInstallmentPattern
        nome={nome} setNome={setNome}
        tiposParcela={tiposParcela} setTiposParcela={setTiposParcela}
        entrada={entrada} setEntrada={setEntrada}

        setErrorMessage={setErrorMessage} errorMessage={errorMessage}
        setErrorColumn={setErrorColumn} errorColumn={errorColumn}
    />

    const icon = <FormatListNumberedIcon fontSize="large" id="icon-svg"/>

    const clearForm = () => {
        setErrorMessage(false)
        setErrorColumn(false)
        setTiposParcela(tiposParcelaDefault)
        setNome('')
        setEntrada(null)
    }

    const returnData = () => {
        return {
            nome, entrada,
            tiposParcela
        }
    }

    return <PopupAdd
        baseUrl={baseUrl + '/register'} setLoadData={setLoadData}
        inputs={inputs} title={'Adicionar Padrão de Parcelamento'} icon={icon}
        clearForm={clearForm} returnData={returnData}
        setErrorMessage={setErrorMessage} setErrorColumn={setErrorColumn}
    />
}