import {useState} from "react";
import EventIcon from '@mui/icons-material/Event';
import PopupAdd from "../../../component/PopupAdd/PopupAdd";
import InputsFaq from "./InputsFaq";

export default function PopupAddFaq({baseUrl, setLoadData}) {

    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('')

    const [errorMessage, setErrorMessage] = useState("")
    const [errorColumn, setErrorColumn] = useState("")

    const inputs = <InputsFaq
        question={question} setQuestion={setQuestion}
        answer={answer} setAnswer={setAnswer}
        setErrorMessage={setErrorMessage} errorMessage={errorMessage}
        setErrorColumn={setErrorColumn} errorColumn={errorColumn}
    />

    const icon = <EventIcon fontSize="large" id="icon-svg"/>

    const clearForm = () => {
        setErrorMessage(false)
        setErrorColumn(false)
        setQuestion('')
        setAnswer('')
    }

    const returnData = () => {
        return {
            question,
            answer
        }
    }

    return <PopupAdd
        baseUrl={baseUrl + '/register'} setLoadData={setLoadData}
        inputs={inputs} title={'Adicionar FAQ'} icon={icon}
        clearForm={clearForm} returnData={returnData}
        setErrorMessage={setErrorMessage} setErrorColumn={setErrorColumn}
    />
}