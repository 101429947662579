import {useState} from "react";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {Button} from "react-bootstrap";
import axios from "axios";
import {ApiUrl} from "../../../auth/authMethods";
import DefaultProblemMessage from "../../../component/DefaultProblemMessage/DefaultProblemMessage";
import DefaultSuccessMessage from "../../../component/DefaultSuccessMessage/DefaultSuccessMessage";

const style = {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

export default function PopupGenerateDuplicate({item, setLoadData}) {

    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [needToReload, setNeedToReload] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        if (needToReload)
            setLoadData(true)
        setError(false)
        setOpen(false);
    };

    const generate = (e) => {
        e.preventDefault();
        setLoading(true);

        axios.post(ApiUrl + '/parcelas/gerar-segunda-via', { idParcela: item.id }, { responseType: 'arraybuffer' })
            .then((response) => {
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'boleto.pdf');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                setNeedToReload(true);
                setLoading(false);
                handleClose();
            })
            .catch(() => {
                setError(true);
                setLoading(false);
            });
    };


    return (
        <>
            <Button onClick={handleOpen} variant="outline-primary">Gerar Segunda Via <ReceiptLongIcon/></Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={style}>
                    {loading ? (
                        <div className='text-center'>
                            <CircularProgress color="primary" />
                            <p>Gerando segunda via do boleto...</p>
                        </div>
                    ) : (
                        !needToReload ? (
                            !error ? (
                                <>
                                    <h2 id="child-modal-title">Confirma geração de segunda via do boleto?</h2>
                                    <hr />
                                    <div className='text-center'>
                                        <Button onClick={generate}>Confirmar</Button>
                                        &nbsp;
                                        <Button onClick={handleClose} variant='outline-secondary'>Cancelar</Button>
                                    </div>
                                </>
                            ) : (
                                <DefaultProblemMessage>
                                    <Button onClick={handleClose} variant='outline-secondary'>Fechar</Button>
                                </DefaultProblemMessage>
                            )
                        ) : (
                            <DefaultSuccessMessage message='Segunda via baixada com sucesso!'>
                                <Button onClick={handleClose} variant='outline-secondary'>Fechar</Button>
                            </DefaultSuccessMessage>
                        )
                    )}
                </Box>
            </Modal>
        </>
    )
        ;
}