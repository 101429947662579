import './App.css';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import {AuthProvider} from "./auth/AuthProvider";
import Login from "./page/Login/Login";
import Logout from "./page/Logout/Logout";
import ForgetPassword from "./page/ForgetPassword/ForgetPassword";
import ResetPassword from "./page/ResetPassword/ResetPassword";
import ChangePassword from "./page/ChangePassword/ChangePassword";
import ContentContainer from "./component/ContentContainer";
import ContactUs from "./page/ContactUs/ContactUs";
import {PrivateRoute} from "./auth/PrivateRoute";
import {PrivateAdminRoute} from "./auth/PrivateAdminRoute";
import {UnloggedRoute} from "./auth/UnloggedRoute";
import HomePage from "./page/HomePage/HomePage";
import AddUser from "./page/AddUser";
import LateOrders from "./page/LateOrders/LateOrders";
import ManualWriteOff from "./page/ManualWriteOff/ManualWriteOff";
import Event from "./page/Event/Event";
import Sidebar from "./component/SidebarMenu";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/en-gb';
import Orders from "./page/Order/Orders";
import Animal from "./page/Animal/Animal";
import Users from "./page/Users";
import Boleto from "./page/Boletos/Boleto";
import TokenValidator from "./component/TokenValidator";
import JobManage from "./page/JobManage/JobManage";
import Payment from "./page/Payment";
import Footer from "./component/Footer";
import FAQ from "./page/FAQ";
import Antecipacao from "./page/Antecipacao";
import PaymentsToAprove from "./page/PaymentsToAprove";
import Report from "./page/Report";
import InstallmentPattern from "./page/InstallmentPattern";
import {PrivateGroupLeaderRoute} from "./auth/PrivateGroupLeaderRoute";
import WhatsAppButton from "./component/WhatsButton/WhatsAppButton";

function App() {

    return (
        <BrowserRouter>
            <AuthProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>

                    <Sidebar>
                        <TokenValidator/>
                        <Routes>

                            <Route path="/" element={<HomePage/>}/>
                            <Route path="/change-password" element={<ChangePassword/>}/>
                            <Route path="/contact-us" element={<ContactUs/>}/>

                            {/* Rotas apenas para usuários não conectados */}
                            <Route exact path='/' element={<UnloggedRoute/>}>
                                <Route path="/login" element={<Login/>}/>
                                <Route path="/forget-password" element={<ForgetPassword/>}/>
                                <Route path="/forget-password/:resetToken" element={<ResetPassword/>}/>
                                <Route path="*" element={<Navigate to='/login'/>}/>
                            </Route>

                            {/* Rotas privadas */}
                            <Route exact path='/' element={<PrivateRoute/>}>
                                <Route path="/logout" element={<Logout pathToRedirect={'/'}/>}/>
                                <Route path="*" element={<Navigate to='/'/>}/>
                            </Route>

                            {/* Rotas privadas para usuários não administradores*/}
                            <Route exact path='/' element={<PrivateRoute/>}>
                                <Route path="/orders/:type" element={
                                    <ContentContainer width='xl'>
                                        <Orders/>
                                    </ContentContainer>
                                }/>
                                <Route path="/boletos/:type" element={<Boleto/>}/>
                                <Route path="/payments" element={<Payment/>}/>
                                <Route path="/payments/to-aprove" element={<PaymentsToAprove/>}/>
                                <Route path="/FAQ" element={<FAQ/>}/>
                                <Route path="/antecipacao" element={<Antecipacao/>}/>
                            </Route>

                            {/* Rotas para admin */}
                            <Route exact path='/' element={<PrivateAdminRoute/>}>
                                <Route path="/login" element={<Navigate to='/'/>}/>
                                <Route path="/add-user" element={<AddUser/>}/>
                                <Route path="/users" element={<Users/>}/>
                                <Route path="/late-orders" element={<LateOrders/>}/>
                                <Route path="/manual-write-offs" element={<ManualWriteOff/>}/>
                                <Route path="/events" element={<Event/>}/>
                                <Route path="/boletos" element={<Boleto/>}/>
                                <Route path="/payments" element={<Payment/>}/>
                                <Route path="/animals" element={<Animal/>}/>
                                <Route path="/jobs" element={<JobManage/>}/>
                                <Route path="/report" element={<Report/>}/>
                                <Route path="/installment-pattern" element={<InstallmentPattern/>}/>
                                <Route path="/admin" element={
                                    <ContentContainer>
                                        <p className='text-center'>Admin</p>
                                    </ContentContainer>
                                }/>
                            </Route>
                        </Routes>
                        <Footer/>
                        <WhatsAppButton />
                    </Sidebar>
                </LocalizationProvider>
            </AuthProvider>
        </BrowserRouter>
    );
}

export default App;
