import PopupEdit from "../../../component/PopupEdit/PopupEdit";
import React, {useState} from "react";
import {ApiUrl} from "../../../auth/authMethods";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import InputsBoleto from "./InputsBoleto";
import displayAlertMsg from "../../../component/AlertMsg/displayAlertMsg";

export default function PopupEditBoleto({item, setLoadData}) {

    const id = item.id


    const [dueDate, setDueDate] = useState("")
    const [files, setFiles] = useState([])

    const [blockSubmit, setBlockSubmit] = useState(false)
    const headers = {
        'Accept': 'application/json'
    }


    const [errorMessage, setErrorMessage] = useState("")
    const [errorColumn, setErrorColumn] = useState("")

    const title = "Alterar Vencimento"

    const buttonText = <>
        <DriveFileRenameOutlineIcon fontSize="medium"/>
        &nbsp; {title}
    </>

    const returnData = () => {

        const formData = new FormData();

        // Adiciona cada arquivo no FormData
        files.forEach(file => {
            formData.append('files', file);
        });

        // Adiciona o objeto JSON como Blob
        formData.append('updateBoletoDto', new Blob([JSON.stringify({ dueDate })], { type: 'application/json' }));

        return formData;
    }

    const inputs = <>
        <InputsBoleto
            dueDate={dueDate}
            setDueDate={setDueDate}
            files={files}
            setFiles={setFiles}
        />

        {!(files.length > 0) && <p className='text-danger'>
            * Adicione ao menos um arquivo de evidência para poder alterar o vencimento do boleto
        </p>}

        {errorMessage && displayAlertMsg(errorMessage)}
    </>

    return <PopupEdit
        baseUrl={ApiUrl + '/boletos/' + id} setLoadData={setLoadData}
        inputs={inputs} title={title} returnData={returnData}
        setErrorMessage={setErrorMessage} setErrorColumn={setErrorColumn}
        buttonClass='dropdown-item' buttonText={buttonText}
        blockSubmit={blockSubmit} headers={headers}
        disableButton={!(files.length > 0)}
    />
}