import React, {useRef, useState} from "react";
import DisplayLinks from "../components/DisplayLinks";
import '../style.css'

// icons
import DescriptionIcon from '@mui/icons-material/Description';
import TimerOffIcon from '@mui/icons-material/TimerOff';
import PaidIcon from '@mui/icons-material/Paid';
import PersonIcon from '@mui/icons-material/Person';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import EventIcon from '@mui/icons-material/Event';
import PetsIcon from '@mui/icons-material/Pets';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
export default function AdminLinks({closeMenu}) {

    // const [openAdmin, setOpenAdmin] = useState(false);

    const links = [
        {
            path: '/',
            name: 'Lotes',
            icon: <DescriptionIcon/>,
            ref: useRef()
        },
        {
            path: '/late-orders',
            name: 'Lotes Atrasados',
            icon: <TimerOffIcon/>,
            ref: useRef()
        },
        {
            path: '/manual-write-offs',
            name: 'Baixas Manuais',
            icon: <PaidIcon/>,
            ref: useRef()
        },
        {
            path: '/events',
            name: 'Eventos',
            icon: <EventIcon/>,
            ref: useRef()
        },
        {
            path: '/animals',
            name: 'Animais',
            icon: <PetsIcon/>,
            ref: useRef()
        },
        {
            path: '/boletos',
            name: 'Boletos',
            icon: <RequestPageIcon/>,
            ref: useRef()
        },
        {
            path: '/payments',
            name: 'Pagamentos',
            icon: <LocalAtmIcon/>,
            ref: useRef()
        },
        {
            path: '/payments/to-aprove',
            name: 'Aprovações pendentes',
            icon: <LocalAtmIcon/>,
            ref: useRef()
        },
        {
            path: '/installment-pattern',
            name: 'Padrões de parcelamento',
            icon: <FormatListNumberedIcon/>,
            ref: useRef()
        },
        {
            path: '/FAQ',
            name: 'FAQ ',
            icon: <LiveHelpIcon/>,
            ref: useRef()
        },
        {
            path: '/antecipacao',
            name: 'Antecipação ',
            icon: <MoreTimeIcon/>,
            ref: useRef()
        }
    ]

    const collapses = [
        {
            name: 'Administrativo',
            icon: <AdminPanelSettingsIcon/>,
            state: useState(false),
            links: [
                {
                    path: '/users',
                    name: 'Usuários',
                    icon: <PersonIcon/>,
                    ref: useRef()
                },
                {
                    path: '/jobs',
                    name: 'Jobs',
                    icon: <ManageHistoryIcon/>,
                    ref: useRef()
                }
            ]
        },
    ]

    return <DisplayLinks links={links} collapses={collapses} closeMenu={closeMenu}/>
}