import {Dropdown} from "react-bootstrap";
import SettingsIcon from "@mui/icons-material/Settings";
import React from "react";
import InstallmentPatternDetails from "./InstallmentPatternDetails";
import PopupDeleteInstallmentPattern from "./PopupDeleteInstallmentPattern";

export default function InstallmentPatternActions({item, setLoadData, baseUrl}) {

    return <Dropdown drop='down-centered'>
        <Dropdown.Toggle variant="secondary">
            Ações &nbsp;
            <SettingsIcon fontSize="medium"/>
        </Dropdown.Toggle>

        <Dropdown.Menu>
            <InstallmentPatternDetails item={item}/>
            <PopupDeleteInstallmentPattern item={item} setLoadData={setLoadData} baseUrl={baseUrl}/>
        </Dropdown.Menu>

    </Dropdown>
}