import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {useUser} from "./AuthProvider";

// Crie um componente de rota privada
export function PrivateRoute() {
    const { user } = useUser();

    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return user?.token ? <Outlet/> : <Navigate to="/login"/>;
}