import React, {useEffect, useState} from "react";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import axios from "axios";
import {Button, Form} from "react-bootstrap";
import Popup from "../Popup/Popup";
import {useUser} from "../../auth/AuthProvider";
import displayAlertMsg from "../AlertMsg/displayAlertMsg";


const PopupEdit = ({
                       baseUrl, setLoadData, inputs,
                       itemToState, title, returnData,
                       setErrorMessage, setErrorColumn,
                       blockSubmit, buttonClass, buttonText,
                       internalErrorMessage, headers, validateData,
                       disableButton
                   }) => {

    const {user} = useUser()

    // modal control
    const [lgShow, setLgShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (!lgShow)
            if (itemToState)
                itemToState()
    }, [lgShow]);


    const iconSpan = <>
        <DriveFileRenameOutlineIcon fontSize="large" id="icon-svg"/>
        {title}
    </>

    const button = <>
        Editar
        <DriveFileRenameOutlineIcon fontSize="medium"/>
    </>


    const handleSubmit = (e) => {
        e.preventDefault()

        const data = returnData()

        setErrorMessage(false)
        setErrorColumn(false)

        let dataIsValid = true;
        if (typeof validateData === "function") dataIsValid = validateData();

        setError(false);
        if (!blockSubmit && dataIsValid) {
            setIsLoading(true)
            axios.put(baseUrl, data, {headers})
                .then((response) => {
                    setLoadData(true)
                    setLgShow(false)
                    if (typeof setErrorColumn == 'function') {
                        setErrorMessage("")
                        setErrorColumn("")
                    }
                }).catch((error) => {
                // aqui temos acesso ao erro, quando alguma coisa inesperada acontece:
                console.log(error);
                if (error.response.status === 409) {
                    setErrorMessage(error.response.data.message);
                    setErrorColumn(error.response.data.column);
                }
                if (error.response.status >= 500) {
                    setError(displayAlertMsg(internalErrorMessage))
                }
            }).finally(() => setIsLoading(false))

        }
    }


    return (
        <>
            {/* Botão e popup */}
            <Popup
                buttonText={buttonText ? buttonText : button}
                title={title}
                iconSpan={iconSpan}
                lgShow={lgShow} setLgShow={setLgShow}
                buttonId={"links-action"}
                buttonClass={buttonClass}
                backdrop={'static'}
            >
                <div className='mt-3'>
                    <Form onSubmit={handleSubmit} className="form-inputs">
                        {inputs}

                        {error}


                        <Button
                            type="submit"
                            disabled={isLoading || disableButton}
                            className={isLoading || disableButton ? "btn btn-secondary" : ""}
                        >
                            {isLoading ? (
                                <>
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                    Aguarde...
                                </>
                            ) : (
                                "Salvar Alterações"
                            )}
                        </Button>
                    </Form>
                </div>


            </Popup>
        </>
    )
}

export default PopupEdit