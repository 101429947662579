import PopupDelete from "../../../component/PopupDelete/PopupDelete";
import {normalizeTimestemp} from "../../../utils/normalizeTimestemp";
import {entradaTypeList} from "./entradaTypeList";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import React from "react";

export default function PopupDeleteInstallmentPattern({
                                             baseUrl,
                                             setLoadData,
                                             item
                                         }) {

    const buttonText = <span>
        <DeleteForeverIcon fontSize="medium"/> &nbsp;
        Deletar
    </span>

    const blockRemoveMessage = `Este padrão de parcelamento não pode ser 
    excluído pois já possui lote(s) e/ou evento(s) atrelado(s) à ele`

    return (
        <PopupDelete
            baseUrl={baseUrl} setLoadData={setLoadData}
            title={"Confirmar Exclusão de Padrão de Parcelamento"} id={item.id}
            buttonClass='dropdown-item text-danger' buttonText={buttonText}
            canBeRemoved={item.canBeRemoved}
            blockRemoveMessage={blockRemoveMessage}
        >

            <div className="mt-3 mb-3">
                <b>Nome: </b>
                <>{item.nome}</>
                <br/>

                <b>Entrada: </b>
                <>{entradaTypeList.filter(ent => ent.value === item.entrada)[0].label}</>
                <br/>

            </div>

            <p className="mb-3">Você deseja realmente excluir este registro?</p>

        </PopupDelete>
    )
}