import PopupDelete from "../../../component/PopupDelete/PopupDelete";
import {ApiUrl} from "../../../auth/authMethods";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import React from "react";
import {genderList, quantityList} from "../../Animal/Components/lists";
import {normalizeTimestemp} from "../../../utils/normalizeTimestemp";
import {statusList} from "./statusList";


export default function PopupCancelBoleto({item, setLoadData}) {

    const title = 'Confirmar Cancelamento de Boleto'

    const buttonText = <>
        <DeleteForeverIcon fontSize="medium"/>
        &nbsp; Cancelar Boleto
    </>

    return <PopupDelete
        baseUrl={ApiUrl + '/boletos'} setLoadData={setLoadData}
        title={title} id={item.id}
        buttonClass={'dropdown-item text-danger'}
        buttonText={buttonText}
    >
        <div className="mt-3 mb-3">
            <b>Identificador: </b>
            <>{item.identificador}</><br/>

            <b>Data de Vencimento: </b>
            <>{normalizeTimestemp(item.dataVencimento)}</><br/>

            <b>Status: </b>
            <>{statusList.filter(stt => stt.value === item.status)[0].label}</><br/>

            <b>Código de Barras: </b>
            <>{item.linhaDigitavel}</><br/>
        </div>

        <p className="mb-3">Você deseja realmente excluir este registro?</p>

    </PopupDelete>

}