import React, {useRef, useState} from "react";
import DisplayLinks from "../components/DisplayLinks";

// iccons
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HomeIcon from '@mui/icons-material/Home';
import RequestPageIcon from "@mui/icons-material/RequestPage";
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import PersonIcon from "@mui/icons-material/Person";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";

export default function CommonUserLinks({closeMenu, isGroupLeader}) {

    // const [openAdmin, setOpenAdmin] = useState(false);

    const links = [
        {
            path: '/',
            name: 'Home',
            icon: <HomeIcon/>,
            ref: useRef()
        },
        {
            path: '/orders/sold',
            name: 'Lotes Vendidos',
            icon: <AttachMoneyIcon/>,
            ref: useRef()
        },
        {
            path: '/orders/bought',
            name: 'Lotes Comprados',
            icon: <MoneyOffIcon/>,
            ref: useRef()
        },
        {
            path: '/boletos/pendentes',
            name: 'Boletos Pendentes',
            icon: <RequestPageIcon/>,
            ref: useRef()
        },
        {
            path: '/boletos/atrasados',
            name: 'Boletos Atrasados',
            icon: <RequestPageIcon/>,
            ref: useRef()
        },
        {
            path: '/boletos/pagos',
            name: 'Boletos Pagos',
            icon: <LocalAtmIcon/>,
            ref: useRef()
        },
        {
            path: '/payments',
            name: 'Pagamentos ',
            icon: <LocalAtmIcon/>,
            ref: useRef()
        },
        {
            path: '/FAQ',
            name: 'FAQ ',
            icon: <LiveHelpIcon/>,
            ref: useRef()
        },
        {
            path: '/antecipacao',
            name: 'Antecipação ',
            icon: <LiveHelpIcon/>,
            ref: useRef()
        }
    ]

    const linksGroupLeader = [
        {
            path: '/',
            name: 'Home',
            icon: <HomeIcon/>,
            ref: useRef()
        },
        {
            path: '/FAQ',
            name: 'FAQ ',
            icon: <LiveHelpIcon/>,
            ref: useRef()
        }
    ]

    const collapses = [
        {
            name: 'Funcionalidades',
            icon: <AdminPanelSettingsIcon/>,
            state: useState(false),
            links: [
                {
                    path: '/users',
                    name: 'Usuários',
                    icon: <PersonIcon/>,
                    ref: useRef()
                }
            ]
        },
    ]

    return <DisplayLinks links={!isGroupLeader ? links : linksGroupLeader} collapses={isGroupLeader ? collapses : []} closeMenu={closeMenu}/>
}